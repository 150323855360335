<template>
  <div class="wrapper" id="app">
    <MemberNav :member="member.firstName"></MemberNav>
    <div
      class="card-form__inner mt-5 container shadow-lg"
      v-bind:style="{
        maxWidth: isMobile() ? '100%' : '55%',
      }"
    >
      <div class="container" style="max-width: 100%">
        <div class="row justify-content-end text-end">
          <span class="m-3" v-if="this.status === 'active'"
            >Active
            <i
              class="fa-solid fa-circle-check fa-2xl"
              style="color: #32bd28"
            ></i>
          </span>
          <span v-else class="m-3"
            >Inactive
            <i class="fa-solid fa-ban fa-2xl" style="color: #db2c1f"></i>
          </span>
        </div>
        <h1 class="text-center m-5">
          {{ membership.memberFirstName }} {{ membership.memberLastName }}'s
          Profile
        </h1>
        <h5 class="text-center">
          <span
            ><i
              class="fa-brands fa-creative-commons-sampling fa-lg m-1"
              style="color: #363166"
            ></i>
            Status: {{ getStatusText }}
          </span>
        </h5>
        <div class="row" style="width: 100%">
          <div class="information-card col-md-12 col-sm-12 center">
            <h2 class="card-title text-center strong">
              <span
                ><i
                  class="fa-solid fa-book fa-lg m-3"
                  style="color: #363166"
                ></i>
                <label style="margin-right: 3px">
                  Description & Bulletin
                </label>
              </span>
            </h2>
            <div class="card-body">
              <p>
                <i
                  class="fa-solid fa-comment fa-lg m-1"
                  style="color: #363166"
                ></i>
                {{ membershipDescription }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center"
          v-bind:style="{
            width: '100%',
            marginLeft: isMobile() ? '1px' : '1px',
          }"
        >
          <div
            class="information-card col-md-6 col-sm-12"
            style="max-width: 625px"
          >
            <h2 class="card-title text-center strong">
              <span
                ><i
                  class="fa-solid fa-user fa-lg m-3"
                  style="color: #363166"
                ></i>
              </span>
              <br />
              <label>Details</label>
            </h2>
            <div class="card-body">
              <h5>
                <span
                  ><i
                    class="fa-solid fa-circle-user fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>First Name:</b> {{ membership.memberFirstName }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-circle-user fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Last Name:</b> {{ membership.memberLastName }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-envelope fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Email:</b> {{ member.email }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-location-dot fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Address:</b> {{ member.address }}, {{ member.city }},
                  {{ member.state }}, {{ member.zipCode }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-mobile-screen-button fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Phone Number:</b> {{ member.phoneNumber }}
                </span>
              </h5>
              <br />
              <div
                v-for="index in membership.customFieldValues.length"
                :key="index"
              >
                <h5>
                  {{ customFieldValues[index - 1].fieldName }}:
                  {{ customFieldValues[index - 1].fieldValue }}
                </h5>
              </div>
            </div>
          </div>

          <div
            class="information-card col-md-6 col-sm-12"
            style="max-width: 625px"
          >
            <h2 class="card-title text-center strong">
              <span
                ><i
                  class="fa-solid fa-truck-medical fa-lg m-3"
                  style="color: #363166"
                ></i>
              </span>
              <br />
              <label>Emergency Contact Details</label>
            </h2>
            <div class="card-body">
              <h5>
                <span
                  ><i
                    class="fa-solid fa-circle-user fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>First Name:</b> {{ member.emergencyContactInfo.firstName }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-circle-user fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Last Name:</b> {{ member.emergencyContactInfo.lastName }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-envelope fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Email:</b> {{ member.emergencyContactInfo.email }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-location-dot fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Address:</b> {{ member.emergencyContactInfo.address }},
                  {{ member.emergencyContactInfo.city }},
                  {{ member.emergencyContactInfo.state }},
                  {{ member.emergencyContactInfo.zipCode }}
                </span>
              </h5>
              <h5>
                <span
                  ><i
                    class="fa-solid fa-mobile-screen-button fa-lg m-1"
                    style="color: #363166"
                  ></i>
                  <b>Phone Number:</b>
                  {{ member.emergencyContactInfo.phoneNumber }}
                </span>
              </h5>
              <br />
            </div>
          </div>
        </div>
        <div class="row" style="width: 100%">
          <div class="information-card col-md-12 col-sm-6">
            <h2 class="card-title text-center strong">
              <span
                ><i
                  class="fa-solid fa-credit-card fa-lg m-3"
                  style="color: #363166"
                ></i>
              </span>
              <br />
              <label> Payment Details</label>
            </h2>
            <div class="card-body row">
              <div v-bind:class="{ col: true, 'm-5': !isMobile() }">
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-circle-user fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>First Name:</b>
                    {{ billingInfo.firstName }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-circle-user fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Last Name:</b> {{ billingInfo.lastName }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-envelope fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Email:</b> {{ subscriptionDetails.profile.email }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-location-dot fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Address:</b> {{ billingInfo.address }},
                    {{ billingInfo.city }}, {{ billingInfo.state }},
                    {{ billingInfo.zip }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-mobile-screen-button fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Phone Number:</b> {{ billingInfo.phoneNumber }}
                  </span>
                </h5>
              </div>

              <div v-bind:class="{ col: true, 'm-5': !isMobile() }">
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-credit-card fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Card On File:</b>
                    {{
                      subscriptionDetails.profile.paymentProfile.payment
                        .creditCard.cardNumber
                    }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-calendar-day fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Payment Period:</b>
                    {{ getMembershipPaymentPeriod(membership.payPeriod) }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-calendar-check fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Joined On:</b>
                    {{ getStartDate }}
                  </span>
                </h5>
                <h5>
                  <span
                    ><i
                      class="fa-solid fa-file-invoice-dollar fa-lg m-1"
                      style="color: #363166"
                    ></i>
                    <b>Cost Each Cycle:</b> ${{ subscriptionDetails.amount }}
                    USD
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div v-bind:class="{ row: true, 'mb-3': !isMobile() }">
          <div class="col justify-content-start">
            <button
              class="card-form__button"
              type="submit"
              v-bind:style="{
                maxWidth: '100%',
                minHeight: isMobile() ? '70px' : '',
              }"
              @click="backToMembershipList"
            >
              Back to Dashboard
            </button>
          </div>

          <div v-if="this.status === 'active'" class="col justify-content-end">
            <button
              class="card-form__button"
              type="submit"
              v-bind:style="{
                maxWidth: '100%',
                minHeight: isMobile() ? '70px' : '',
                backgroundColor: 'red',
              }"
              @click="cancelMembership"
            >
              Cancel Payments
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MemberNav from '../components/MemberNav.vue'
import axios from 'axios'
// import * as url from "url";
import swal from 'sweetalert'
// import VueJwtDecode from "vue-jwt-decode";

export default {
  components: {
    MemberNav,
  },
  data() {
    return {
      user: {},
      data: {},
      membershipID: null,
      memberID: null,
      member: {},
      subscriptionDetails: null,
      subscriptionID: null,
      membership: null,
      membershipDescription: null,
      customFieldValues: [],
      billingInfo: null,
      status: null,
      coachEmail: null,

      numFields: 0,
    }
  },

  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    //api call to get member data
    getMemberData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMemberForMembership?membershipID=' +
        this.membershipID +
        '&memberID=' +
        this.memberID +
        '&subscriptionID=' +
        this.subscriptionID
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.member = response.data.member
          this.subscriptionDetails = response.data.subscriptionDetails
          this.billingInfo = this.subscriptionDetails.profile.shippingProfile
          this.status = this.subscriptionDetails.status
          this.coachEmail = response.data.coachEmail
          for (const membership of this.member.memberships) {
            if (
              membership.membershipID == this.membershipID &&
              membership.subscriptionID == this.subscriptionID
            ) {
              this.membership = membership
              this.customFieldValues = membership.customFieldValues
              this.subscriptionID = membership.subscriptionID
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getMembershipPaymentPeriod(payPeriod) {
      switch (payPeriod) {
        case 'weeklyPrice':
          return 'Weekly'
        case 'monthlyPrice':
          return 'Monthly'
        case 'semiAnnualPrice':
          return 'Semi-Annually'
        case 'annualPrice':
          return 'Annually'
        default:
          return 'Invalid'
      }
    },
    backToMembershipList() {
      this.$router.push('/MemberDashboard/')
    },

    cancelMembership() {
      let data = {
        accountInfo: {
          memberID: this.memberID,
          firstName: this.member.firstName,
          lastName: this.member.lastName,
          email: this.member.email,
        },
        membershipInfo: {
          membershipID: this.membershipID,
          membershipName: this.membership.membershipName,
          subscriptionID: this.subscriptionID,
          memberFirstName: this.membership.memberFirstName,
          memberLastName: this.membership.memberLastName,
          coachEmail: this.coachEmail,
        },
      }

      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/cancelMember?membershipID=' +
        this.membershipID +
        '&memberID=' +
        this.memberID
      //axios post to cancel membership
      axios
        .post(url, data, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          if (response.data.success) {
            this.$router.push('/MemberDashboard/')
            swal('Success', 'Membership Cancelled Successfully!', 'success')
          }
        })
        .catch(error => {
          console.log(error)
          swal(
            "We're Sorry!",
            `We are not able to cancel your membership at this time. Please contact ${this.coachEmail} for assistance.`,
            'error'
          )
        })
    },
    //get membership description
    getMembershipDescription() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMembershipDescription?membershipID=' +
        this.membershipID
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.membershipDescription = response.data.membershipDescription
        })
        .catch(error => {
          console.log(error)
        })
    },
    //get membership status
  },
  created() {
    this.membershipID = this.$route.params.membershipID
    this.memberID = this.$route.params.memberID
    this.subscriptionID = this.$route.params.subscriptionID
    this.getMemberData()
    this.getMembershipDescription()
  },
  computed: {
    getTriCol() {
      let temp = []
      for (let x = 0; x < this.membership.customFieldValues.length; x++) {
        temp.push(this.membership.customFieldValues[x])
      }

      return temp
    },
    getTextLongNum() {
      let temp = 0
      for (let x = 0; x < this.membership.customFieldValues.length; x++) {
        if (this.membership.customFieldValues[x].fieldType == 'textLong')
          temp = temp + 1
      }

      return temp
    },
    getStatusText() {
      //switch case for expired, suspended, canceled, or terminated
      switch (this.status) {
        case 'active':
          return 'Making Active Payments'
        case 'expired':
          return 'Membership Expired'
        case 'suspended':
          return 'Membership Suspended'
        case 'canceled':
          return 'Membership Canceled'
        case 'terminated':
          return 'Membership Terminated'
        default:
          return 'Invalid'
      }
    },
    getStartDate() {
      return new Date(this.membership.startDate).toDateString()
    },
  },
}
</script>
<style>
.information-card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;

  .go-corner {
    opacity: 0.7;
  }

  &:hover {
    border: 1px solid #363166;
    background-color: #f0eff7;
    /*box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);*/
    /*z-index: 500;*/
    transition: 0.3s linear;
  }
}

.information-card:hover {
  p {
    transition: 0.3s linear;
  }
  .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
}
</style>
