<template>
  <div style="width: 100%">
    <div
      class="row justify-content-center information-card"
      style="display: flex !important"
    >
      <div
        :class="{
          'col-4': true,
          'col-sm-2': true,

          'text-muted': this.yearly,
          'text-center': isMobile,
          'text-end': !isMobile,
        }"
      >
        <h4 v-if="!isMobile">Billed Monthly</h4>
        <h5 v-else>Billed Monthly</h5>
      </div>

      <label class="switch col-4">
        <input type="checkbox" @click="setPeriod()" :checked="this.yearly" />
        <div class="slider round"></div>
      </label>

      <div
        :class="{
          'col-4': true,
          'col-sm-2': true,
          'text-muted': !this.yearly,
          'text-start': !isMobile,
          'text-center': isMobile,
        }"
      >
        <h4 v-if="!isMobile">Billed Annually</h4>
        <h5 v-else>Billed Annually</h5>
      </div>
      <br />
      <br />
      <br />
      <h5 v-if="this.yearly" class="text-center text-muted">
        Get up to 3 Months - For Free!
      </h5>
    </div>
    <div v-if="!isMobile">
      <div
        class="row justify-content-center mt-5"
        v-if="!checkingRookie"
        style="width: 100%"
      >
        <ProDetailsCard
          class="col-md-4 col-sm-6 mt-1"
          :yearly="this.yearly"
          :isMobile="this.isMobile"
          :promo="true"
          :style="{
            border:
              this.wantedSubscription.subscriptionName === 'Pro'
                ? '3px solid #007bff'
                : '3px solid #3',
            backgroundColor: '#f8f9fa',
          }"
          @buySubscription="buySubscription"
        ></ProDetailsCard>
        <AllStarDetailsCard
          class="col-md-4 col-sm-6 mt-1"
          :yearly="this.yearly"
          :isMobile="this.isMobile"
          :promo="true"
          :style="{
            border:
              this.wantedSubscription.subscriptionName === 'All Star'
                ? '3px solid #007bff'
                : '',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            transform: 'scale(1.05)',
          }"
          @buySubscription="buySubscription"
        ></AllStarDetailsCard>
        <HallOfFameDetailsCard
          class="col-md-4 col-sm-6 mt-1"
          :yearly="this.yearly"
          :isMobile="this.isMobile"
          :promo="true"
          :style="{
            border:
              this.wantedSubscription.subscriptionName === 'Hall of Fame'
                ? '3px solid #007bff'
                : '',
            backgroundColor: '#f8f9fa',
            transform: 'scale(1.02)',
          }"
          @buySubscription="buySubscription"
        >
        </HallOfFameDetailsCard>
      </div>
    </div>
    <!--start of mobile sliders-->
    <div v-else>
      <div
        id="subscriptionCarousel"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <!-- Dots Indicators -->
        <ol class="carousel-indicators">
          <li
            v-for="(card, index) in subscriptionCards"
            :key="index"
            :data-bs-target="'#subscriptionCarousel'"
            :data-bs-slide-to="index"
            :class="{ active: index === 0 }"
          ></li>
        </ol>

        <!-- Cards -->
        <div class="carousel-inner">
          <div
            class="carousel-item"
            v-for="(card, index) in subscriptionCards"
            :key="index"
            :class="{ active: index === 0 }"
          >
            <div class="row justify-content-center">
              <component
                :is="card.component"
                class="col-md-4 col-sm-6 mt-1"
                :yearly="yearly"
                :isMobile="isMobile"
                :promo="true"
                @buySubscription="buySubscription"
              ></component>
            </div>
          </div>
        </div>

        <!-- Left and Right Controls -->
        <a
          class="carousel-control-prev"
          href="#subscriptionCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#subscriptionCarousel"
          role="button"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import FreeDetailsCard from "../../components/Subscriptions/FreeDetailsCard.vue";
import ProDetailsCard from '../../components/Subscriptions/ProDetailsCard.vue'
import AllStarDetailsCard from '../../components/Subscriptions/AllStarDetailsCard.vue'
import HallOfFameDetailsCard from '../../components/Subscriptions/HallOfFameDetailsCard.vue'

export default {
  components: {
    ProDetailsCard,
    AllStarDetailsCard,
    HallOfFameDetailsCard,
  },
  data() {
    return {
      yearly: false,
      subscriptions: [],
      currentSubscription: {
        subscriptionName: '',
        subscriptionTier: 0,
        subscriptionPayPeriod: '',
      },
      subscriptionCards: [
        { component: 'ProDetailsCard' },
        { component: 'AllStarDetailsCard' },
        { component: 'HallOfFameDetailsCard' },
      ],
      user: null,
      checkingRookie: false,
      skipSchoolQuestion: false,
      subscription: {},
      wantedSubscription: {
        subscriptionName: '',
        subscriptionTier: 0,
        subscriptionPayPeriod: '',
      },
    }
  },
  methods: {
    setPeriod() {
      this.yearly = !this.yearly
    },
    buySubscription(subscription) {
      this.subscription = subscription
      this.$emit('proceed', {
        subscription: subscription,
        yearly: this.yearly,
      })
      // if (window.dataLayer) {
      //   window.dataLayer.push({
      //     event: "signUpStarted",
      //   });
      // }
      // this.$router.push(
      //   "/SubscriptionChoices/ExternalCheckout/" +
      //     subscription._id +
      //     "/" +
      //     this.yearly
      // );
    },
  },
  created() {},
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getSubscriptionRookieCheckoutLink() {
      return (
        import.meta.env.VITE_APP_CLIENT_URL +
        'SubscriptionChoices/Checkout/' +
        this.subscription._id +
        '/' +
        this.yearly
      )
    },
    getSchoolRegistrationLink() {
      return (
        import.meta.env.VITE_APP_CLIENT_URL +
        'register/' +
        this.yearly +
        '/true'
      )
    },
  },
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}
.information-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.actionFeature {
  background-color: #a39ed0;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  z-index: 30;
}

.navigation .container {
  max-width: unset;
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.sa-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-logo {
  border-radius: 20%;
}

.sa-header .sa-logo-wrapper {
  width: 90px;
  display: flex;
}

.sa-nav-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 54px;
}

.sa-nav-wrapper .sa-menu {
  height: 100%;
  display: flex;
  gap: 40px;
}

.sa-menu .sa-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover,
.sa-menu .sa-menu-item.active {
  color: #363166;
}

.sa-menu .sa-menu-item::after {
  content: '';
  width: 100%;
  height: 3px;
  background-color: #363166;
  position: absolute;
  bottom: 0;
  left: -100%;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover::after,
.sa-menu .sa-menu-item.active::after {
  left: 0;
}

.sa-menu-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-image {
  width: 100%;
  height: 100%;
  display: block;
}

.sa-image-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sa-image-cover.fit-pos-top {
  object-position: top;
}

.sa-image-contained {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
.carousel-indicators {
  position: fixed;
  bottom: 20px; /* Position at the bottom of the page */
  left: 50%;
  transform: translateX(-125%);
  z-index: 1000;
}
/* Customizing the carousel indicators (dots) */
.carousel-indicators li {
  background-color: #19d412;
}

/* Changing the color of the active dot */
.carousel-indicators .active {
  background-color: #19d412;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%; /* Position arrows in the middle of the carousel */
  transform: translateY(-50%); /* Center arrows vertically */
}
/* Customizing the carousel control icons (left and right arrows) */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #19d412; /* Make arrows the green color */
  border-radius: 50%; /* Optional: Round the arrow background */
  padding: 10px; /* Optional: Add padding around the arrow */
}

/* Optional: Adjusting size of the arrow icons */
.carousel-control-prev-icon {
  width: 30px;
  height: 30px;
}

.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}
.carousel-inner {
  min-height: 650px; /* Adjust this value based on the tallest card */
}

/* Add padding to align the carousel on mobile */
@media (max-width: 768px) {
  .carousel-inner {
    padding-top: 20px; /* Add padding at the top for mobile */
  }
}
</style>
