<template>
  <div
    class="modal fade"
    id="uploadCamperSearchModal"
    tabindex="-1"
    aria-labelledby="uploadCamperSearchModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="uploadCamperSearchModalLabel">
            Search For Existing Customer
          </h5>

          <button
            type="button"
            class="btn-close"
            id="uploadCamperSearchModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center mb-5">
            <form class="d-flex justify-content-center">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                style="
                  margin-bottom: 10px;
                  min-width: 300px;
                  max-width: 500px;
                  margin-left: 15px;
                "
                v-model="searchQuery"
              />
            </form>
          </div>
          <div class="card" style="max-height: 400px; overflow: auto">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="person in resultQuery"
                  :key="person._id"
                  style="cursor: pointer"
                  @click="selectPerson(person)"
                >
                  <td>{{ person.firstName }} {{ person.lastName }}</td>
                  <td>{{ person.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  data() {
    return {
      people: [],
      user: {},
      searchQuery: '',
    }
  },
  components: {},
  props: {},
  methods: {
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      this.user = VueJwtDecode.decode(token)
    },
    async getAllPeople() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'crm/getAllPeople?id=' + this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response)
          this.people = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    selectPerson(person) {
      document.getElementById('uploadCamperSearchModalClose').click()
      this.$emit('selectedPerson', person)
    },
  },
  created() {
    this.getUserDetails()
    this.getAllPeople()
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.people.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              v =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v) //||
              // item.email.toLowerCase().includes(v) ||
              // item.phoneNumber.includes(v)
            )
        })
      } else {
        return this.people
      }
    },
  },
}
</script>

<style scoped></style>
