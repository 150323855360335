<template>
  <div class="entryPointTheme">
    <div v-if="isActive === 1" v-html="html" class="entryPointTheme"></div>
    <lockout v-if="isActive === 2"></lockout>
    <StaticAd v-if="isActive === 3"></StaticAd>
    <CustomTemplateOne
      v-if="isActive === 4"
      :website="website"
    ></CustomTemplateOne>
  </div>
</template>

<script>
import axios from 'axios'
import Lockout from '../components/Lockout.vue'
import StaticAd from '../components/StaticAd.vue'
import CustomTemplateOne from '../../recruits/views/AthleteProfileTemplates/CustomTemplateOne/Container.vue'
export default {
  components: {
    Lockout,
    StaticAd,
    CustomTemplateOne,
  },
  data() {
    return {
      activeStatus: 0,
      html: null,
      website: {},
      contact: '',
    }
  },
  methods: {
    async getHtml() {
      const hostname = window.location.hostname
      const parts = hostname.split('.')
      const subdomain = parts[0]
      let url =
        import.meta.env.VITE_APP_DB_URL + 'ai/getHtml?subDomain=' + subdomain
      axios
        .get(url)
        .then(response => {
          console.log(response)

          if (response.data) {
            if (response.data.newVersion) {
              if (
                response.data.website.websiteTemplateType ===
                'CustomTemplateOne'
              ) {
                this.activeStatus = 4
                this.website = response.data.website
              }
            } else {
              this.activeStatus = 1
              this.html = response.data
            }
          } else {
            this.activeStatus = 1
            this.html = response.data
          }
        })
        .catch(err => {
          let error = err.response
          console.log(error.status)
          if (error.status == 403) {
            console.log('here')
            this.activeStatus = 2
          } else {
            this.activeStatus = 3
          }
          //swal("Error", "Website could not be found", "error");
        })
    },
  },
  computed: {
    isActive() {
      return this.activeStatus
    },
  },
  mounted() {
    setTimeout(function () {
      const minutes = document.getElementById('minutes')
      const seconds = document.getElementById('seconds')
      const buyWebsiteButton = document.getElementById('keepmywebsite')
      const buyWebsiteButton1 = document.getElementById('keepmywebsite1')
      const buyWebsiteButton2 = document.getElementById('keepmywebsite2')
      const goCountown = () => {
        //the final date
        if (minutes.innerText) {
          if (parseInt(seconds.innerText) === 0) {
            let minutesText = parseInt(minutes.innerText - 1)
            if (minutesText < 10) minutesText = '0' + minutesText
            minutes.innerText = minutesText.toString()
            seconds.innerText = '59'
          } else {
            let secondsText = parseInt(seconds.innerText) - 1
            if (secondsText < 10) secondsText = '0' + secondsText
            seconds.innerText = secondsText.toString()
          }
        } else {
          minutes.innerText = '10'
          seconds.innerText = '00'
        }
      }
      if (!minutes.innerText) {
        console.log('DONE')
        goCountown()
        setInterval(goCountown, 1000)
      }
      buyWebsiteButton.addEventListener('click', function () {
        let contactVariable = window.location.search.split('?')[1]
        let contact = contactVariable.split('=')[1]
        window.location.href =
          import.meta.env.VITE_APP_CLIENT_URL +
          'AIDemoExternalCheckout?contact=' +
          contact
      })
      buyWebsiteButton1.addEventListener('click', function () {
        console.log('here')
        let contactVariable1 = window.location.search.split('?')[1]
        let contact1 = contactVariable1.split('=')[1]
        window.location.href =
          import.meta.env.VITE_APP_CLIENT_URL +
          'AIDemoExternalCheckout?contact=' +
          contact1
      })
      buyWebsiteButton2.addEventListener('click', function () {
        let contactVariable2 = window.location.search.split('?')[1]
        let contact2 = contactVariable2.split('=')[1]
        window.location.href =
          import.meta.env.VITE_APP_CLIENT_URL +
          'AIDemoExternalCheckout?contact=' +
          contact2
      })
    }, 2500)
  },
  created() {
    this.getHtml()
  },
}
</script>
<style>
.entryPointTheme {
  background-image: url('../../../public/plain-white-background.jpg');
}
</style>
