<template>
  <!--  <div v-if="isMobile()">-->
  <!--    <CoachDashboardMobile></CoachDashboardMobile>-->
  <!--  </div>-->
  <div class="wrapper container justify-content-center" id="app">
    <Layout :user="user"></Layout>
    <!--    <Tour>-->

    <section class="container justify-content-center">
      <div class="container mt-5 justify-content-center">
        <div class="row justify-content-center">
          <div class="col-md-5 col-sm-5">
            <div class="card-form" style="margin-bottom: 15px">
              <div
                class="card-form__inner"
                v-bind:style="{
                  width: isMobile ? '99%' : '',
                  marginLeft: isMobile ? '21px' : '',
                  textAlign: isMobile ? 'center' : '',
                  marginTop: isMobile ? '21px' : '',
                }"
              >
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>My Athlete Profile</b>
                  </h3>
                  <hr />
                  <h1 class="text-center">QR Code</h1>
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <qrcode-vue
                        :value="getQRLink"
                        :size="getQRSize"
                        level="H"
                        class="center"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row center justify-content-center">
                    <center>
                      <h5>
                        <a
                          :href="getQRLink"
                          target="_blank"
                          class="link"
                          style="color: #0d6efd; text-underline: #0d6efd"
                        >
                          Personal Athlete Profile Link</a
                        >
                      </h5>
                    </center>
                  </div>
                  <hr />
                  <div class="row justify-content-center">
                    <h5 class="text-center mb-3">
                      Share Your Talent!
                      <a
                        :href="getTwitterShareLink"
                        target="_blank"
                        style="
                          all: unset;
                          cursor: pointer;
                          text-decoration: none;
                        "
                      >
                        <i class="fa-brands fa-square-x-twitter fa-xl"></i>
                      </a>
                    </h5>
                  </div>
                  <!--                  <div class="row justify-content-center">-->
                  <!--                    <div class="col text-center"></div>-->
                  <!--                    <div class="col text-center">-->
                  <!--                      <a-->
                  <!--                        :href="getFacebookShareLink"-->
                  <!--                        target="_blank"-->
                  <!--                        style="all: unset; cursor: pointer; color: #0866ff"-->
                  <!--                      >-->
                  <!--                        <i class="fa-brands fa-square-facebook fa-2xl"></i>-->
                  <!--                      </a>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-sm-5 justify-content-center">
            <div class="card-form v-step-0" style="margin-bottom: 15px">
              <div
                class="card-form__inner"
                v-bind:style="{
                  width: isMobile ? '99%' : '',
                  marginLeft: isMobile ? '21px' : '',
                  textAlign: isMobile ? 'center' : '',
                }"
              >
                <div>
                  <h3 class="card-title">
                    <b>Profile Views </b>
                  </h3>
                  <hr />
                  <br />
                  <h4 class="card-text">Total Views: {{ getTotalViews }}</h4>

                  <hr />
                  <h4 class="card-text">
                    Views this Month: {{ getTotalViews }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//import axios from "axios";
//import swal from "sweetalert";
import Layout from '../components/Layout.vue'
import QrcodeVue from 'qrcode.vue'
import axios from 'axios'

export default {
  components: {
    Layout,
    QrcodeVue,
  },
  data() {
    return {
      value: 'https://example.com',
      websites: [],
      size: 150,
      user: {},
      totalCash: null,
      payoutInfo: {
        payoutTotal: 0,
      },
      accountBalance: 0,
      deletedCamps: [],
      deletedMemberships: [],
      totalCampers: null,
      tshirts: {
        youthSmall: null,
        youthMedium: null,
        youthLarge: null,
        adultSmall: null,
        adultMedium: null,
        adultLarge: null,
        adultXLarge: null,
        adultXXLarge: null,
      },
      camps: [],
      teamCamps: [],
      memberships: [],
    }
  },
  methods: {
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },

    getAIWebsites() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'recruit/getRecruitWebsites?id=' +
        this.user._id
      axios.get(url).then(response => {
        this.websites = response.data.aiWebsites
      })
    },

    shareTwitter() {},

    //   getRecruit() {
    //     let url =
    //       import.meta.env.VITE_APP_DB_URL + "recruit/getRecruit?id=" + this.user._id;
    //     axios
    //       .get(url, {
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       })
    //       .then((response) => {
    //         this.recruit = response.data;
    //       });
    //   },
  },
  created() {
    this.getUserDetails()
    this.getAIWebsites()
  },

  mounted() {},

  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getWebsiteLink() {
      return '/coach/' + this.user._id
    },

    getQRLink() {
      if (this.user.aiWebsites.length > 0) {
        const subdomain = this.user.aiWebsites[0].subdomain
        return 'https://' + subdomain + '.summerathletes.com'
      } else if (this.websites.length > 0) {
        return 'https://' + this.websites[0].subdomain + '.summerathletes.com'
      } else {
        return 'https://summerathletes.com'
      }
    },

    getTotalViews() {
      return this.user.analytics.totalViews
        ? this.user.analytics.totalViews
        : 'TBD'
    },

    getQRSize() {
      return '200'
    },

    getTwitterShareLink() {
      let link = 'https://twitter.com/intent/tweet?text='
      let text =
        "Check%20out%20this%20new%20athlete%20profile!%20It's%20the%20best%20way%20to%20showcase%20your%20skills%20to%20scouts%20and%20recruiters! \n" +
        this.getQRLink
      return link + text
    },

    getFacebookShareLink() {
      let link = 'https://www.facebook.com/sharer/sharer.php?u='
      let text = this.getQRLink
      return link + text
    },
  },
}
</script>
<style scoped>
.link {
  color: #0d6efd;
  text-decoration: underline;
}

.link:hover {
  opacity: 0.8;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
