<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          :src="this.membershipLogo"
          class="card-img-top rounded-corners"
          id="logoTop"
        />

        <div class="card-body" style="margin-top: 10px">
          <h5 class="card-title" style="margin-bottom: 10px">
            {{ this.membership.membershipName }}
          </h5>
          <p
            class="card-text"
            style="white-space: pre-wrap; margin-bottom: 5px"
          >
            {{ this.membership.membershipDescription }}
          </p>
        </div>
        <ul class="list-group">
          <li class="list-group-item">
            Location: {{ this.membership.membershipLocation }}
          </li>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-if="this.membership.membershipPayPeriod.weeklyPrice"
            >
              <span>
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="weeklyPrice"
                  v-model="this.paymentPeriodSelected"
                />
                <label
                  style="margin-left: 15px"
                  @click="this.paymentPeriodSelected = 'weeklyPrice'"
                >
                  Weekly Price: ${{ this.getWeeklyPrice }}
                </label>
              </span>
            </li>
            <li
              class="list-group-item"
              v-if="this.membership.membershipPayPeriod.monthlyPrice"
            >
              <span>
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="monthlyPrice"
                  checked
                  v-model="this.paymentPeriodSelected"
                />
                <label
                  style="margin-left: 15px"
                  @click="this.paymentPeriodSelected = 'monthlyPrice'"
                >
                  Monthly Price: ${{ this.getMonthlyPrice }}
                </label>
              </span>
            </li>
            <li
              class="list-group-item"
              v-if="this.membership.membershipPayPeriod.semiAnnualPrice"
            >
              <span>
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="semiAnnualPrice"
                  v-model="this.paymentPeriodSelected"
                />
                <label
                  style="margin-left: 15px"
                  @click="this.paymentPeriodSelected = 'semiAnnualPrice'"
                >
                  Semi-Annual Price: ${{ this.getSemiAnnualPrice }}
                </label>
              </span>
            </li>
            <li
              class="list-group-item"
              v-if="this.membership.membershipPayPeriod.annualPrice"
            >
              <span>
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="annualPrice"
                  v-model="this.paymentPeriodSelected"
                />
                <label
                  style="margin-left: 15px"
                  @click="this.paymentPeriodSelected = 'annualPrice'"
                >
                  Annual Price: ${{ this.getAnnualPrice }}
                </label>
              </span>
            </li>
          </ul>
        </ul>
        <button
          class="card-form__button rounded-pill"
          :style="{ backgroundColor: primaryColor, color: 'white' }"
          @click="proceed"
        >
          {{ this.buttonName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
export default {
  components: {},
  props: {
    membership: Object,
    membershipLogo: String,
    membershipWaiver: String,
    buttonName: String,
    hidePlatformFees: Boolean,
    chosenPaymentPeriodSelected: Object,
    totalMembershipUpchargePercentage: Number,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
  },
  data() {
    return {
      paymentPeriodSelected: this.chosenPaymentPeriodSelected,
    }
  },
  methods: {
    async proceed() {
      if (this.paymentPeriodSelected) {
        swal({
          title: 'Accept Liability and Recurring Charges',
          text: this.membershipWaiver,
          icon: 'warning',
          buttons: ['Cancel', 'I understand and accept.'],
          dangerMode: true,
        }).then(isConfirm => {
          if (isConfirm) {
            let data = {
              paymentPeriodSelected: this.paymentPeriodSelected,
            }
            this.$emit('proceed', data)
          } else {
            swal('Cancelled', 'Registration cancelled.', 'error')
          }
        })
      } else {
        swal({
          title: 'Error',
          text: 'Please select a payment period to continue.',
          icon: 'error',
          dangerMode: true,
        })
      }
    },
  },
  created() {},
  computed: {
    getWeeklyPrice() {
      if (this.hidePlatformFees) {
        let membershipPrice = this.membership.membershipPayPeriod.weeklyPrice
        if (membershipPrice < 50) {
          return (membershipPrice + 4.99).toFixed(2)
        } else {
          return (
            membershipPrice *
              (1 + this.totalMembershipUpchargePercentage / 100) -
            0.01
          ).toFixed(2)
        }
      }
      return this.membership.membershipPayPeriod.weeklyPrice.toFixed(2)
    },
    getMonthlyPrice() {
      if (this.hidePlatformFees) {
        let membershipPrice = this.membership.membershipPayPeriod.monthlyPrice
        if (membershipPrice < 50) {
          return (membershipPrice + 4.99).toFixed(2)
        } else {
          return (
            membershipPrice *
              (1 + this.totalMembershipUpchargePercentage / 100) -
            0.01
          ).toFixed(2)
        }
      }

      return this.membership.membershipPayPeriod.monthlyPrice.toFixed(2)
    },
    getSemiAnnualPrice() {
      if (this.hidePlatformFees) {
        let membershipPrice =
          this.membership.membershipPayPeriod.semiAnnualPrice
        if (membershipPrice < 50) {
          return (membershipPrice + 4.99).toFixed(2)
        } else {
          return (
            membershipPrice *
              (1 + this.totalMembershipUpchargePercentage / 100) -
            0.01
          ).toFixed(2)
        }
      }
      return this.membership.membershipPayPeriod.semiAnnualPrice.toFixed(2)
    },
    getAnnualPrice() {
      if (this.hidePlatformFees) {
        let membershipPrice = this.membership.membershipPayPeriod.annualPrice
        if (membershipPrice < 50) {
          return (membershipPrice + 4.99).toFixed(2)
        } else {
          return (
            membershipPrice *
              (1 + this.totalMembershipUpchargePercentage / 100) -
            0.01
          ).toFixed(2)
        }
      }
      return this.membership.membershipPayPeriod.annualPrice.toFixed(2)
    },
  },
  mounted() {
    if (this.membership.membershipPayPeriod.weeklyPrice) {
      this.paymentPeriodSelected = 'weeklyPrice'
    } else if (this.membership.membershipPayPeriod.monthlyPrice) {
      this.paymentPeriodSelected = 'monthlyPrice'
    } else if (this.membership.membershipPayPeriod.semiAnnualPrice) {
      this.paymentPeriodSelected = 'semiAnnualPrice'
    } else if (this.membership.membershipPayPeriod.annualPrice) {
      this.paymentPeriodSelected = 'annualPrice'
    } else {
      this.paymentPeriodSelected = ''
    }
  },
}
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
