<template>
  <div
    :class="{
      reset: true,
      loginFootball: getBackgroundClass === 'loginFootball',
      loginBaseball: getBackgroundClass === 'loginBaseball',
      loginBasketball: getBackgroundClass === 'loginBasketball',
      loginSoccer: getBackgroundClass === 'loginSoccer',
    }"
  >
    <ResetPassword />
  </div>
</template>
<script>
import ResetPassword from '../components/ResetPasswordComponent.vue'
export default {
  components: {
    ResetPassword,
  },
  computed: {
    getBackgroundClass() {
      const classes = [
        'loginFootball',
        'loginBaseball',
        'loginBasketball',
        'loginSoccer',
      ]
      return classes[Math.floor(Math.random() * classes.length)]
    },
  },
}
</script>
