<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="this.campLogo" class="card-img-top rounded-corners" />
        <ul class="list-group" style="margin-top: 20px">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>{{ this.campName }}</b>
            </div>
          </li>
          <li class="list-group-item">
            <div
              v-if="
                !hidePlatformFees ||
                addOns.length >= 1 ||
                this.getRecruitCost > 0 ||
                this.additionalCampers.length > 0
              "
              class="d-flex justify-content-between"
            >
              <div>
                {{ this.camperInfo.firstName + ' ' + this.camperInfo.lastName }}
                Registration
              </div>
              <div
                v-if="
                  this.hidePlatformFees &&
                  this.getAddOnTotal === 0 &&
                  this.additionalCampers.length === 0
                "
              >
                ${{ this.getFormattedTotalNoAddOns }}
              </div>
              <div
                v-else-if="
                  this.hidePlatformFees &&
                  this.getAddOnTotal === 0 &&
                  this.additionalCampers.length > 0
                "
              >
                ${{ this.getFormattedBaseCamperPrice }}
              </div>
              <div v-else>${{ this.getFormattedInitial }}</div>
            </div>
            <div v-for="index in addOns.length" :key="index">
              <CustomAddOnSummary
                :field="addOns[index - 1]"
              ></CustomAddOnSummary>
            </div>
            <div v-if="this.recruitPurchase">
              <div>
                <div class="d-flex justify-content-between">
                  <div>Athlete Profile</div>
                  <div>${{ recruitSubscriptionPrice }}.00 / month</div>
                </div>
              </div>
            </div>
            <div
              v-for="(camper, camperIndex) in additionalCampers"
              :key="camperIndex * 100"
            >
              <div
                v-for="(addOn, index) in camper.addOns"
                :key="(index + camperId) * 10"
              >
                <CustomAddOnSummary :field="addOn"></CustomAddOnSummary>
              </div>
            </div>
            <div v-if="this.additionalCampers.length > 0">
              <div
                v-for="(camper, index) in this.additionalCampers"
                :key="index"
              >
                <AdditionalCamperSummary
                  :camperNumber="index + 1"
                  :camperPrice="getCamperPrice(index)"
                  :camperName="camper.firstName + ' ' + camper.lastName"
                  :camperId="camper.camperId"
                ></AdditionalCamperSummary>

                <div v-if="camper.recruitPurchase">
                  <div>
                    <div class="d-flex justify-content-between">
                      <div>Athlete Profile</div>
                      <div>${{ recruitSubscriptionPrice }}.00 / month</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!this.hidePlatformFees"
              class="d-flex justify-content-between"
            >
              <div @click="toggleAllFees()">
                Transaction/Processing Fee
                <i
                  v-if="!this.showAllFees && this.getTotal > 30"
                  class="fa-solid fa-caret-right"
                ></i>
                <i
                  v-else-if="this.showAllFees && this.getTotal > 30"
                  class="fa-solid fa-caret-down"
                ></i>
              </div>
              <div>~${{ getTransactionFees }}</div>
            </div>
            <div
              v-if="!this.hidePlatformFees && this.showAllFees"
              class="d-flex justify-content-between"
            >
              <div class="mx-3">- Summer Athletes Platform Fee</div>
              <div>~${{ getPlatformFee.toFixed(2) }}</div>
            </div>

            <div
              v-if="!this.hidePlatformFees && this.showAllFees"
              class="d-flex justify-content-between"
            >
              <div class="mx-3" v-if="this.paymentType === 'creditcard'">
                - Credit Card Processing Fee
              </div>
              <div class="mx-3" v-else>- PayPal Processing Fee</div>
              <div>~${{ getCreditFee.toFixed(2) }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Total</div>
              <div>
                <div v-if="getRecruitCost > 0">
                  <s class="fst-italic text-muted">
                    ${{ parseFloat(getOriginalTotal).toFixed(2) }}</s
                  >
                </div>
                <div>${{ getFormattedTotal }}</div>
              </div>
            </div>
          </li>
          <div v-if="getRecruitCost > 0">
            <p style="font-size: 12px; color: grey" class="text-end">
              You saved ${{
                (parseFloat(getOriginalTotal) - parseFloat(getTotal)).toFixed(2)
              }}
              with your Athlete Profile!
            </p>
          </div>
          <a
            href="#"
            v-if="!this.hideRecruitAds && !this.getRecruitCost > 0"
            style="color: #0000ee"
            @click="showAthleteProfileAd()"
            ><div class="d-flex justify-content-end fst-italic">
              Save ${{ getTransactionFees }}?
            </div></a
          >
        </ul>

        <div
          v-if="hasDiscountCode && this.additionalCampers.length === 0"
          class="d-flex justify-content-between row"
        >
          <div class="col mt-4">
            <input
              type="text"
              id="form6Example4"
              class="form-control"
              placeholder="Discount Code"
              v-model="camperDiscountCode"
            />
          </div>
          <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <button
              class="card-form__button"
              @click="applyDiscountCode"
              style="max-height: 60%"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
            >
              Apply
            </button>
          </div>
        </div>

        <div>
          <div v-if="this.expressCheckout">
            <PayPal
              class="mt-3"
              :campId="this.campId"
              :camperInfo="this.camperInfo"
              :emergencyContactInfo="this.emergencyContactInfo"
              :customFieldValues="this.customFieldValues"
              :addOns="this.addOns"
              :discountCode="this.camperDiscountCode"
              :fullPrice="this.getTotal"
              :paymentType="this.paymentType"
              :dates="this.dates"
              :additionalCampers="this.additionalCampers"
              :multipleCamperPrices="this.multipleCamperPrices"
              :recruitPurchase="this.recruitPurchase"
              :hideRecruitAds="this.hideRecruitAds"
              @checkedOut="this.checkedOut"
            ></PayPal>
            <br />
            <br />
            <br />
            <button
              :class="{
                'card-form__button': true,
                'w-25': !isMobile,
                'w-50': isMobile,
                'rounded-pill': true,
              }"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
          <div v-else>
            <br />
            <button
              class="card-form__button rounded-pill"
              :style="{
                backgroundColor: primaryColor,
                color: 'white',
              }"
              @click="submitPayment"
            >
              {{
                this.initial + this.getAddOnTotal === 0
                  ? 'Register Now'
                  : `Pay $${this.getFormattedTotal}`
              }}
            </button>
            <br />
            <br />
            <br />
            <button
              :class="{
                'card-form__button': true,
                'w-25': !isMobile,
                'w-50': isMobile,
                'rounded-pill': true,
              }"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import CustomAddOnSummary from './../CustomAddOnSummary.vue'
import AdditionalCamperSummary from '../AdditionalCamperSummary.vue'
import axios from 'axios'
import PayPal from './PayPal.vue'
import numeral from 'numeral'
export default {
  components: {
    PayPal,
    CustomAddOnSummary,
    AdditionalCamperSummary,
  },
  props: {
    campLogo: String,
    campName: String,
    initial: Number,
    addOns: Array,
    discounts: String,
    hidePlatformFees: Boolean,
    expressCheckout: Boolean,
    campId: String,
    camperInfo: Object,
    emergencyContactInfo: Object,
    customFieldValues: Array,
    paymentType: String,
    totalUpchargePercentage: Number,
    dates: String,
    additionalCampers: Array,
    multipleCamperPrices: Array,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
    recruitPurchase: Boolean,
    recruitSubscriptionPrice: Number,
    hideRecruitAds: Boolean,
  },
  data() {
    return {
      totalPrice: 0,
      campDisplayName: '',
      camperDiscountCode: '',
      approvedFreeCamp: false,
      showAllFees: false,
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    showAthleteProfileAd() {
      swal({
        title: 'Save Big When You Create Your Athlete Profile!',
        text: "We'll cover transaction fees when you sign up for an Athlete Profile. Receive event updates and direct messaging with your coach, view your upcoming events, and make an athlete profile + website for scouts and recruiters.",
        icon: 'info',
        buttons: ['I already have an Athlete Profile', 'Get Discount!'],
      }).then(willConfirm => {
        if (willConfirm) {
          this.recruitPurchase = true
          for (let additionalCamper of this.additionalCampers) {
            additionalCamper.recruitPurchase = true
          }
          this.$emit('addingRecruitPurchase')
          if (this.camperDiscountCode) {
            this.applyDiscountCode()
          }
        }
      })
    },
    applyDiscountCode() {
      //axios post to check if discount code is valid
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/checkDiscountCode?discountCode=' +
        this.camperDiscountCode +
        '&campId=' +
        this.$route.params.campId
      axios
        .get(url, {
          discountCode: this.camperDiscountCode,
        })
        .then(response => {
          if (response.status === 200) {
            this.initial = response.data.discountPrice
            if (this.initial == '0') {
              if (response.data.approvedFreeCamp) {
                this.approvedFreeCamp = response.data.approvedFreeCamp
              }
            }
            swal({
              title: 'Success!',
              text: 'Discount code applied successfully!',
              icon: 'success',
              button: 'OK',
            })
          } else {
            swal({
              title: "We're Sorry!",
              text: 'Discount Code Invalid',
              icon: 'error',
              button: 'OK',
            })
          }
        })
        .catch(error => {
          console.log(error)
          swal({
            title: "We're Sorry!",
            text: 'Discount Code Invalid',
            icon: 'error',
            button: 'OK',
          })
        })
    },
    toggleAllFees() {
      if (this.getTotal >= 30) {
        this.showAllFees = !this.showAllFees
      }
    },

    checkedOut() {
      this.$emit('checkedOut')
    },

    getCamperPrice(index) {
      if (this.multipleCamperPrices.length > 0) {
        if (index < this.multipleCamperPrices.length) {
          return this.multipleCamperPrices[index].camperPrice
        } else {
          return this.multipleCamperPrices[this.multipleCamperPrices.length - 1]
            .camperPrice
        }
      } else {
        return this.initial
      }
    },
    submitPayment() {
      this.$emit('proceed', this.camperDiscountCode)
    },
  },
  created() {},
  computed: {
    getAddOnTotal() {
      let temp = 0
      for (let x = 0; x < this.addOns.length; x++) {
        if (this.addOns[x].isPurchased) {
          temp = temp + this.addOns[x].addOnPrice
        }
      }
      for (const camper of this.additionalCampers) {
        for (const addOn of camper.addOns) {
          if (addOn.isPurchased) {
            temp = temp + addOn.addOnPrice
          }
        }
      }
      return temp
    },
    getTotalNoAddOnsNoAdditionalCampers() {
      let total = this.getTotal - this.getAddOnTotal
      if (this.recruitPurchase) {
        total = total - this.recruitSubscriptionPrice
      }
      console.log(total)
      for (const additionalCamperIndex in this.additionalCampers) {
        if (this.multipleCamperPrices.length > 0) {
          if (additionalCamperIndex < this.multipleCamperPrices.length) {
            total =
              total -
              this.multipleCamperPrices[additionalCamperIndex].camperPrice
          } else {
            total =
              total -
              this.multipleCamperPrices[this.multipleCamperPrices.length - 1]
                .camperPrice
          }
        } else {
          total = total - this.initial
        }
        if (this.additionalCampers[additionalCamperIndex].recruitPurchase) {
          total = total - this.recruitSubscriptionPrice
        }
      }
      return total
    },
    getTotalNoAddOns() {
      return this.initial
    },
    getTotal() {
      let total = this.initial + this.getAddOnTotal
      const recruitPurchaseTotal = this.getRecruitCost
      for (const index in this.additionalCampers) {
        total = total + this.getCamperPrice(index)
      }
      if (total === 0) {
        total += recruitPurchaseTotal
        return Number(recruitPurchaseTotal).toFixed(2)
      } else if (this.getRecruitCost === 0) {
        if (total < 30) {
          total = total + 0.99
          total =
            this.paymentType == 'venmo' ||
            this.paymentType == 'paypal' ||
            this.paymentType == 'paylater'
              ? total + 1
              : total
          total += recruitPurchaseTotal
          return Number(total).toFixed(2)
        } else {
          if (this.paymentType === 'ACHPayment') {
            total =
              total * (1 + (this.totalUpchargePercentage - 3) / 100) - 0.01
            total += recruitPurchaseTotal
            return Number(total).toFixed(2)
          } else {
            total = total * (1 + this.totalUpchargePercentage / 100) - 0.01
            total =
              this.paymentType == 'venmo' ||
              this.paymentType == 'paypal' ||
              this.paymentType == 'paylater'
                ? total + 1
                : total
            total += recruitPurchaseTotal
            return Number(total).toFixed(2)
          }
        }
      } else {
        total += recruitPurchaseTotal
        return total
      }
    },
    getPlatformFee() {
      if (this.initial + this.addOns === 0) {
        return 0.0
      } else {
        if (
          this.paymentType === 'creditcard' ||
          this.paymentType === 'ACHPayment'
        ) {
          if (this.initial + this.addOns < 30) {
            return 0.99
          } else {
            return parseFloat(
              (this.getTotal * (this.totalUpchargePercentage - 3)) / 100
            )
          }
        } else {
          if (this.initial + this.addOns < 30) {
            return 1.99
          } else {
            return (
              parseFloat(
                (this.getTotal * (this.totalUpchargePercentage - 3)) / 100
              ) + 1.0
            )
          }
        }
      }
    },

    getOriginalTotal() {
      let total = this.initial + this.getAddOnTotal
      const recruitPurchaseTotal = this.getRecruitCost
      for (const index in this.additionalCampers) {
        total = total + this.getCamperPrice(index)
      }
      if (total === 0) {
        total += recruitPurchaseTotal
        return Number(recruitPurchaseTotal).toFixed(2)
      } else if (total < 30) {
        total = total + 0.99
        total =
          this.paymentType == 'venmo' ||
          this.paymentType == 'paypal' ||
          this.paymentType == 'paylater'
            ? total + 1
            : total
        total += recruitPurchaseTotal
        return Number(total).toFixed(2)
      } else {
        if (this.paymentType === 'ACHPayment') {
          total = total * (1 + (this.totalUpchargePercentage - 3) / 100) - 0.01
          total += recruitPurchaseTotal
          return Number(total).toFixed(2)
        } else {
          total = total * (1 + this.totalUpchargePercentage / 100) - 0.01
          total =
            this.paymentType == 'venmo' ||
            this.paymentType == 'paypal' ||
            this.paymentType == 'paylater'
              ? total + 1
              : total
          total += recruitPurchaseTotal
          return Number(total).toFixed(2)
        }
      }
    },

    getCampPriceWithAdditionalCampers() {
      let total = this.initial
      for (const additionalCamperIndex in this.additionalCampers) {
        total += this.getCamperPrice(additionalCamperIndex)
      }
      return total
    },

    getCreditFee() {
      return (
        parseFloat(this.getTotal) -
        parseFloat(this.getCampPriceWithAdditionalCampers) -
        parseFloat(this.getPlatformFee) -
        parseFloat(this.getAddOnTotal)
      )
    },
    getTransactionFees() {
      return (this.getPlatformFee + this.getCreditFee).toFixed(2)
    },
    hasDiscountCode() {
      if (this.discounts.length > 0) {
        return true
      } else {
        return false
      }
    },
    getFormattedTotal() {
      return numeral(this.getTotal).format('0,0.00')
    },

    getFormattedTotalNoAddOns() {
      return numeral(this.getTotalNoAddOns).format('0,0.00')
    },
    getFormattedBaseCamperPrice() {
      return numeral(this.getTotalNoAddOnsNoAdditionalCampers).format('0,0.00')
    },

    getFormattedInitial() {
      return numeral(this.initial).format('0,0.00')
    },

    getRecruitCost() {
      let total = 0
      if (this.recruitPurchase) {
        total += this.recruitSubscriptionPrice
      }
      for (const camper of this.additionalCampers) {
        if (camper.recruitPurchase) {
          total += this.recruitSubscriptionPrice
        }
      }
      return total
    },
  },
}
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
