<template>
  <div class="wrapper container" style="min-width: 100%">
    <ClientLayout :user="user"></ClientLayout>
    <v-tour name="viewMembershipTour" :steps="determineSteps"></v-tour>
    <section class="container">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
      >
        <div
          class="card-form"
          v-show="!this.messageBool && !this.textBool"
          style="min-width: 100%"
        >
          <div class="card-form__inner text-center">
            <center>
              <img
                :src="getMembershipLogo"
                class="card-img-top rounded-corners"
                id="logoTop"
                :style="{
                  display: 'block',
                  maxWidth: !isMobile() ? '100vh' : '100%',
                  maxHeight: '35vh',
                  width: 'auto',
                  height: 'auto',
                }"
              />
            </center>

            <h3 class="card-title mt-3">
              {{ this.membership.membershipName }}
            </h3>
            <div class="row mt-3 justify-content-center">
              <div
                class="information-card col-md-6 col-sm-4 membership-step-0 text-start"
              >
                <h3 class="text-center">Details</h3>
                <div class="m-2"><b>Type</b>: Recuring payment</div>
                <div class="m-2">
                  <b>Organization</b>:
                  {{ this.membership.membershipOrganization }}
                </div>

                <div class="m-2">
                  <b>Location</b>: {{ this.membership.membershipLocation }}
                </div>

                <div class="m-2">
                  <b>Current Registrants</b>: {{ getRegistrants }}
                </div>

                <div class="m-2" v-show="!getParticipants">
                  <b
                    >Maximum:
                    {{ this.membership.membershipMaxParticipants }}
                  </b>
                </div>

                <div v-show="getParticipants">
                  <b>Unlimited Participants</b>
                </div>

                <div v-show="this.membership.requireWaiver">
                  <b>Requiring waiver</b>
                </div>

                <div class="m-2" v-show="!this.membership.requireWaiver">
                  <b>Not requiring waiver</b>
                </div>

                <div class="m-2">
                  <div class="card-input">
                    <b for="description" class="card-input__label"
                      >Description/Bulletin</b
                    >
                    <textarea
                      type="textarea"
                      rows="4"
                      id="description"
                      class="form-control"
                      placeholder="Weekdays 5pm-7:30pm..."
                      v-model="membership.membershipDescription"
                      @change="updateDescription"
                    />
                  </div>
                </div>
              </div>

              <div class="information-card col-md-5 col-sm-4 membership-step-1">
                <h3>Payment Details</h3>
                <div><b>Revenue</b>: ${{ getMoney }}</div>
                <br />
                <div>
                  <div v-if="this.membership.membershipPayPeriod.weeklyPrice">
                    <b>Weekly Price</b>: ${{
                      this.membership.membershipPayPeriod.weeklyPrice
                    }}<br />
                  </div>
                  <div v-if="this.membership.membershipPayPeriod.monthlyPrice">
                    <b>Monthly Price</b>: ${{
                      this.membership.membershipPayPeriod.monthlyPrice
                    }}<br />
                  </div>
                  <div
                    v-if="this.membership.membershipPayPeriod.semiAnnualPrice"
                  >
                    <b>Semi-Annual Price</b>: ${{
                      this.membership.membershipPayPeriod.semiAnnualPrice
                    }}<br />
                  </div>
                  <div v-if="this.membership.membershipPayPeriod.annualPrice">
                    <b>Annual Price</b>: ${{
                      this.membership.membershipPayPeriod.annualPrice
                    }}
                  </div>
                </div>
                <br />

                <b>Annual Revenue Goal: $50,000 USD</b>
                <br />

                <b><i> Projected </i>Annual Revenue:</b>
                <div class="mt-3">
                  <VueEllipseProgress
                    :progress="getProjectedAnnualRevenuePercentage"
                    animation="rs 1500 700"
                    color="#19d412"
                    line-mode="in 10"
                    empty-color="#363166"
                  >
                    ${{ getProjectedAnnualRevenue }} / $50,000
                    <br />
                    <br />
                    Keep Going!
                  </VueEllipseProgress>
                </div>
              </div>
            </div>

            <div class="row center mt-3 justify-content-center">
              <div class="col-md-3 col-sm-6 membership-step-2">
                <h5 class="panel-title">Post On Landing Page</h5>

                <label class="switch">
                  <input
                    type="checkbox"
                    @click="toggleMembershipToWebsite"
                    :checked="this.membership.isPostedToWebsite"
                  />
                  <div class="slider round"></div>
                </label>
              </div>
              <div class="col-md-3 col-sm-6 membership-step-3">
                <h5 class="panel-title">Receive Purchase Notifications</h5>

                <label class="switch">
                  <input
                    type="checkbox"
                    @click="toggleSendEmailNotification"
                    :checked="this.membership.sendEmailNotification"
                  />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
            <h3 class="card-title mt-5">Active Links</h3>
            <div class="row mt-3 justify-content-center">
              <a
                class="card2 col-md-3 col-sm-6 membership-step-4"
                :href="getMembershipURL"
                target="_blank"
              >
                <h5>Payment Link</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>

              <a
                class="card2 col-md-3 col-sm-6 membership-step-5"
                :href="getQrLink"
                target="_blank"
              >
                <h5>Payment QR Code</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>
            </div>

            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans"
              rel="stylesheet"
            />

            <h3 class="card-title mt-5 membership-step-6">Action Hub</h3>

            <div class="row mt-3 justify-content-center">
              <div
                @click="memberList()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-users fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Customer List</label
                    >
                  </span>
                </h5>
                <p>View and manage every customer</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>
              <div
                @click="messageBool = true"
                class="information-card col-md-3 col-sm-6 actionHub"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-comments fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Message All</label
                    >
                  </span>
                </h5>
                <p>Message every customer</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>

              <div
                class="information-card col-md-3 col-sm-6 actionHub"
                @click="customizeReceipt()"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-file-invoice fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Customize Receipt</label
                    >
                  </span>
                </h5>
                <p class>Personalize your email receipts</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>

              <CustomizeEmailReceipt
                :userID="this.user._id"
                :membershipID="this.membership._id"
                :customEmailReceipt="this.membership.customEmailReceipt"
                :isMembership="true"
              ></CustomizeEmailReceipt>
              <button
                data-bs-toggle="modal"
                data-bs-target="#customizeEmailReceiptModal"
                id="customizeEmailReceiptTrigger"
                style="display: none"
              ></button>
              <div
                @click="copyMembership()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-copy fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Duplicate Link</label
                    >
                  </span>
                </h5>
                <p>Don't waste time re-typing</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <button
                  class="card-form__button rounded-pill"
                  @click="editMembership"
                  style="
                    margin-top: 10px;
                    margin-bottom: 10px;
                    background-color: #363166;
                  "
                >
                  Edit Payment Link
                </button>
              </div>

              <div class="col-md-6 col-sm-6">
                <button
                  class="card-form__button rounded-pill"
                  @click="deleteMembership"
                  style="
                    margin-top: 10px;
                    margin-bottom: 10px;
                    background-color: red;
                  "
                >
                  Delete Payment Link
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--Start of Message Fuctionality-->
        <div class="container" v-show="messageBool || textBool">
          <div class="row">
            <div v-if="!isMobile()" class="col-2">
              <br /><br /><br /><br /><br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">
                    Email <i class="fa-regular fa-envelope"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >Pro</span
                    >
                  </h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-3"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text <i class="fa-solid fa-mobile-screen"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >All Star</span
                    >
                  </h5>
                </div>
              </div>
              <!--            </div>-->
            </div>
            <div v-else>
              <br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">
                    Email <i class="fa-regular fa-envelope"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >Pro</span
                    >
                  </h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-3"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text <i class="fa-solid fa-mobile-screen"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >All Star</span
                    >
                  </h5>
                </div>
              </div>
              <br />
            </div>
            <div class="col">
              <div class="card-form" v-show="this.messageBool">
                <div class="card-form__inner">
                  <img
                    src="../../../../public/SummerAthletes.png"
                    class="card-img-top"
                  />
                  <div class="card-form">
                    <div class="card-input">
                      <label for="email" class="card-input__label"
                        >Subject</label
                      >
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Subject"
                        v-model="message.subject"
                      />
                    </div>
                    <div class="card-input">
                      <label for="email" class="card-input__label">Intro</label>
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Hi Everyone,"
                        v-model="message.intro"
                      />
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 1</label>

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 1"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body1"
                      ></textarea>
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 2</label>
                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 2 (optional)"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body2"
                      ></textarea>
                    </div>
                  </div>

                  <button
                    class="card-form__button rounded-pill"
                    @click="sendEmail"
                  >
                    Send email to all members
                  </button>
                  <button class="card-form__button rounded-pill">
                    <a @click="setTabStyle('back')">Back to membership</a>
                  </button>
                </div>
              </div>
              <!-- START OF TEXT MESSAGE PORTION-->
              <div class="card-form" v-show="this.textBool">
                <div class="card-form__inner">
                  <img
                    src="../../../../public/SummerAthletes.png"
                    class="card-img-top"
                  />
                  <div class="card-form">
                    <div class="card-input">
                      <label class="card-input__label">Text Message</label>

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Friendly reminder..."
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="text.content"
                      ></textarea>
                    </div>
                  </div>

                  <button
                    class="card-form__button rounded-pill"
                    @click="sendText"
                  >
                    Send text to all members
                  </button>
                  <button class="card-form__button rounded-pill">
                    <a @click="setTabStyle('back')">Back to membership</a>
                  </button>
                </div>
              </div>
              <!-- END OF TEXT MESSAGE PORTION-->
            </div>
          </div>
        </div>

        <!--End of Message Functionality-->
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment'
import ClientLayout from '../../components/ClientLayout.vue'
import { VueEllipseProgress } from 'vue-ellipse-progress'
import CustomizeEmailReceipt from '../../components/CustomizeEmailReceipt.vue'

export default {
  components: {
    CustomizeEmailReceipt,
    VueEllipseProgress,
    ClientLayout,
  },
  name: 'view-membership-tour',
  data() {
    return {
      steps: [
        {
          target: '.membership-step-0', // We're using document.querySelector() under the hood
          content: `The <strong>Details</strong> section shows all details of the camp you created.`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.membership-step-1', // We're using document.querySelector() under the hood
          content: `The <strong>Payment Details</strong> section gives an overview of all revenue information. <br/> <br /> It shows what the base price is, how much the camp has earned, and how close you are to your goals.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.membership-step-2', // We're using document.querySelector() under the hood
          content: `The <strong>Post on Landing Page</strong> toggle publishes your website to your free landing page. <br/> <br /> This makes the event public, and shows up when someone navigates to your landing page via your AI Website`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.membership-step-3', // We're using document.querySelector() under the hood
          content: `The <strong>Receive Purchase Notifications</strong> toggle tracks if you receive email alerts when someone pays via this link.`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.membership-step-4', // We're using document.querySelector() under the hood
          content: `The <strong>Link to Registration</strong> is how you get people signed up and <strong><em>make money</em></strong> on the platform! <br/> <br /> Be sure to post this out on social media as soon as possible, to help advertise for the event! We recommend at least <br/> <strong><em>one month in advance of advertising!</strong></em>`,
          params: {
            enableScrolling: false,
            placement: 'left',
            highlight: true,
          },
        },
        {
          target: '.membership-step-5', // We're using document.querySelector() under the hood
          content: `The <strong>QR Code</strong> takes your campers to the exact same page as the sign-up link! <br/> <br /> Please be sure to post this out wherever you can, it is most useful for both online and physical fliers!`,
          params: {
            enableScrolling: false,
            placement: 'right',
            highlight: true,
          },
        },
        {
          target: '.membership-step-6', // We're using document.querySelector() under the hood
          content: `The <strong>Action Hub</strong> is your camp command station. <br/> <br /> Manage your contacts, message your registrants, and work with your data, all in one spot!`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
      ],

      messageBool: false,
      textBool: false,
      message: {
        subject: '',
        intro: '',
        body1: '',
        body2: '',
      },
      text: {
        content: '',
      },
      membershipID: 0,
      user: {},
      temp: {},
      money: 0,
      membership: {
        membershipName: '',
        membershipDescription: '',
        membershipLocation: '',
        membershipLogo: '',
        membershipPayPeriod: {},
        membershipOrganization: '',
        membershipRegistrationDeadline: '',
        membershipMaxParticipants: '',
        requireWaiver: '',
        tShirtsProvided: '',
        members: [],
        textLimit: null,
        isPostedToWebsite: false,
        sendEmailNotification: false,
        colors: {
          primary: '',
          secondary: '',
        },
      },
      allMemberData: [],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toggleMembershipToWebsite() {
      this.membership.isPostedToWebsite = !this.membership.isPostedToWebsite
      let url =
        import.meta.env.VITE_APP_DB_URL + 'membership/toggleMembershipToWebsite'
      axios.post(
        url,
        {
          membershipID: this.membershipID,
          isPostedToWebsite: this.membership.isPostedToWebsite,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },
    toggleSendEmailNotification() {
      this.membership.sendEmailNotification =
        !this.membership.sendEmailNotification
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/toggleMembershipEmailNotification'
      axios.post(
        url,
        {
          membershipID: this.membershipID,
          sendEmailNotification: this.membership.sendEmailNotification,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    customizeReceipt() {
      if (this.user.subscription.subscriptionTier >= 3) {
        //run customize receipt modal
        document.getElementById('customizeEmailReceiptTrigger').click()
      } else {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: "You need to be at least an All Star user to customize your event's email receipts. Please upgrade your plan to continue.",
        })
        document.getElementById('openAdvertisementPopUp').click()
      }
    },

    copyMembership() {
      this.$router.push('/Dashboard/CreateMembership/' + this.membershipID)
    },

    async sendEmail() {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least a Pro user to send mass messages. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'membership/emailAllMembers'
      axios
        .post(
          url,
          {
            subject: this.message.subject,
            intro: this.message.intro,
            body1: this.message.body1,
            body2: this.message.body2,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            membershipID: this.membershipID,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
      swal('Success', 'Message Sent!', 'success')
    },
    async sendText() {
      if (this.user.subscription.subscriptionTier < 3) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least an All Star user to send mass texts. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'membership/textAllMembers'
      axios
        .post(
          url,
          {
            content: this.text.content,
            phoneNumber: this.user.phoneNumber,
            membershipID: this.membershipID,
            coachID: this.user._id,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.membership.textLimit += 1
            swal('Success', 'Message Sent!', 'success')
          } else {
            swal(
              'Sorry!',
              'You have already reached your text limit for this membership',
              'error'
            )
          }
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
    },
    dayToString(date) {
      let temp = new Date(date)
      return temp.toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    memberList() {
      this.$router.push('/Dashboard/MembershipCheckIn/' + this.membershipID)
    },

    async getMembership() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMembershipByMembershipID?id=' +
        this.membershipID
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.membership = response.data.membership
          if (response.data.membership.members.length < 3) {
            this.$tours['viewMembershipTour'].start()
          }
          this.addCustomFieldsExport()
          this.temp = response.data.membership
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getAllMemberData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getAllMemberData?membershipID=' +
        this.membershipID
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.allMemberData = response.data.allMemberData
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    editMembership() {
      if (this.membership.members.length > 0) {
        swal({
          title: "We're Sorry!",
          text: 'You cannot edit a recurring payment link that already has existing customers.  Please contact support@summerathletes.com and we can help you edit your payment link for you.',
          icon: 'warning',
        })
      } else {
        this.$router.push(
          '/Dashboard/CreateMembership/' + this.membershipID + '/edit'
        )
      }
    },

    //delete camps
    async deleteMembership() {
      swal({
        title: 'Confirm Deletion',
        text: 'Once the recurring payment link is deleted, all customer information is lost, and you are responsible for reaching out to them to refund and inform them of the cancellation. ',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          if (this.determineDeletable) {
            let url =
              import.meta.env.VITE_APP_DB_URL + 'membership/deleteMembership'
            axios
              .post(
                url,
                {
                  membershipID: this.membershipID,
                  coachID: this.user._id,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                  },
                }
              )
              .catch(function (err) {
                let error = err.response
                swal('Error', error.data.errorMessage, 'error')
              })
            swal('Success', 'Membership Deleted', 'success')
            this.$router.push('/Dashboard')
          } else {
            swal(
              'Error',
              'You still have existing members. You must cancel all memberships in the member list first.',
              'error'
            )
          }
        }
      })
    },
    setTabStyle(type) {
      this.messageBool = type === 'email'
      this.textBool = type === 'text'
      var emailTabHeader = document.getElementById('emailTabHeader')
      var emailTabBody = document.getElementById('emailTabBody')
      var textTabHeader = document.getElementById('textTabHeader')
      var textTabBody = document.getElementById('textTabBody')
      if (type === 'email') {
        emailTabHeader.style.backgroundColor = '#19d412'
        emailTabBody.style.backgroundColor = 'white'
        textTabHeader.style.backgroundColor = 'lightgrey'
        textTabBody.style.backgroundColor = 'whitesmoke'
      } else if (type === 'text') {
        textTabHeader.style.backgroundColor = '#19d412'
        textTabBody.style.backgroundColor = 'white'
        emailTabHeader.style.backgroundColor = 'lightgrey'
        emailTabBody.style.backgroundColor = 'whitesmoke'
      } else {
        emailTabHeader.style.backgroundColor = '#19d412'
        emailTabBody.style.backgroundColor = 'white'
        textTabHeader.style.backgroundColor = 'lightgrey'
        textTabBody.style.backgroundColor = 'whitesmoke'
      }
    },
    updateDescription() {
      const url =
        import.meta.env.VITE_APP_DB_URL + 'membership/updateMembership'
      axios
        .put(
          url,
          {
            coachID: this.user._id,
            membershipID: this.membershipID,
            newMembershipDescription: this.membership.membershipDescription,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          console.log(response.data)
          swal(
            'Success',
            'Description/Bulletin updated successfully. Your members will see the new updates on their account.',
            'success'
          )
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },

  created() {
    this.getUserDetails()
    this.membershipID = this.$route.params.id
    this.getMembership()
    this.getAllMemberData()
    // this.window.scrollTo(0, 0);
  },
  computed: {
    determineSteps() {
      if (this.isMobile()) {
        let steps = this.steps
        for (let step of steps) {
          step.params.placement = 'bottom'
        }
        return steps
      }
      return this.steps
    },
    determineDeletable() {
      let deletable = true
      for (const member of this.membership.members) {
        if (member.isActive) {
          deletable = false
          break
        }
      }
      return deletable
    },
    getMembershipLogo() {
      if (this.membership.membershipLogo != '') {
        return this.membership.membershipLogo
      }
      return 'https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png'
    },
    getFormattedData() {
      let exportData = []
      for (let x = 0; x++; x < this.membership.members.length) {
        exportData[x] = this.membership.members[x]
      }
      return exportData
    },
    getMembershipURL() {
      if (this.membership.colors) {
        return (
          import.meta.env.VITE_APP_MEMBERSHIP_URL +
          'membershipCheckout/' +
          this.membershipID +
          '/' +
          this.membership.colors.primary +
          '/' +
          this.membership.colors.secondary
        )
      }
      return (
        import.meta.env.VITE_APP_MEMBERSHIP_URL +
        'membershipCheckout/' +
        this.membershipID
      )
    },
    getQrLink() {
      if (this.membership.colors) {
        return (
          'https://api.qrserver.com/v1/create-qr-code/?data=' +
          import.meta.env.VITE_APP_MEMBERSHIP_URL +
          'membershipCheckout/' +
          this.membershipID +
          '/' +
          this.membership.colors.primary +
          '/' +
          this.membership.colors.secondary +
          '&amp;size=100x100'
        )
      }
      return (
        'https://api.qrserver.com/v1/create-qr-code/?data=' +
        import.meta.env.VITE_APP_MEMBERSHIP_URL +
        'membershipCheckout/' +
        this.membershipID +
        '&amp;size=100x100'
      )
    },
    getParticipants() {
      return this.membership.membershipMaxParticipants == null
    },
    getMoney() {
      let totalAmountCharged = 0

      for (const member of this.membership.members) {
        if (member.paymentPeriod === 'weeklyPrice') {
          const today = new Date()
          const weeklyPrice = this.membership.membershipPayPeriod.weeklyPrice
          const startDate = moment(member.startDate, 'YYYY-MM-DD')
          const current = moment(today, 'YYYY-MM-DD')
          const totalTimesCharged = current.diff(startDate, 'weeks') + 1
          totalAmountCharged += weeklyPrice * totalTimesCharged
        }
        if (member.paymentPeriod === 'monthlyPrice') {
          const today = new Date()
          const monthlyPrice = this.membership.membershipPayPeriod.monthlyPrice
          const startDate = moment(member.startDate, 'YYYY-MM-DD')
          const current = moment(today, 'YYYY-MM-DD')
          const totalTimesCharged = current.diff(startDate, 'months') + 1
          totalAmountCharged += monthlyPrice * totalTimesCharged
        }
        if (member.paymentPeriod === 'semiAnnualPrice') {
          const today = new Date()
          const semiAnnualPrice =
            this.membership.membershipPayPeriod.semiAnnualPrice
          const startDate = moment(member.startDate, 'YYYY-MM-DD')
          const current = moment(today, 'YYYY-MM-DD')
          const totalTimesCharged = current.diff(startDate, 'months')
          console.log(totalTimesCharged)
          totalAmountCharged +=
            semiAnnualPrice * (Math.floor(totalTimesCharged / 6) + 1)
        }
        if (member.paymentPeriod === 'annualPrice') {
          const today = new Date()
          const annualPrice = this.membership.membershipPayPeriod.annualPrice
          const startDate = moment(member.startDate, 'YYYY-MM-DD')
          const current = moment(today, 'YYYY-MM-DD')
          const totalTimesCharged = current.diff(startDate, 'years') + 1
          totalAmountCharged += annualPrice * totalTimesCharged
        }
      }
      return totalAmountCharged.toFixed(2)
    },
    getRegistrants() {
      return this.membership.members.length
    },
    getYS() {
      let size = 'YS'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYM() {
      let size = 'YM'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYL() {
      let size = 'YL'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAS() {
      let size = 'AS'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAM() {
      let size = 'AM'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAL() {
      let size = 'AL'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXL() {
      let size = 'AXL'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXXL() {
      let size = 'AXXL'
      let shirts = 0
      for (let i = 0; i < this.membership.members.length; i++) {
        if (this.membership.members[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },

    getProjectedAnnualRevenue() {
      let projectedAmount = 0

      for (const member of this.membership.members) {
        if (member.isActive) {
          if (member.paymentPeriod === 'weeklyPrice') {
            const weeklyPrice = this.membership.membershipPayPeriod.weeklyPrice
            projectedAmount += weeklyPrice * 52
          }
          if (member.paymentPeriod === 'monthlyPrice') {
            const monthlyPrice =
              this.membership.membershipPayPeriod.monthlyPrice
            projectedAmount += monthlyPrice * 12
          }
          if (member.paymentPeriod === 'semiAnnualPrice') {
            const semiAnnualPrice =
              this.membership.membershipPayPeriod.semiAnnualPrice
            projectedAmount += semiAnnualPrice * 2
          }
          if (member.paymentPeriod === 'annualPrice') {
            const annualPrice = this.membership.membershipPayPeriod.annualPrice
            projectedAmount += annualPrice * 1
          }
        }
      }

      return projectedAmount.toFixed(2)
    },

    getProjectedAnnualRevenuePercentage() {
      let projectedRev = this.getProjectedAnnualRevenue
      return (projectedRev / 50000) * 100
    },
  },
}
</script>
<style scoped>
.message_tab {
  background: whitesmoke;
  border-color: whitesmoke;
  border-width: 3px;
  transition: all 0.2s ease-in-out;
}
.message_tab .card-header {
  background: lightgrey;
  transition: 0.3s;
}
/*.message_tab:hover .card-header{*/
/*  background: #19d412;*/
/*}*/
.message_tab:hover {
  background: white;
  cursor: pointer;
  transform: scale(1.1);
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}

img.rounded-corners {
  border-radius: 30px;
}

.information-card {
  background-color: white;
  border-radius: 4px;
  /*padding: 32px 24px;*/
  /*margin: 12px;*/
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;

  &:hover {
    border: 1px solid #363166;
    background-color: #f0eff7;
    /*box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);*/
    /*z-index: 500;*/
    transition: 0.3s linear;
  }
}

.actionFeature {
  background-color: #a39ed0;
}

.action-button {
  background-color: #363166;
}

.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
