<template>
  <div class="wrapper container" style="min-width: 100%">
    <ClientLayout :user="user"></ClientLayout>
    <section class="container">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
      >
        <div
          class="card-form"
          v-show="!this.messageBool && !this.textBool"
          style="min-width: 100%"
        >
          <div class="card-form__inner" style="text-align: center">
            <center>
              <img
                :src="getCampLogo"
                class="card-img-top rounded-corners"
                id="logoTop"
                :style="{
                  display: 'block',
                  maxWidth: !isMobile() ? '100vh' : '100%',
                  maxHeight: '35vh',
                  width: 'auto',
                  height: 'auto',
                }"
              />
            </center>
            <h2 class="card-title">
              <b>{{ this.camp.campName }}</b>
            </h2>
            <div class="row mt-3 justify-content-center">
              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-6': !this.camp.tShirtsProvided,
                  'col-md-5': this.camp.tShirtsProvided,
                  'col-sm-6': true,
                  'text-start': true,
                  'justify-content-center': true,
                  'camp-step-0': true,
                }"
              >
                <h3 class="text-center">Details</h3>
                <div class="m-2"><b>Type</b>: One-time payment</div>

                <div class="m-2">
                  <b>Location</b>: {{ this.camp.campLocation }}
                </div>

                <div class="m-2">
                  <b>Current Registrants</b>: {{ getRegistrants }}
                </div>

                <div v-show="!getParticipants" class="m-2">
                  <b
                    >Maximum Registrants:
                    {{ this.camp.campMaxParticipants }}
                  </b>
                </div>

                <div v-show="getParticipants" class="m-2">
                  <b>Unlimited Participants</b>
                </div>

                <div v-show="this.camp.requireWaiver" class="m-2">
                  <b>Requiring waiver</b>
                </div>

                <div v-show="!this.camp.requireWaiver" class="m-2">
                  <b>Not requiring waiver</b>
                </div>

                <div class="m-2">
                  <div>
                    <b>Registration Deadline </b>:
                    {{ getFormattedRegDate }}
                  </div>
                  <div v-show="this.camp.acceptLateRegistration">
                    <b>**Accepting Late Registrants</b>
                  </div>

                  <div
                    v-show="
                      !this.camp.acceptLateRegistration &&
                      this.camp.campRegistrationDeadline
                    "
                  >
                    <b>**Not Accepting Late Registrants</b>
                  </div>
                </div>

                <div class="m-2">
                  <b>Days</b>: {{ this.camp.campDates[0] ? '' : 'None' }}
                  <div
                    v-for="(day, index) in this.camp.campDates"
                    :key="index"
                    class="m-2"
                  >
                    <div>
                      <b>Day {{ index + 1 }}</b>
                      <div>
                        <div>Start Time: {{ dayToString(day.start) }}</div>
                        <div>End Time: {{ dayToString(day.end) }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="m-2">
                  <b>Description</b>: {{ this.camp.campDescription }}
                </div>
              </div>

              <div
                v-show="this.camp.tShirtsProvided"
                class="information-card col-md-2 col-sm-6 camp-step-1"
              >
                <div>
                  <h3>T-Shirt Sales</h3>
                  <div class="text-start">
                    <div>Youth Small: {{ getYS }}</div>
                    <div>Youth Medium: {{ getYM }}</div>
                    <div>Youth Large: {{ getYL }}</div>
                    <div>Adult Small: {{ getAS }}</div>
                    <div>Adult Medium: {{ getAM }}</div>
                    <div>Adult Large: {{ getAL }}</div>
                    <div>Adult XL: {{ getAXL }}</div>
                    <div>Adult XXL: {{ getAXXL }}</div>
                  </div>
                </div>
                <!--                <div v-show="this.camp.tShirtsProvided">-->
                <!--                  Shirts not provided-->
                <!--                </div>-->
              </div>

              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-5': !this.camp.tShirtsProvided,
                  'col-md-4': this.camp.tShirtsProvided,
                  'col-sm-6': true,
                  'text-center': true,
                  'camp-step-2': true,
                }"
              >
                <h3>Payment Details</h3>
                <div><b>Price</b>: ${{ getFormattedCampPrice }}</div>

                <div v-if="this.camp.discountCode">
                  <br />
                  <b>Discount Code:</b> {{ this.camp.discountCode }}<br />
                  <b>Discount Price:</b> ${{
                    this.camp.discountPrice.toFixed(2)
                  }}
                  <br />
                  <br />
                </div>

                <div v-if="this.camp.multipleCamperPrices.length > 0">
                  <br />
                  <b>Bundle Prices:</b>
                  <br />
                  <div
                    v-for="(price, index) in camp.multipleCamperPrices"
                    :key="index + price.camperPrice"
                  >
                    <b
                      >Additional Registrant{{
                        index === camp.multipleCamperPrices.length - 1
                          ? 's'
                          : ''
                      }}
                      {{ index + 1
                      }}{{
                        index === camp.multipleCamperPrices.length - 1
                          ? '+'
                          : ''
                      }}:</b
                    >
                    ${{ price.camperPrice.toFixed(2) }}
                    <br />
                  </div>
                </div>
                <br />

                <div><b>Current Earnings</b>: ${{ getFormattedMoney }}</div>

                <br />
                <b>Goal: ${{ getFormattedRevenueGoal }}</b>
                <div class="mt-3">
                  <VeProgress
                    :progress="getRevenueGoalProgress"
                    animation="rs 1500 700"
                    color="#19d412"
                    line-mode="in 10"
                    empty-color="#363166"
                  >
                    ${{ getFormattedMoney }} / ${{ getFormattedRevenueGoal }}
                    <br />
                    <br />
                    Keep Going!
                  </VeProgress>
                </div>
                <div class="mt-3" v-if="getFormattedAddOns.length != 0">
                  <b>Add On Purchases</b>
                  <div v-for="index in getFormattedAddOns.length" :key="index">
                    <ViewCampCustomAddOns
                      :addOnName="getFormattedAddOns[index - 1].addOnName"
                      :numCampers="getFormattedAddOns[index - 1].numCampers"
                    ></ViewCampCustomAddOns>
                  </div>
                </div>
              </div>
            </div>

            <div class="row center mt-3 justify-content-center">
              <div class="col-md-3 col-sm-6 camp-step-3">
                <h5 class="panel-title">Post On Landing Page</h5>

                <label class="switch">
                  <input
                    type="checkbox"
                    @click="toggleCampToWebsite"
                    :checked="this.camp.isPostedToWebsite"
                  />
                  <div class="slider round"></div>
                </label>
              </div>
              <div class="col-md-3 col-sm-6 camp-step-4">
                <h5 class="panel-title">Turn Link On</h5>

                <label class="switch">
                  <input
                    type="checkbox"
                    @click="toggleTurnCampOn"
                    :checked="this.camp.turnCampOn"
                  />
                  <div class="slider round"></div>
                </label>
              </div>
              <div class="col-md-3 col-sm-6 camp-step-5">
                <h5 class="panel-title">Receive Purchase Notifications</h5>

                <label class="switch">
                  <input
                    type="checkbox"
                    @click="toggleEmailNotification"
                    :checked="this.camp.sendEmailNotification"
                  />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
            <h3 class="card-title mt-5">Active Links</h3>
            <div class="row justify-content-center mt-3">
              <a
                class="card2 col-md-3 col-sm-6 camp-step-6"
                :href="getCampURL"
                target="_blank"
              >
                <h5>Payment Link</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>

              <a
                class="card2 col-md-3 col-sm-6 camp-step-7"
                :href="getQrLink"
                target="_blank"
              >
                <h5>Payment QR Code</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>
            </div>

            <h3 class="card-title mt-5 camp-step-8">Action Hub</h3>

            <div class="row justify-content-center mt-3">
              <div
                @click="handleClick()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-users fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Customer List</label
                    >
                  </span>
                </h5>
                <p>View and manage every customer</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>
              <div
                @click="messageBool = true"
                class="information-card col-md-3 col-sm-6 actionHub"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-comments fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Message All</label
                    >
                  </span>
                </h5>
                <p>Message every customer</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>

              <div
                class="information-card col-md-3 col-sm-6 actionHub"
                @click="customizeReceipt()"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-file-invoice fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Customize Receipt</label
                    >
                  </span>
                </h5>
                <p class>Personalize your email receipts</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>

              <CustomizeEmailReceipt
                :userID="this.user._id"
                :campStripeId="this.camp.campStripeId"
                :customEmailReceipt="this.camp.customEmailReceipt"
                :isMembership="false"
              ></CustomizeEmailReceipt>
              <button
                data-bs-toggle="modal"
                data-bs-target="#customizeEmailReceiptModal"
                id="customizeEmailReceiptTrigger"
                style="display: none"
              ></button>

              <div
                @click="copyCamp()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '200%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature mb-3">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-copy fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Duplicate Link</label
                    >
                  </span>
                </h5>
                <p>Don't waste time re-typing</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <button
                  class="card-form__button rounded-pill"
                  @click="editCamp"
                  style="
                    margin-top: 10px;
                    margin-bottom: 10px;
                    background-color: #363166;
                  "
                >
                  Edit Payment Link
                </button>
              </div>

              <div class="col-md-6 col-sm-6">
                <button
                  class="card-form__button rounded-pill"
                  @click="deleteCamp"
                  style="
                    margin-top: 10px;
                    margin-bottom: 10px;
                    background-color: red;
                  "
                >
                  Delete Payment Link
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--Start of Message Fuctionality-->
        <div class="container" v-show="messageBool || textBool">
          <div class="row">
            <div v-if="!isMobile()" class="col-2">
              <br /><br /><br /><br /><br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">
                    Email <i class="fa-regular fa-envelope"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >Pro</span
                    >
                  </h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-3"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text <i class="fa-solid fa-mobile-screen"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >All Star</span
                    >
                  </h5>
                </div>
              </div>
              <!--            </div>-->
            </div>
            <div v-else>
              <br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">
                    Email <i class="fa-regular fa-envelope"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >Pro</span
                    >
                  </h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-3"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text <i class="fa-solid fa-mobile-screen"></i>
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill actionFeature"
                      >All Star</span
                    >
                  </h5>
                </div>
              </div>
              <br />
            </div>
            <div class="col">
              <div class="card-form" v-show="this.messageBool">
                <div class="card-form__inner">
                  <img src="/SummerAthletes.png" class="card-img-top" />
                  <div class="card-form">
                    <div class="card-input">
                      <label for="email" class="card-input__label"
                        >Subject</label
                      >
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Subject"
                        v-model="message.subject"
                      />
                    </div>
                    <div class="card-input">
                      <label for="email" class="card-input__label">Intro</label>
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Hi Everyone,"
                        v-model="message.intro"
                      />
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 1</label>

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 1"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body1"
                      ></textarea>
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 2</label>
                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 2 (optional)"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body2"
                      ></textarea>
                    </div>
                  </div>

                  <button
                    class="card-form__button rounded-pill"
                    @click="sendEmail"
                  >
                    Send email to all
                  </button>
                  <button class="card-form__button rounded-pill">
                    <a @click="setTabStyle('back')">Back to event</a>
                  </button>
                </div>
              </div>
              <!-- START OF TEXT MESSAGE PORTION-->
              <div class="card-form" v-show="this.textBool">
                <div class="card-form__inner">
                  <img src="/SummerAthletes.png" class="card-img-top" />
                  <div class="card-form">
                    <div class="card-input">
                      <label class="card-input__label">Text Message</label>

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Friendly reminder..."
                        rows="4"
                        cols="50"
                        :style="{
                          height: '150px',
                        }"
                        v-model="text.content"
                      ></textarea>
                    </div>
                  </div>

                  <button
                    class="card-form__button rounded-pill"
                    @click="sendText"
                  >
                    Send text to all
                  </button>
                  <button class="card-form__button rounded-pill">
                    <a @click="setTabStyle('back')">Back to event</a>
                  </button>
                </div>
              </div>
              <!-- END OF TEXT MESSAGE PORTION-->
            </div>
          </div>
        </div>

        <!--End of Message Functionality-->
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import ViewCampCustomAddOns from '../components/ViewCampCustomAddOns.vue'
import CustomizeEmailReceipt from '../components/CustomizeEmailReceipt.vue'
import { VeProgress } from 'vue-ellipse-progress'
import swal from 'sweetalert'
import ClientLayout from '../components/ClientLayout.vue'
import numeral from 'numeral'
export default {
  components: {
    ClientLayout,
    ViewCampCustomAddOns,
    CustomizeEmailReceipt,
    VeProgress,
  },
  name: 'view-individual-camp',
  data() {
    return {
      json_fields: {
        'Camper First Name': 'firstName',
        'Camper Last Name': 'lastName',
        'Camper T-shirt size': 'tShirtSize',
        'Camper Emergency Contact First Name': {
          callback: value => {
            return value.emergencyContactInfo[0].firstName
          },
        },
        'Camper Emergency Contact Last Name': {
          callback: value => {
            return value.emergencyContactInfo[0].lastName
          },
        },
        'Camper Emergency Contact Email': {
          callback: value => {
            return value.emergencyContactInfo[0].email
          },
        },
        'Camper Emergency Contact Phone Number': {
          callback: value => {
            return value.emergencyContactInfo[0].phoneNumber
          },
        },
        'Camper Emergency Contact Address': {
          callback: value => {
            return value.emergencyContactInfo[0].address
          },
        },
        'Camper Emergency Contact City': {
          callback: value => {
            return value.emergencyContactInfo[0].city
          },
        },
        'Camper Emergency Contact State': {
          callback: value => {
            return value.emergencyContactInfo[0].state
          },
        },
        'Camper Emergency Contact Zip Code': {
          callback: value => {
            return value.emergencyContactInfo[0].zipCode
          },
        },
      },
      steps: [
        {
          target: '.camp-step-0', // We're using document.querySelector() under the hood
          content: `The <strong>Details</strong> section shows all details of the camp you created.`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-1', // We're using document.querySelector() under the hood
          content: `The <strong>T-Shirt Sales</strong> column specifies all T-Shirt sales for this specific camp. <br/> <br /> Each size has a value that shows how many people have purchased it.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-2', // We're using document.querySelector() under the hood
          content: `The <strong>Payment Details</strong> section gives an overview of all revenue information. <br/> <br /> It shows what the base price is, how much the camp has earned, and how close you are to your goals.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-3', // We're using document.querySelector() under the hood
          content: `The <strong>Post on Landing Page</strong> toggle publishes your website to your free landing page. <br/> <br /> This makes the event public, and shows up when someone navigates to your landing page via your AI Website`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-4', // We're using document.querySelector() under the hood
          content: `The <strong>Turn Link On</strong> toggle is what activates your payment link. <br/> <br /> Shutting this off will deactivate your payment link until you toggle it back on.`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-5', // We're using document.querySelector() under the hood
          content: `The <strong>Receive Purchase Notifications</strong> toggle tracks if you receive email alerts when someone pays via this link.`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
        {
          target: '.camp-step-6', // We're using document.querySelector() under the hood
          content: `The <strong>Link to Registration</strong> is how you get people signed up and <strong><em>make money</em></strong> on the platform! <br/> <br /> Be sure to post this out on social media as soon as possible, to help advertise for the event! We recommend at least <br/> <strong><em>one month in advance of advertising!</strong></em>`,
          params: {
            enableScrolling: false,
            placement: 'left',
            highlight: true,
          },
        },
        {
          target: '.camp-step-7', // We're using document.querySelector() under the hood
          content: `The <strong>QR Code</strong> takes your campers to the exact same page as the sign-up link! <br/> <br /> Please be sure to post this out wherever you can, it is most useful for both online and physical fliers!`,
          params: {
            enableScrolling: false,
            placement: 'right',
            highlight: true,
          },
        },
        {
          target: '.camp-step-8', // We're using document.querySelector() under the hood
          content: `The <strong>Action Hub</strong> is your camp command station. <br/> <br /> Manage your contacts, message your registrants, and work with your data, all in one spot!`,
          params: {
            enableScrolling: false,
            placement: 'top',
            // highlight: true,
          },
        },
      ],
      messageBool: false,
      textBool: false,
      message: {
        subject: '',
        intro: '',
        body1: '',
        body2: '',
      },
      text: {
        content: '',
      },
      campId: 0,
      user: {},
      temp: {},
      money: 0,
      camp: {
        campName: '',
        campDescription: '',
        campLocation: '',
        campLogo: '',
        campPrice: 0,
        discountPrice: 0,
        campOrganization: '',
        campDates: [],
        campRegistrationDeadline: '',
        campMaxParticipants: '',
        acceptLateRegistration: '',
        requireWaiver: '',
        tShirtsProvided: '',
        campStripeId: '',
        campers: [],
        colors: {
          primary: '',
          secondary: '',
        },
        multipleCamperPrices: [],
        customEmailReceipt: '',
      },
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    toggleCampToWebsite() {
      console.log('website toggle???')
      this.camp.isPostedToWebsite = !this.camp.isPostedToWebsite
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/toggleCampToWebsite'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          isPostedToWebsite: this.camp.isPostedToWebsite,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },
    toggleTurnCampOn() {
      this.camp.turnCampOn = !this.camp.turnCampOn
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/toggleTurnCampOn'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          turnCampOn: this.camp.turnCampOn,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    toggleEmailNotification() {
      this.camp.sendEmailNotification = !this.camp.sendEmailNotification
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/toggleEmailNotification'
      axios.post(
        url,
        {
          campStripeId: this.camp.campStripeId,
          sendEmailNotification: this.camp.sendEmailNotification,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    async sendEmail() {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least a Pro user to send mass messages. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'user/emailAll'
      axios
        .post(
          url,
          {
            subject: this.message.subject,
            intro: this.message.intro,
            body1: this.message.body1,
            body2: this.message.body2,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            campId: this.camp.campStripeId,
            type: 'camp',
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
      swal('Success', 'Message Sent!', 'success')
    },
    async sendText() {
      if (this.user.subscription.subscriptionTier < 3) {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: 'You need to be at least an All Star user to send mass texts. Please upgrade your plan to continue.',
        })
        document.getElementById('openAdvertisementPopUp').click()
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'user/textAll'
      axios
        .post(
          url,
          {
            content: this.text.content,
            phoneNumber: this.user.phoneNumber,
            campId: this.camp.campStripeId,
            coachID: this.user._id,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            this.camp.textLimit += 1
            swal('Success', 'Message Sent!', 'success')
          } else {
            swal('Sorry!', 'You have already reached your text limit.', 'error')
          }
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
          this.newMembership = this.savedData
        })
    },
    dayToString(date) {
      let temp = new Date(date)
      return temp.toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    handleClick() {
      this.$router.push('/Dashboard/CheckIn/' + this.campId)
    },

    customizeReceipt() {
      if (this.user.subscription.subscriptionTier >= 3) {
        //run customize receipt modal
        document.getElementById('customizeEmailReceiptTrigger').click()
      } else {
        swal({
          icon: 'info',
          title: 'Upgrade Your Plan',
          text: "You need to be at least an All Star user to customize your event's email receipts. Please upgrade your plan to continue.",
        })
        document.getElementById('openAdvertisementPopUp').click()
      }
    },

    copyCamp() {
      this.$router.push('/Dashboard/CreateCamp/Individual/' + this.campId)
    },
    showDownloadAd() {
      swal({
        icon: 'info',
        title: 'Upgrade Your Plan',
        text: 'You need to be at least a Pro user to download customer data locally. Please upgrade your plan to continue.',
      })
      document.getElementById('openAdvertisementPopUp').click()
    },

    async getCamps() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/camps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]._id == this.campId) {
              this.camp = response.data[i]
              this.camp.customEmailReceipt = response.data[i].customEmailReceipt
              if (response.data[i].campers.length < 3) {
                if (!this.camp.tShirtsProvided) {
                  this.steps.splice(1, 1)
                }
              }
            }
          }

          if (this.camp.isPostedToWebsite == null) {
            this.camp.isPostedToWebsite = false
          }
          if (this.camp.sendEmailNotification == null) {
            this.camp.sendEmailNotification = false
          }
          this.temp = response.data
        })
        .catch(function () {
          //report error as a swal message
          swal('Error', 'Could not find data', 'error')
        })
    },

    editCamp() {
      this.$router.push(
        '/Dashboard/CreateCamp/Individual/' + this.campId + '/edit'
      )
    },

    //delete camps
    async deleteCamp() {
      swal({
        title: 'Confirm Deletion',
        text: 'Once the payment link is deleted, all customer information is lost, and you are responsible for reaching out to the customers to refund and inform them of the cancellation.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let url =
            import.meta.env.VITE_APP_DB_URL + 'individualCamp/deleteCamp'
          axios
            .post(
              url,
              {
                campId: this.campId,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              swal('Success', 'Deleted', 'success')
              this.camps = response.data.camps
              this.$router.push('/Dashboard')
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
            })
        }
      })
    },
    showSubscriptionChoices() {
      this.$router.push(
        '/SubscriptionChoices/' + this.user.subscription.subscriptionType
      )
    },
    setTabStyle(type) {
      this.messageBool = type === 'email'
      this.textBool = type === 'text'
      var emailTabHeader = document.getElementById('emailTabHeader')
      var emailTabBody = document.getElementById('emailTabBody')
      var textTabHeader = document.getElementById('textTabHeader')
      var textTabBody = document.getElementById('textTabBody')
      if (type === 'email') {
        emailTabHeader.style.backgroundColor = '#19d412'
        emailTabBody.style.backgroundColor = 'white'
        textTabHeader.style.backgroundColor = 'lightgrey'
        textTabBody.style.backgroundColor = 'whitesmoke'
      } else if (type === 'text') {
        textTabHeader.style.backgroundColor = '#19d412'
        textTabBody.style.backgroundColor = 'white'
        emailTabHeader.style.backgroundColor = 'lightgrey'
        emailTabBody.style.backgroundColor = 'whitesmoke'
      } else {
        emailTabHeader.style.backgroundColor = '#19d412'
        emailTabBody.style.backgroundColor = 'white'
        textTabHeader.style.backgroundColor = 'lightgrey'
        textTabBody.style.backgroundColor = 'whitesmoke'
      }
    },
  },

  created() {
    this.getUserDetails()
    this.campId = this.$route.params.id
    this.getCamps()
  },
  computed: {
    determineSteps() {
      if (this.isMobile()) {
        let steps = this.steps
        for (let step of steps) {
          step.params.placement = 'bottom'
        }
        return steps
      }
      return this.steps
    },
    getFormattedCampPrice() {
      return numeral(this.camp.campPrice).format('0,0.00')
    },
    getFormattedMoney() {
      return numeral(this.getMoney).format('0,0.00')
    },
    getFormattedRevenueGoal() {
      return numeral(this.getRevenueGoal).format('0,0.00')
    },
    getCampLogo() {
      if (this.camp.campLogo != '') {
        return this.camp.campLogo
      }
      return 'https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png'
    },
    getFormattedAddOns() {
      let temp = []
      let count
      if (this.camp.customAddOns) {
        for (count = 0; count < this.camp.customAddOns.length; count++) {
          let numCampersRegistered = 0
          let x
          for (x = 0; x < this.camp.campers.length; x++) {
            if (this.camp.campers[x]?.customAddOnValues[count]?.isPurchased) {
              numCampersRegistered = numCampersRegistered + 1
            }
          }
          temp.push({
            addOnName: this.camp.customAddOns[count].addOnName,
            numCampers: numCampersRegistered,
          })
        }
      }

      return temp
    },
    getFormattedData() {
      let exportData = []
      for (let x = 0; x++; x < this.camp.campers.length) {
        exportData[x] = this.camp.campers[x]
      }
      return exportData
    },
    getCampURL() {
      if (this.camp.colors) {
        return (
          import.meta.env.VITE_APP_CAMP_URL +
          'campcheckout/' +
          this.camp.campStripeId +
          '/' +
          this.camp.colors.primary +
          '/' +
          this.camp.colors.secondary
        )
      }
      return (
        import.meta.env.VITE_APP_CAMP_URL +
        'campcheckout/' +
        this.camp.campStripeId
      )
    },
    getQrLink() {
      if (this.camp.colors) {
        return (
          'https://api.qrserver.com/v1/create-qr-code/?data=' +
          import.meta.env.VITE_APP_CAMP_URL +
          'campcheckout/' +
          this.camp.campStripeId +
          '/' +
          this.camp.colors.primary +
          '/' +
          this.camp.colors.secondary +
          '&amp;size=100x100'
        )
      }
      return (
        'https://api.qrserver.com/v1/create-qr-code/?data=' +
        import.meta.env.VITE_APP_CAMP_URL +
        'campcheckout/' +
        this.camp.campStripeId +
        '&amp;size=100x100'
      )
    },
    getCampDays() {
      return this.camp.campDates
    },
    getParticipants() {
      return this.camp.campMaxParticipants == null
    },
    getFormattedRegDate() {
      if (this.camp.campRegistrationDeadline) {
        let temp = new Date(this.camp.campRegistrationDeadline)
        let time = temp.toLocaleDateString()
        return time
      } else {
        return 'None'
      }
    },
    getFormattedCampDate() {
      let time = null
      try {
        let temp = new Date(this.camp.campDates[0].start)
        time = temp.toLocaleString()
      } catch (err) {
        return time
      }

      return time
    },

    getMoney() {
      let total = 0
      for (const camper of this.camp.campers) {
        total += camper.pricePaid
      }
      return total.toFixed(2)
    },
    getRegistrants() {
      return this.camp.campers.length
    },
    getYS() {
      let size = 'YS'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYM() {
      let size = 'YM'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getYL() {
      let size = 'YL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAS() {
      let size = 'AS'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAM() {
      let size = 'AM'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAL() {
      let size = 'AL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXL() {
      let size = 'AXL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getAXXL() {
      let size = 'AXXL'
      let shirts = 0
      for (let i = 0; i < this.camp.campers.length; i++) {
        if (this.camp.campers[i].tShirtSize == size) {
          shirts++
        }
      }
      return shirts
    },
    getRevenueGoal() {
      return this.camp.campRevenueGoal ? this.camp.campRevenueGoal : 10000
    },
    getRevenueGoalProgress() {
      const currentRevenue = this.getMoney
      const goal = this.camp.campRevenueGoal ? this.camp.campRevenueGoal : 10000
      const percentage = (currentRevenue / goal) * 100
      return percentage > 100 ? 100 : percentage
    },
  },
  mounted: function () {},
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.message_tab {
  background: whitesmoke;
  border-color: whitesmoke;
  border-width: 3px;
  transition: all 0.2s ease-in-out;
}
.message_tab .card-header {
  background: lightgrey;
  transition: 0.3s;
}
/*.message_tab:hover .card-header{*/
/*  background: #19d412;*/
/*}*/
.message_tab:hover {
  background: white;
  cursor: pointer;
  transform: scale(1.1);
}

.off_tab {
  background: whitesmoke;
}
.on_tab {
  background: #19d412;
}

img.rounded-corners {
  border-radius: 30px;
}

.actionFeature {
  background-color: #a39ed0;
}

.action-button {
  background-color: #363166;
}

.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}
</style>
