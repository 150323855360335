<template>
  <div class="wrapper" id="app">
    <MembershipInfo
      v-if="this.toggle == 0"
      :membership="this.membership"
      :membershipLogo="getMembershipLogo"
      :membershipWaiver="getMemberWaiver"
      :hidePlatformFees="this.membership.hidePlatformFees"
      :totalMembershipUpchargePercentage="
        this.totalMembershipUpchargePercentage
      "
      :chosenPaymentPeriodSelected="this.paymentPeriodSelected"
      :isMobile="this.isMobile"
      :primaryColor="this.primaryColor"
      :secondaryColor="this.secondaryColor"
      buttonName="Proceed to checkout"
      @proceed="toMemberInfo"
    ></MembershipInfo>
    <MemberInfo
      v-if="this.toggle == 1"
      :tshirtBool="this.membership.tShirtsProvided"
      :customFields="this.membership.customFields"
      :membershipLogo="getMembershipLogo"
      :accountInfo="this.accountInfo"
      :emergencyContactInfo="this.emergencyContactInfo"
      :fieldValueInputs="this.fieldValueInputs"
      :isMobile="this.isMobile"
      :primaryColor="this.primaryColor"
      :secondaryColor="this.secondaryColor"
      buttonName="Proceed to payment details"
      @back="back"
      @proceed="toPayment"
    ></MemberInfo>
    <Payment
      v-if="this.toggle == 2"
      :billingInfo="this.billingInfo"
      :isMobile="isMobile"
      :primaryColor="this.primaryColor"
      :secondaryColor="this.secondaryColor"
      @back="back"
      @proceed="toOrderSummary"
    ></Payment>
    <OrderSummary
      v-if="this.toggle == 3"
      :membershipLogo="getMembershipLogo"
      :membershipName="this.membership.membershipName"
      :membershipPayPeriod="this.membership.membershipPayPeriod"
      :paymentPeriodSelected="this.paymentPeriodSelected"
      :basePriceSelected="getBasePriceSelected"
      :hidePlatformFees="this.membership.hidePlatformFees"
      :totalMembershipUpchargePercentage="
        this.totalMembershipUpchargePercentage
      "
      :isMobile="this.isMobile"
      :primaryColor="this.primaryColor"
      :secondaryColor="this.secondaryColor"
      @back="back"
      @proceed="registerMember"
    ></OrderSummary>
  </div>
</template>

<script>
import Payment from '../../camps/components/CheckoutProcess/Payment.vue'
import OrderSummary from '../components/CheckoutProcess/OrderSummary.vue'
import swal from 'sweetalert'
import axios from 'axios'
import MembershipInfo from '../components/CheckoutProcess/MembershipInfo.vue'
import MemberInfo from '../components/CheckoutProcess/MemberInfo.vue'

export default {
  components: {
    MembershipInfo,
    MemberInfo,
    Payment,
    OrderSummary,
  },
  data() {
    return {
      paymentPeriodSelected: '',
      primaryColor: '#19D412',
      secondaryColor: '#363166',
      fieldValueInputs: [{}],
      accountInfo: {
        memberFirstName: '',
        memberLastName: '',
        tShirtSize: '',
      },
      emergencyContactInfo: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        email: '',
        phoneNumber: '',
        emergencyBool: true,
      },
      membershipID: '',
      toggle: 0,
      membership: {},
      customFieldValues: [],
      billingInfo: {
        cardFirstName: '',
        cardLastName: '',
        firstName: '',
        lastName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
      totalMembershipUpchargePercentage: 10,
    }
  },
  methods: {
    back() {
      this.toggle = this.toggle - 1
    },
    formatEmergencyContactInfo() {
      if (this.emergencyContactInfo.emergencyBool) {
        this.emergencyContactInfo.address = this.billingInfo.address
        this.emergencyContactInfo.city = this.billingInfo.city
        this.emergencyContactInfo.email = this.billingInfo.email
        this.emergencyContactInfo.firstName = this.billingInfo.firstName
        this.emergencyContactInfo.lastName = this.billingInfo.lastName
        this.emergencyContactInfo.phoneNumber = this.billingInfo.phoneNumber
        this.emergencyContactInfo.state = this.billingInfo.state
        this.emergencyContactInfo.zipCode = this.billingInfo.zipCode
      }
    },
    async getMembershipInfo() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMembershipByMembershipID?id=' +
        this.membershipID
      axios.get(url, {}).then(response => {
        this.membership = response.data.membership
      })
    },
    async registerMember() {
      let url = import.meta.env.VITE_APP_DB_URL + 'membership/registerMember'
      swal({
        title: 'Wait for confirmation',
        text: 'Please wait for confirmation',
        icon: 'warning',
        buttons: false,
      })
      this.formatEmergencyContactInfo()
      this.billingInfo.paymentPeriodSelected = this.paymentPeriodSelected
      this.accountInfo.membershipID = this.membershipID
      this.accountInfo.accountLink =
        import.meta.env.VITE_APP_CLIENT_URL + 'MemberLogin'
      await axios
        .post(url, {
          accountInfo: this.accountInfo,
          emergencyContactInfo: this.emergencyContactInfo,
          customFieldValues: this.customFieldValues,
          billingInfo: this.billingInfo,
        })
        .then(function () {
          swal('Success', 'Registration Successful', 'success')
          setTimeout(function () {
            window.location.href =
              import.meta.env.VITE_APP_MEMBERSHIP_URL + 'success'
          }, 1000)
          this.gif = false
        })
        .catch(function (err) {
          console.log('err')
          let error = err.response
          try {
            swal('Error', error.data.errorMessage, 'error')
          } catch (e) {
            console.log('post failure')
          }
        })
      this.gif = false
      //this.toggle = 1;
    },
    getTotalMembershipUpchargePercentage() {
      console.log('HIT!!!')
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getTotalMembershipUpchargePercentage?id=' +
        this.membershipID
      axios.get(url, {}).then(response => {
        this.totalMembershipUpchargePercentage =
          response.data.totalMembershipUpchargePercentage
        console.log(this.totalMembershipUpchargePercentage)
      })
    },
    toOrderSummary(x) {
      this.billingInfo = x
      this.toggle = 3
    },
    toPayment(x) {
      this.accountInfo = x.accountInfo
      this.emergencyContactInfo = x.emergencyContactInfo
      this.customFieldValues = x.customFieldValues
      console.log(x)
      this.toggle = 2
    },
    toMemberInfo(x) {
      console.log(x)
      this.paymentPeriodSelected = x.paymentPeriodSelected
      this.toggle = 1
    },
  },
  created() {
    this.membershipID = this.$route.params.id
    if (this.$route.params.primaryColor) {
      this.primaryColor = '#' + this.$route.params.primaryColor
    }
    if (this.$route.params.secondaryColor) {
      this.secondaryColor = '#' + this.$route.params.secondaryColor
    }
    this.getMembershipInfo()
    this.getTotalMembershipUpchargePercentage()
    //make axios api call to get camp data here
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getMemberWaiver() {
      let waiver =
        'Summer Athletes accepts no liability for any physical injury that occurs. Upon signup, the payment method will be charged automatically each payment cycle until cancellation. Upon signup, you will recive an email receipt with instructions on how to cancel.'
      if (this.membership.requireWaiver) {
        waiver =
          'Summer Athletes and the organizer accept no liability for any physical injury that occurs. Upon signup, the payment method will be charged automatically each payment cycle until cancellation. Upon signup, you will recive an email receipt with instructions on how to cancel.'
        if (this.membership.customWaiver != null) {
          if (this.membership.customWaiver)
            waiver =
              this.membership.customWaiver +
              '\n\nIn addition to the waiver above, Summer Athletes accepts no liability any physical injury that occurs. Upon signup, the payment method will be charged automatically each payment cycle until cancellation. Upon signup, you will recive an email receipt with instructions on how to cancel.'
        }
      }
      return waiver
    },
    getMembershipLogo() {
      if (
        this.membership.membershipLogo != '' &&
        this.membership.membershipLogo != null
      ) {
        return this.membership.membershipLogo
      }
      return 'https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png'
    },
    getBasePriceSelected() {
      if (this.paymentPeriodSelected === 'weeklyPrice') {
        return this.membership.membershipPayPeriod.weeklyPrice
      } else if (this.paymentPeriodSelected === 'monthlyPrice') {
        return this.membership.membershipPayPeriod.monthlyPrice
      } else if (this.paymentPeriodSelected === 'semiAnnualPrice') {
        return this.membership.membershipPayPeriod.semiAnnualPrice
      } else if (this.paymentPeriodSelected === 'annualPrice') {
        return this.membership.membershipPayPeriod.annualPrice
      } else {
        return 0
      }
    },
  },
}
</script>
<style scoped>
body {
  background: #ddeefc;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 35px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 15px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #19d412;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 3px 10px 20px 0 rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}
.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: 'Source Code Pro', monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}
.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}
.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  margin-bottom: 5px;
  color: #000000;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #000000;
  font-family: 'Source Sans Pro', sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #19d412;
}
.card-input__input:focus {
  box-shadow: 0 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}
.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) {
  .github-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
</style>
