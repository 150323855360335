<template>
  <div class="wrapper" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <div
      class="card-form"
      v-bind:style="{
        marginTop: '3%',
        maxWidth: isMobile ? '100%' : '75%',
        marginLeft: isMobile ? '8%' : '',
      }"
    >
      <div class="card-form__inner">
        <div class="row">
          <div class="col profile">
            <span>
              <img :src="getImage" class="image-profile" />
              <label for="image-input"
                ><i
                  class="fa-solid fa-plus fa-3x"
                  style="
                    color: #19d412;
                    position: relative;
                    top: 70px;
                    cursor: pointer;
                  "
                ></i
              ></label>
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event)"
                id="image-input"
                hidden
              />
            </span>
          </div>

          <center>
            <img
              src="/SummerAthletes.png"
              class="card-img-top"
              v-bind:style="{
                width: isMobile ? '75%' : '40%',
                marginTop: isMobile ? '10%' : '0%',
              }"
            />
          </center>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-2">
            <button
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#uploadCamperSearchModal"
            >
              + Import Existing Customer
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="firstName" class="card-input__label"
                >Athlete First Name</label
              >
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                placeholder="First Name"
                v-model="camper.firstName"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="lastName" class="card-input__label"
                >Athlete Last Name</label
              >
              <input
                type="text"
                id="lastName"
                class="card-input__input"
                placeholder="Last Name"
                v-model="camper.lastName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="firstName" class="card-input__label"
                >Emergency Contact First Name</label
              >
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                placeholder="First Name"
                v-model="camper.emergencyContactInfo[0].firstName"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="lastName" class="card-input__label"
                >Emergency Contact Last Name</label
              >
              <input
                type="text"
                id="lastName"
                class="card-input__input"
                placeholder="Last Name"
                v-model="camper.emergencyContactInfo[0].lastName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label class="card-input__label"
                >Emergency Contact Phone Number</label
              >
              <input
                type="text"
                id="phoneNumber"
                class="card-input__input"
                placeholder="Phone Number"
                v-model="camper.emergencyContactInfo[0].phoneNumber"
              />
            </div>
          </div>
          <div class="col">
            <div class="card-input">
              <label for="email" class="card-input__label"
                >Emergency Contact Email</label
              >
              <input
                type="email"
                id="email"
                class="card-input__input"
                placeholder="Email"
                v-model="camper.emergencyContactInfo[0].email"
              />
            </div>
          </div>
        </div>

        <div class="card-input">
          <label>Address</label>
          <input
            type="text"
            id="address"
            class="card-input__input"
            placeholder="Address"
            v-model="camper.emergencyContactInfo[0].address"
          />
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="city" class="card-input__label">City</label>
              <input
                type="text"
                id="city"
                placeholder="City"
                class="card-input__input"
                v-model="camper.emergencyContactInfo[0].city"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardMonth">State</label>
              <select
                required
                class="card-input__input"
                id="cardMonth"
                v-model="camper.emergencyContactInfo[0].state"
              >
                <option value="" disabled selected>State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardCvv">Zip Code</label>
              <input
                type="text"
                class="card-input__input"
                placeholder="Zip Code"
                v-model="camper.emergencyContactInfo[0].zipCode"
              />
            </div>
          </div>
        </div>

        <!--Start of tri-column info-->
        <div class="row">
          <!--Start of col 1-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getCampCustomFields.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getCampCustomFields[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 0
                "
                :field="getCampCustomFields[index - 1]"
                @change="formatData($event, value2)"
              ></CustomFieldDisplay>
            </li>
          </div>
          <!--Start of col 2-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getCampCustomFields.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getCampCustomFields[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 1
                "
                :field="getCampCustomFields[index - 1]"
                @change="formatData($event, value2)"
              ></CustomFieldDisplay>
            </li>
          </div>

          <!--Start of col 3-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getCampCustomFields.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getCampCustomFields[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 2
                "
                :field="getCampCustomFields[index - 1]"
                @change="formatData($event, index)"
              ></CustomFieldDisplay>
            </li>
          </div>
        </div>
        <!--Start of text long info-->
        <li
          style="list-style-type: none"
          v-for="index in getCampCustomFields.length"
          :key="index + getCampCustomFields.length"
        >
          <CustomFieldDisplay
            v-if="getCampCustomFields[index - 1].fieldType == 'textLong'"
            :field="getCampCustomFields[index - 1]"
          ></CustomFieldDisplay>
        </li>
        <!--Start of notes section-->
        <li
          style="list-style-type: none"
          v-for="index in this.numFields"
          :key="index"
        >
          <CamperNotes
            :note="camper.camperNotes[index - 1]"
            @changed="noteChange($event, index)"
          ></CamperNotes>
        </li>
        <div
          v-if="!this.camperRefunded"
          class="card-form m-3"
          style="min-width: 75%"
        >
          <!--          <h3 @click="toggleUploadedDocuments()">-->
          <!--            Uploaded Documents-->
          <!--            <i-->
          <!--              v-if="!this.showUploadedDocuments"-->
          <!--              class="fa-solid fa-caret-right"-->
          <!--            ></i>-->
          <!--            <i v-else class="fa-solid fa-caret-down"></i>-->
          <!--          </h3>-->
          <!--          <div v-if="this.showUploadedDocuments">-->
          <!--            <div-->
          <!--              style="list-style-type: none"-->
          <!--              v-for="index in this.camper.files.length"-->
          <!--              :key="index"-->
          <!--            >-->
          <!--              {{ index }}.-->
          <!--              <a-->
          <!--                href="#"-->
          <!--                @click="-->
          <!--                  getCamperFile(-->
          <!--                    camper.files[index - 1].S3Key,-->
          <!--                    camper.files[index - 1].fileName-->
          <!--                  )-->
          <!--                "-->
          <!--                :id="camper.files[index - 1].S3Key"-->
          <!--              >-->
          <!--                {{ camper.files[index - 1].fileName }}-->
          <!--              </a>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>

        <div v-if="!this.camperRefunded" class="row justify-content-center">
          <!--          <div class="col-md-3 col-sm-6">-->
          <!--            <label-->
          <!--              for="file-input"-->
          <!--              class="card-form__button rounded-pill"-->
          <!--              style="margin-top: 10px; background-color: #363166; color: white"-->
          <!--            >-->
          <!--              <center class="mt-2">&#8593; Upload Document &#8593;</center>-->
          <!--            </label>-->
          <!--            <div class="card-input">-->
          <!--              <input-->
          <!--                type="file"-->
          <!--                accept="*"-->
          <!--                @change="uploadCamperFile($event)"-->
          <!--                id="file-input"-->
          <!--                hidden-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              type="submit"
              style="margin-top: 10px; background-color: red; color: white"
              @click="backToCamperList"
            >
              Cancel Upload
            </button>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              @click="addNotes"
              style="margin-top: 10px; background-color: #363166; color: white"
            >
              Add notes section
            </button>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              @click="saveCamperInfo"
              style="margin-top: 10px"
            >
              Upload Customer
            </button>
          </div>
        </div>
      </div>
    </div>
    <UploadCamperSearchModal @selectedPerson="selectedPerson">
    </UploadCamperSearchModal>
  </div>
  <!--import swal from "sweetalert";
import axios from "axios";--->
</template>
<script>
import CustomFieldDisplay from '../../components/CustomFieldDisplay.vue'
import swal from 'sweetalert'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import CamperNotes from '../../components/Campers/CamperNotes.vue'
import UploadCamperSearchModal from '../../components/Campers/UploadCamperSearchModal.vue'
import ClientLayout from '../../components/ClientLayout.vue'

export default {
  components: {
    ClientLayout,
    CustomFieldDisplay,
    CamperNotes,
    UploadCamperSearchModal,
  },
  data() {
    return {
      user: {},
      camp: { customFields: [], files: [] },
      camper: {
        firstName: '',
        lastName: '',
        camperNotes: [],
        emergencyContactInfo: [
          {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
          },
        ],
        customFieldValues: [],
      },
      numFields: 0,
      test: '',
      uploadedFiles: [],
      selectedFile: null,
      showUploadedDocuments: false,
      camperRefunded: false,
    }
  },

  methods: {
    uploadImage(event) {
      let id = this.$route.params.id
      let profile = this.$route.params.profile
      //this.gif = true;
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/setProfileImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('type', 'IndividualCamp')
      data.append('eventId', id)
      data.append('profileId', profile)
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          console.log(response)
          this.camper.camperProfileImage = response.data.location
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },

    selectedPerson(person) {
      this.camper.firstName = person.firstName
      this.camper.lastName = person.lastName
      this.camper.emergencyContactInfo = person.emergencyContactInfo
      for (const field of person.customFieldValues) {
        if (
          this.camper.customFieldValues.some(
            campField =>
              campField.fieldName === field.fieldName &&
              campField.fieldType === field.fieldType
          )
        ) {
          //find index of field
          let index = this.camper.customFieldValues.findIndex(
            cf =>
              cf.fieldName === field.fieldName &&
              cf.fieldType === field.fieldType
          )
          if (field.fieldType === 'height') {
            this.camper.customFieldValues[index].fieldValue =
              field.fieldValue.split("'")[0]
            document.getElementById(field.fieldName).value =
              field.fieldValue.split("'")[0]
            this.camper.customFieldValues[index].value2 =
              field.fieldValue.split("'")[1]
            document.getElementById(field.fieldName + '_2').value =
              field.fieldValue.split("'")[1]
          } else {
            this.camper.customFieldValues[index].fieldValue = field.fieldValue
            document.getElementById(field.fieldName).value = field.fieldValue
          }
        }
      }
    },

    getCamp() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getCampById?id=' +
        this.$route.params.id
      axios.get(url).then(response => {
        this.camp = response.data
        // this.camper.customFieldValues = response.data.customFields;
        for (const field of response.data.customFields) {
          if (field.fieldType !== 'fileUpload') {
            this.camper.customFieldValues.push(field)
          }
        }
      })
    },

    formatData() {
      console.log('FORMATTING')
      for (let i = 0; i < this.camper.customFieldValues.length; i++) {
        console.log('test')
        if (this.camper.customFieldValues[i].value2) {
          this.camper.customFieldValues[i].fieldValue =
            this.camper.customFieldValues[i].fieldValue +
            "'" +
            this.camper.customFieldValues[i].value2
          delete this.camper.customFieldValues[i].value2
        }
      }
    },
    noteChange(x, y) {
      console.log(x)
      console.log(y)
      this.camper.camperNotes[y - 1] = x
    },
    saveCamperInfo() {
      swal({
        title: 'Confirm',
        text: 'Confirm save of camper information',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willSave => {
        if (willSave) {
          console.log('here')
          this.formatData()
          let url =
            import.meta.env.VITE_APP_DB_URL +
            'individualCamp/uploadCamperManually'
          axios
            .post(
              url,
              {
                id: this.user._id,
                camper: this.camper,
                campID: this.$route.params.id,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              this.$router.push(
                '/Dashboard/CheckIn/' +
                  this.$route.params.id +
                  '/' +
                  response.data.camper._id
              )
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
            })

          swal('Success', 'Camper Info Updated', 'success')
        }
      })
    },

    addNotes() {
      console.log('here')
      let temp = { title: '', notes: '' }
      this.camper.camperNotes.push(temp)
      this.numFields++
    },

    backToCamperList() {
      let id = this.$route.params.id
      this.$router.push('/Dashboard/CheckIn/' + id)
    },
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.user = decoded
    },
    uploadCamperFile(event) {
      this.gif = true
      const URL =
        import.meta.env.VITE_APP_DB_URL +
        'util/uploadCamperFile?userId=' +
        this.user._id +
        '&campId=' +
        this.$route.params.id +
        '&camperId=' +
        this.$route.params.profile
      let data = new FormData()
      data.append('name', 'camper-' + this.$route.params.profile)
      data.append('file', event.target.files[0])
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(() => {
          console.log(event.target.files[0])
          this.gif = false
          swal(
            'Success',
            `${event.target.files[0].name} uploaded successfully. You may need to refresh the page.`,
            'success'
          )
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },
    getCamperFile(S3Key, fileName) {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'util/getCamperFile?S3Key=' +
        S3Key +
        '&fileName=' +
        fileName
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
          responseType: 'blob',
        })
        .then(async response => {
          // console.log(response.data);
          // const downloadBlobURL = window.URL.createObjectURL(downloadBlob);
          const downloadBlobURL = window.URL.createObjectURL(
            new Blob([response.data])
          )

          console.log(downloadBlobURL)
          document.getElementById(S3Key).href = downloadBlobURL
          // this.selectedFile = response.data;

          const link = document.createElement('a')
          link.href = downloadBlobURL
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        })
        .catch(function (err) {
          console.log(err)

          swal('Error', err, 'error')
        })
    },
    toggleUploadedDocuments() {
      this.showUploadedDocuments = !this.showUploadedDocuments
    },
  },
  created() {
    this.getUserDetails()
    this.getCamp()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getImage() {
      if (!this.camper.camperProfileImage) {
        return '/headshot-icon.png'
      } else {
        return this.camper.camperProfileImage
      }
    },
    getCampCustomFields() {
      return this.camper.customFieldValues
    },
    getTextLongNum() {
      let temp = 0
      for (let x = 0; x < this.camp.customFields.length; x++) {
        if (this.camp.customFields[x].fieldType == 'textLong') temp = temp + 1
      }

      return temp
    },
  },
}
</script>
