<template>
  <div>
    <div
      class="card-form"
      v-bind:style="{
        width: isMobile() ? '' : '25rem',
        marginBottom: '15px',
      }"
    >
      <div
        class="card-form__inner"
        v-bind:style="{
          width: isMobile() ? '' : '25rem',
          textAlign: isMobile() ? 'center' : '',
        }"
      >
        <img :src="this.getLogo" class="card-img-top rounded-corners" />
        <div class="card-body mt-3">
          <h2 class="card-title">{{ this.getTitle }}</h2>
          <p class="card-text">
            {{ getDate }}
          </p>
          <button class="card-form__button rounded-pill" @click="showDetails">
            <a
              data-bs-toggle="modal"
              data-bs-target="#getStartedModal"
              target="_blank"
              style="cursor: pointer"
            >
              View Details
            </a>
          </button>
          <button
            class="card-form__button rounded-pill"
            style="color: white; background-color: #363166"
            @click="getInTouch()"
          >
            Get in Touch
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'

export default {
  components: {},
  props: {
    activity: Object,
  },
  data() {
    return {}
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    showDetails() {
      this.$emit('show-details', {
        activity: this.activity,
      })
    },

    async getInTouch() {
      let url = import.meta.env.VITE_APP_DB_URL + 'recruit/getCoachEmail?'
      if (this.activity.campStripeId) {
        url += 'campStripeId=' + this.activity.campStripeId
      } else {
        url += 'membershipID=' + this.activity._id
      }
      await axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          if (response.data.email) {
            this.$router.push(
              '/Recruit/Dashboard/Inbox?email=' +
                response.data.email +
                '&name=' +
                response.data.name
            )
          } else {
            swal({
              title: 'Error',
              text: 'Coach is not receiving emails at this time.',
              icon: 'error',
            })
          }
        })
        .catch(() => {
          swal({
            title: 'Error',
            text: 'Coach is not receiving emails at this time.',
            icon: 'error',
          })
        })
    },
  },
  computed: {
    getDate() {
      return !this.date ? '' : new Date(this.date).toLocaleDateString()
    },
    getLogo() {
      if (this.activity.campLogo) {
        return this.activity.campLogo
      }
      if (this.activity.membershipLogo) {
        return this.activity.membershipLogo
      }
      return require('../../../public/SummerAthletes.png')
    },
    getTitle() {
      if (this.activity.campName) {
        return this.activity.campName
      }
      if (this.activity.membershipName) {
        return this.activity.membershipName
      }
      return ''
    },
  },
  created() {},
}
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
img.rounded-corners {
  border-radius: 30px;
}
</style>
