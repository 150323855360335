<template>
  <div
    class="modal fade"
    id="partialRefundModal"
    tabindex="-1"
    aria-labelledby="partialRefundModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="partialRefundModalLabel">
            Provide Refund - Up To ${{ this.formattedPricePaid }}
          </h5>

          <button
            type="button"
            class="btn-close"
            id="partialRefundModalClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label for="refundAmount">Refund Amount:</label>
          <div class="row">
            <div class="col-md-9">
              <input
                type="number"
                class="form-control"
                id="refundAmount"
                v-model="refundAmount"
                required
              />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn"
                style="background-color: #363166; color: white"
                @click="refundAmount = pricePaid"
              >
                Set Max
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            id="partialRefundModalClose"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn"
            style="background-color: orange; color: white"
            @click="submitRefund"
          >
            Refund Customer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert'

export default {
  data() {
    return {
      refundAmount: 0,
    }
  },
  components: {},
  props: {
    pricePaid: Number,
    camperID: String,
    campID: String,
    userID: String,
  },
  methods: {
    async submitRefund() {
      if (this.refundAmount > this.pricePaid) {
        swal({
          title: 'Error',
          text: 'Refund amount exceeds the price paid.',
          icon: 'error',
        })
        return
      }
      swal({
        title: `Confirm Refund For $${this.refundAmount}`,
        text: `Are you sure you would like to refund this customer for $${this.refundAmount}? This cannot be undone.`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let url =
            import.meta.env.VITE_APP_DB_URL + 'individualCamp/partialRefund'
          axios
            .post(
              url,
              {
                coachID: this.userID,
                camperID: this.camperID,
                campID: this.campID,
                refundAmount: this.refundAmount,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              console.log(response)
              swal({
                title: 'Refund Initiated',
                text: 'Camper refund has been initiated.',
                icon: 'success',
              })
              document.getElementById('partialRefundModalClose').click()
              this.$emit('refunded', response.data.updatedPricePaid)
            })
            .catch(error => {
              console.log(error)
              swal({
                title: 'Refund Failed',
                text: error.response.data.errorMessage,
                icon: 'error',
              })
              document.getElementById('partialRefundModalClose').click()
            })
        }
      })
    },
  },
  computed: {
    formattedPricePaid() {
      return this.pricePaid?.toFixed(2)
    },
  },
}
</script>

<style scoped></style>
