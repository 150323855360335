<template>
  <div>
    <CoachNav :user="user.firstName"></CoachNav>

    <section style="margin-top: 7%">
      <div class="container mt-5">
        <a href="/Dashboard/Admin/Charts" class="btn btn-primary m-2">Charts</a>
        <a href="/Dashboard/Admin/MembershipPayouts" class="btn btn-primary m-2"
          >Memberships</a
        >
        <a href="/Dashboard/Admin/SMSMarketing" class="btn btn-primary m-2"
          >SMS Marketing</a
        >
        <a href="/Dashboard/Admin/RecruitReferral" class="btn btn-primary m-2"
          >Recruit Referral</a
        >
        <a
          class="btn m-2"
          style="background-color: #363166; color: white"
          @click="showUserAccountBalanceView = !showUserAccountBalanceView"
          >Toggle MRR Table</a
        >
        <div class="row mt-3">
          <div class="col">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Potential Float (Estimation)</b>
                  </h3>
                  <br />
                  <h1 class="text-center">~${{ getTotalFloat }}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Platform Liabilities (Exact)</b>
                  </h3>
                  <br />
                  <h1 class="card-text text-center">
                    ${{ getTotalAccountBalanceFloat }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Pending In Brex (Estimation)</b>
                  </h3>
                  <br />

                  <h1 class="card-text text-center">
                    ~${{ getTotalDaysPayoutsFloat }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-3">
          <div class="col-3">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Active Accounts </b>
                  </h3>
                  <br />
                  <h1 class="text-center">{{ activeSubscribers.length }}</h1>
                </div>
              </div>
            </div>
          </div>

          <div class="col-5">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b
                      >Month's Projected MRR
                      <i
                        class="fa-solid fa-circle-info"
                        style="color: #45487a; cursor: pointer"
                        @click="showMRRexplanation"
                      ></i
                    ></b>
                  </h3>
                  <br />
                  <h1 class="text-center">${{ getProjectedMonthMRR }}</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card-form center-flex">
              <div class="card-form__inner">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Current Account ARR </b>
                  </h3>
                  <br />
                  <h1 class="text-center">${{ getSubscriptionARR }}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="this.showUserAccountBalanceView">
          <div class="card" style="max-height: 1200px; overflow: auto">
            <table class="table table-hover" style="">
              <thead>
                <tr>
                  <th scope="col" style="position: sticky; top: 0px">
                    User ID
                  </th>
                  <th scope="col" style="position: sticky; top: 0px">Name</th>
                  <th scope="col" style="position: sticky; top: 0px">Email</th>
                  <th scope="col" style="position: sticky; top: 0px">
                    Account Balance
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in this.userList" v-bind:key="user.id">
                  <td>{{ user.userID }}</td>
                  <td>{{ user.userName }}</td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>${{ formatUserAccountBalance(user.accountBalance) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-else>
          <div class="card" style="max-height: 1200px; overflow: auto">
            <table class="table table-hover" style="">
              <thead>
                <tr>
                  <th scope="col" style="position: sticky; top: 0px">
                    Subscription ID
                  </th>
                  <th scope="col" style="position: sticky; top: 0px">Name</th>
                  <th scope="col" style="position: sticky; top: 0px">Email</th>
                  <th scope="col" style="position: sticky; top: 0px">
                    Currently Paying
                  </th>
                  <th scope="col" style="position: sticky; top: 0px">
                    Subscription Type
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in this.activeSubscribers" v-bind:key="user.id">
                  <td>{{ user.subscription.subscriptionID }}</td>
                  <td>{{ user.userName }}</td>
                  <td>
                    {{ user.email }}
                  </td>
                  <td>
                    ${{
                      formatUserAccountBalance(
                        user.subscription.currentlyPaying
                      )
                    }}
                    / {{ user.subscription.subscriptionPayPeriod }}
                  </td>
                  <td>{{ user.subscription.subscriptionName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import CoachNav from '../../components/CoachNav.vue'
import swal from 'sweetalert'
import numeral from 'numeral'

export default {
  components: { CoachNav },
  data() {
    return {
      user: {},
      totalCash: null,
      totalCampers: null,
      tshirts: {
        youthSmall: null,
        youthMedium: null,
        youthLarge: null,
        adultSmall: null,
        adultMedium: null,
        adultLarge: null,
        adultXLarge: null,
        adultXXLarge: null,
      },
      camps: [],
      memberships: [],
      campPaymentList: [],
      upcomingCampList: [],
      previousCampsList: [],
      campsThisSeason: [],
      allCampsTotalPayout: 0,
      totalFloat: 0,
      totalAccountBalanceFloat: 0,
      totalDaysPayouts: 0,
      userList: [],
      activeSubscribers: [],
      previousMonthEarnings: 0,
      showUserAccountBalanceView: true,
    }
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.user = decoded
    },
    async getPayoutData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'admin/getCoachesDueToday?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.campPaymentList = response.data
          // this.camps.sort((a, b) => (a.date > b.date ? 1 : -1));
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getUpcomingCampsData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'admin/getUpcomingCamps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.upcomingCampList = response.data
          // this.camps.sort((a, b) => (a.date > b.date ? 1 : -1));
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getLateCampsData() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'admin/getLateCamps?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.previousCampsList = response.data
          // this.camps.sort((a, b) => (a.date > b.date ? 1 : -1));
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getCampsThisSeason() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'admin/getCampsThisSeason?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.campsThisSeason = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getAllCampsTotalPayouts() {
      let url = import.meta.env.VITE_APP_DB_URL + 'admin/getAllPayoutsTotal'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.allCampsTotalPayout = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    calcTotalPayoutForList(list) {
      let total = 0
      if (list?.length > 0) {
        list.forEach(camp => (total += camp.amountDue))
      }
      return total
    },
    async getAllAccountBalances() {
      const url = import.meta.env.VITE_APP_DB_URL + 'util/getAllAccountBalances'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.totalFloat += response.data.totalFloat
          this.totalAccountBalanceFloat = response.data.totalFloat
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getTotalDaysPayouts() {
      const url = import.meta.env.VITE_APP_DB_URL + 'util/getTotalDaysPayouts'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.totalFloat += response.data.totalDaysPayouts
          this.totalDaysPayouts = response.data.totalDaysPayouts
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    getUserList() {
      let url = import.meta.env.VITE_APP_DB_URL + 'admin/getUserList'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.userList = response.data.userList
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    getActiveSubscribers() {
      let url = import.meta.env.VITE_APP_DB_URL + 'admin/getActiveSubscribers'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.activeSubscribers = response.data.activeSubscriberList
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    formatUserAccountBalance(accountBalance) {
      return numeral(accountBalance).format('0,0.00')
    },
    getPreviousMonthEarnings() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'admin/getPreviousMonthEarnings'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.previousMonthEarnings = response.data.previousMonthEarnings
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    showMRRexplanation() {
      swal({
        title: 'MRR Explanation',
        text: 'This is the total dollar amount we would make if ALL current active users (including those on free trial) did not cancel and paid successfully this month. This does NOT include annual subscribers. ',
        icon: 'info',
      })
    },
  },
  created() {
    this.getUserDetails()
    this.getAllAccountBalances()
    this.getTotalDaysPayouts()
    this.getUserList()
    this.getActiveSubscribers()
  },
  computed: {
    getNumCamps() {
      return this.camps.length
    },
    getTotalRegistrants() {
      let total = 0
      for (let i = 0; i < this.camps.length; i++) {
        total = total + this.camps[i].campers.length
      }
      return total
    },
    getTotalCamps() {
      return this.camps.length
    },
    getTotalFloat() {
      return numeral(this.totalFloat).format('0,0.00')
    },
    getTotalAccountBalanceFloat() {
      return numeral(this.totalAccountBalanceFloat).format('0,0.00')
    },
    getTotalDaysPayoutsFloat() {
      return numeral(this.totalDaysPayouts).format('0,0.00')
    },
    getPreviousMonthEarningsFormatted() {
      return numeral(this.previousMonthEarnings).format('0,0.00')
    },
    getProjectedMonthMRR() {
      let totalProjectedMRR = 0
      for (const subscriber of this.activeSubscribers) {
        if (subscriber.subscription.subscriptionPayPeriod === 'annually') {
          totalProjectedMRR += 0
        } else {
          totalProjectedMRR += subscriber.subscription.currentlyPaying
        }
      }
      return numeral(totalProjectedMRR).format('0,0.00')
    },
    getSubscriptionARR() {
      let totalARR = 0
      for (const subscriber of this.activeSubscribers) {
        if (subscriber.subscription.subscriptionPayPeriod === 'annually') {
          totalARR += subscriber.subscription.currentlyPaying
        } else {
          totalARR += 0
        }
      }
      return numeral(totalARR).format('0,0.00')
    },
  },
}
</script>
<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > .col {
  align-self: stretch;
  margin-bottom: 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 102px;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.admin-section {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid grey;
}
.row {
  align-items: flex-start;
}
.total-payout {
  position: absolute;
  font-size: 15px;
  color: red;
  width: auto !important;
}
</style>
