<template>
  <div class="wrapper container">
    <div class="card-form shadow-lg" style="margin-top: 0px">
      <div class="card-form__inner" style="margin-top: 0px">
        <center class="m-3">
          <img
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
            style="max-width: 400px; max-height: 300px"
          />
        </center>
        <h5 class="text-center">
          Get Access to Summer Athletes - Athlete Profile
        </h5>
        <p class="text-center fst-italic">Made for all athletes</p>
        <br />
        <div class="row">
          <div class="card-input col">
            <label for="firstName" class="card-input__label">First Name</label>
            <input
              type="text"
              id="firstName"
              class="card-input__input"
              v-model="firstName"
              data-ref="firstName"
              autocomplete="given-name"
            />
          </div>

          <div class="card-input col">
            <label for="cardName" class="card-input__label">Last Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="lastName"
              data-ref="cardName"
              autocomplete="family-name"
            />
          </div>
        </div>
        <div class="card-input">
          <label class="card-input__label">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            class="card-input__input"
            v-model="phoneNumber"
          />
        </div>
        <div class="card-input">
          <label class="card-input__label">Email </label>
          <input
            type="email"
            id="email"
            class="card-input__input"
            v-model="email"
          />
        </div>
        <div class="card-input">
          <label for="address" class="card-input__label"
            >Address
            <span
              v-if="!addressVerified"
              class="text-muted"
              style="font-size: 12px"
              >Unverified</span
            >
            <span v-else class="text-muted" style="font-size: 12px"
              ><i class="fa-solid fa-circle-check"></i> Verified</span
            >
          </label>
          <vue-google-autocomplete
            autocomplete="off"
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Address"
            class="card-input__input"
            v-on:placechanged="getAddressData"
            v-on:change="setAddressData"
            :country="['us']"
            v-model="address"
          >
          </vue-google-autocomplete>
        </div>
        <div>
          <div class="center text-center">
            <div class="ms-auto">
              <vue-friendly-captcha
                :sitekey="this.captchaSiteKey"
                id="captcha"
                ref="captcha"
                @done="setCaptchaData"
              />
            </div>
          </div>
          <button
            class="card-form__button rounded-pill"
            @click="validateCoachDetails"
            :disabled="checkCoachDetails"
            :style="{
              backgroundColor: checkCoachDetails ? 'lightgrey' : '',
              transition: checkCoachDetails ? 'none' : 'all 1s ease',
              cursor: checkCoachDetails ? 'not-allowed' : 'pointer',
            }"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VueFriendlyCaptcha from '@somushq/vue3-friendly-captcha'
import swal from 'sweetalert'
import swal2 from 'sweetalert2'
import axios from 'axios'

export default {
  components: {
    VueGoogleAutocomplete,
    VueFriendlyCaptcha,
  },
  props: {},
  data() {
    return {
      captchaSiteKey: '',
      captchaValue: '',
      addressVerified: false,
      zipCode: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      user: null,
    }
  },
  methods: {
    setAddressData(e) {
      if (!this.address) {
        this.address = e.toString()
      }
    },
    setCaptchaData() {
      this.captchaValue = document.getElementsByClassName(
        'frc-captcha-solution'
      )[0].value
    },
    validateCoachDetails() {
      try {
        swal2.fire({
          title: 'Preparing Your Account...',
          html: `<span class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></span> <span class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></span> <span class="spinner-grow" style="width: 3rem; height: 3rem;" role="status"></span>`,
          icon: 'info',
          showConfirmButton: false,
        })

        setTimeout(() => {
          this.registerWebTrafficContact()
        }, 500)
      } catch (err) {
        swal2.close()
        let error = err.response
        swal('Error', error.data.errorMessage, 'error')
        this.captchaValue = ''
        this.$refs.captcha.reset()
      }
    },

    async registerWebTrafficContact() {
      try {
        let captcha = document.getElementsByClassName('frc-captcha-solution')[0]

        let captchaValue = captcha.value
        let url = import.meta.env.VITE_APP_DB_URL + 'user/addWebTrafficContact'
        const webTrafficUser = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          address: this.address,
          captchaValue: captchaValue,
          recruit: true,
        }
        let response = await axios.post(url, webTrafficUser)
        if (response.data.success) {
          swal2.close()
          this.$emit('proceed', webTrafficUser)
        } else {
          window.location.href = `/RecruitExternalCheckout?firstName=${
            this.firstName
          }&lastName=${this.lastName}&phoneNumber=${this.phoneNumber}&address=${
            this.address
          }&errorMessage=${encodeURIComponent(error.data.errorMessage)}`
        }
      } catch (err) {
        swal2.close()
        let error = err.response
        window.location.href = `/RecruitExternalCheckout?firstName=${
          this.firstName
        }&lastName=${this.lastName}&phoneNumber=${this.phoneNumber}&address=${
          this.address
        }&errorMessage=${encodeURIComponent(error.data.errorMessage)}`
      }
    },

    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData)
      console.log(id)
      console.log(addressData)
      this.address =
        addressData.street_number +
        ' ' +
        addressData.route +
        ' ' +
        addressData.locality +
        ' ' +
        addressData.administrative_area_level_1 +
        ', ' +
        addressData.postal_code
      // this.billingInfo.city = addressData.locality;
      // this.billingInfo.state = addressData.administrative_area_level_1;
      // this.billingInfo.zipCode = addressData.postal_code;
      this.addressVerified = true
      this.zipCode = addressData.postal_code
    },
  },
  created() {
    this.captchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITEKEY
  },
  mounted() {
    if (this.$route.query.errorMessage) {
      swal('Error', this.$route.query.errorMessage, 'error')
    }
    this.firstName = this.$route.query.firstName || ''
    this.lastName = this.$route.query.lastName || ''
    this.phoneNumber = this.$route.query.phoneNumber || ''
    this.address = this.$route.query.address || ''
  },
  computed: {
    getPrice() {
      return numeral(this.price).format('0,0.00')
    },

    checkCoachDetails() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.phoneNumber ||
        !this.address ||
        this.captchaValue.length < 20
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.actionFeature {
  background-color: #a39ed0;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  z-index: 30;
}

.navigation .container {
  max-width: unset;
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.sa-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-logo {
  border-radius: 20%;
}

.sa-header .sa-logo-wrapper {
  width: 90px;
  display: flex;
}

.sa-nav-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 54px;
}

.sa-nav-wrapper .sa-menu {
  height: 100%;
  display: flex;
  gap: 40px;
}

.sa-menu .sa-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover,
.sa-menu .sa-menu-item.active {
  color: #363166;
}

.sa-menu .sa-menu-item::after {
  content: '';
  width: 100%;
  height: 3px;
  background-color: #363166;
  position: absolute;
  bottom: 0;
  left: -100%;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover::after,
.sa-menu .sa-menu-item.active::after {
  left: 0;
}

.sa-menu-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-image {
  width: 100%;
  height: 100%;
  display: block;
}

.sa-image-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sa-image-cover.fit-pos-top {
  object-position: top;
}

.sa-image-contained {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
</style>
