<template>
  <div class="wrapper">
    <div class="container mt-5">
      <div
        :class="{
          'card-form': true,
          container: true,
          'd-flex': true,
          'justify-content-center': true,
          'mx-4': true,
        }"
        style="min-width: 100%"
      >
        <div :class="{ 'card-form__inner': true }" style="min-width: 100%">
          <a href="/Dashboard/Admin/Payouts" class="btn btn-primary mb-5"
            >Payouts</a
          >
          <br />
          <div class="mb-3">
            <label for="formFile" class="form-label"
              >Upload Phone Numbers</label
            >
            <input
              class="form-control"
              type="file"
              id="input"
              @change="uploadPhoneNumbers($event)"
            />
          </div>
          <label class="form-label mt-3">Marketing Message</label>
          <textarea
            class="form-control mb-3"
            rows="4"
            placeholder="e.g. The Junior High Basketball Camp will teach your child...."
            v-model="content"
            style="min-height: 200px"
            required
          ></textarea>
          <button @click="sendSMS" class="btn btn-primary mb-5">
            Submit Marketing Message
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import readXlsxFile from "read-excel-file";
import * as XLSX from 'xlsx/xlsx.mjs'
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import swal from 'sweetalert'
export default {
  components: {},
  data() {
    return {
      user: {},
      content: '',
      phoneNumbers: [],
    }
  },
  methods: {
    uploadPhoneNumbers(event) {
      // const URL = import.meta.env.VITE_APP_DB_URL + "util/uploadFile";
      // let data = new FormData();
      // data.append("name", "phone_numbers.csv");
      // data.append("file", event.target.files[0]);
      // console.log(event.target.files[0]);
      let file = event.target.files[0]
      let phoneNumbers = []
      var reader = new FileReader()

      reader.onload = function (e) {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary',
        })
        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          )
          var json_object = JSON.stringify(XL_row_object)
          let json = JSON.parse(json_object)
          for (const row of json) {
            let phoneNumber = row['Phone Number']
            phoneNumbers.push(phoneNumber)
            console.log(phoneNumber)
          }
        })
      }

      reader.onerror = function (ex) {
        console.log(ex)
      }

      reader.readAsBinaryString(file)
      this.phoneNumbers = phoneNumbers
    },
    sendSMS() {
      let url = import.meta.env.VITE_APP_DB_URL + 'admin/SMSMarketing'

      axios
        .post(
          url,
          { phoneNumbers: this.phoneNumbers, content: this.content },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response.data.success) {
            swal('Success', 'Messages Sent Successfully!', 'success')
          }
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
          console.log(err.response.data.errorMessage)
          console.log(err.response.data.errorMessage.message)

          swal(
            'Error',
            'Failed! Please check the console for more info',
            'error'
          )
        })
    },
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.user = decoded
    },
    async getAllMembershipInfo() {
      let url = import.meta.env.VITE_APP_DB_URL + 'admin/getAllMembershipInfo'
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.memberships = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    changeStartDateRange(event) {
      this.startDateRange = event.target.value
    },
    changeEndDateRange(event) {
      this.endDateRange = event.target.value
    },
  },
  created() {
    this.getUserDetails()
  },
}
</script>
<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > .col {
  align-self: stretch;
  margin-bottom: 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 102px;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.admin-section {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid grey;
}
.row {
  align-items: flex-start;
}
.total-payout {
  position: absolute;
  font-size: 15px;
  color: red;
  width: auto !important;
}
</style>
