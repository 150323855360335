<template>
  <div class="wrapper" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <div
      class="card-form"
      v-bind:style="{
        marginTop: '3%',
        maxWidth: isMobile ? '100%' : '75%',
        marginLeft: isMobile ? '8%' : '',
      }"
    >
      <div class="card-form__inner">
        <div class="row">
          <div class="col-md-3">
            <div class="row justify-content-between">
              <div class="col"><h1 class="text-center">Inbox</h1></div>
              <div class="col mt-2">
                <button
                  class="btn"
                  style="color: white; background-color: #363166"
                  @click="compose = true"
                >
                  Compose
                </button>
              </div>
              <hr />
            </div>
            <div style="max-height: 700px; overflow-y: scroll">
              <div
                v-for="(message, messageIndex) in this.messages"
                v-bind:key="message._id"
                @click="handleMessageClick(messageIndex)"
              >
                <div
                  :class="{
                    card: true,
                    'm-3': true,
                    messageHover: true,
                    selectedMessage: messageIndex === currentMessageIndex,
                    hasBeenRead:
                      message.hasBeenRead &&
                      messageIndex !== currentMessageIndex,
                    hasBeenReadAndViewing:
                      message.hasBeenRead &&
                      messageIndex === currentMessageIndex,
                  }"
                >
                  <h5 class="card-title m-3">
                    <div class="row justify-content-between">
                      <div class="col">{{ message.sentFromName }}</div>
                      <div class="col">
                        {{ displayDate(message.sentOn) }}
                      </div>
                    </div>
                  </h5>
                  <p class="card-body m-3">
                    {{
                      message.subject
                        ? message.subject
                        : message.messageText.slice(0, 33)
                    }}...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card-form__inner" v-if="!compose">
              <div class="card-title">
                <h3 class="card-title mb-3">
                  <div class="row justify-content-between">
                    <div class="col">
                      {{ this.currentMessage.subject }}
                    </div>
                    <div class="col text-end">
                      <i
                        class="fa-solid fa-reply"
                        style="cursor: pointer"
                        @click="typeReply = true"
                      ></i>
                    </div>
                  </div>
                </h3>
                <h5>From: {{ this.currentMessage.sentFromName }}</h5>
              </div>
              <div class="card-body" style="min-height: 100px">
                <!--                {{ this.currentMessage.messageText }}-->
                <VueEditor
                  class="center"
                  v-model="this.currentMessage.messageText"
                  disabled="true"
                  editable="false"
                  contenteditable="false"
                  :editorToolbar="customToolbar"
                ></VueEditor>
              </div>
              <div class="card-footer mt-3" v-if="typeReply">
                <hr />
                <VueEditor
                  class="center"
                  v-model="replyMessageText"
                ></VueEditor>
                <br />
                <button
                  class="btn"
                  style="color: white; background-color: #363166"
                  @click="sendReply()"
                >
                  <span
                    >Send
                    <i
                      class="fa-regular fa-paper-plane fa-lg"
                      style="margin-left: 3px"
                    ></i
                  ></span>
                </button>
              </div>
            </div>
            <div class="card-form__inner" v-else>
              <input
                type="text"
                id="email"
                class="form-control mb-3"
                style="max-width: 300px"
                placeholder="To"
                v-model="sendToEmail"
              />
              <input
                type="text"
                id="subject"
                class="form-control mb-3"
                placeholder="Subject"
                v-model="messageSubject"
              />
              <VueEditor class="center" v-model="newMessageText"></VueEditor>
              <br />
              <button
                class="btn"
                style="color: white; background-color: #363166"
                @click="sendNewMessage()"
              >
                <span
                  >Send
                  <i
                    class="fa-regular fa-paper-plane fa-lg"
                    style="margin-left: 3px"
                  ></i
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
// import axios from "axios";
// import VueJwtDecode from "vue-jwt-decode";
import axios from 'axios'
import { VueEditor } from 'vue3-editor'
import ClientLayout from '../../components/ClientLayout.vue'

export default {
  components: {
    ClientLayout,
    VueEditor,
  },
  data() {
    return {
      user: {},
      currentMessageIndex: 0,
      currentMessage: {
        subject: '',
        sentFromName: '',
        sentOn: '',
        messageText: '',
        hasBeenRead: false,
        _id: '',
      },
      messages: [],
      replyMessageText: '',
      typeReply: false,
      compose: false,
      sendToEmail: '',
      messageSubject: '',
      newMessageText: '',
      customToolbar: [''],
    }
  },

  methods: {
    getUserDetails() {
      //TODO fix white screen when no emails are present
      this.user = JSON.parse(localStorage.getItem('user'))
      let url =
        import.meta.env.VITE_APP_DB_URL + 'user/getDetails?id=' + this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.messages = response.data.user.messages.reverse()
          this.currentMessage = this.messages[0]
        })
    },

    async handleMessageClick(messageIndex) {
      this.typeReply = false
      this.compose = false
      this.currentMessageIndex = messageIndex
      this.currentMessage = this.messages[messageIndex]
      this.messages[messageIndex].hasBeenRead = true
      let url = import.meta.env.VITE_APP_DB_URL + 'user/markMessageAsRead'
      await axios.post(
        url,
        {
          userID: this.user._id,
          messageID: this.messages[messageIndex]._id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        }
      )
    },

    displayDate(sentOn) {
      let date = new Date(sentOn).toLocaleString()
      let dateArray = date.split(',')
      let officialDate = dateArray[0]
      let today = new Date().toLocaleString().split(',')[0]

      let timeArray =
        dateArray[1].split(':')[0] + ':' + dateArray[1].split(':')[1]
      let dayMark = date.split(' ')[2]

      if (today === officialDate) {
        return timeArray + ' ' + dayMark
      } else {
        return officialDate
      }
    },

    sendNewMessage() {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/sendNewMessage'
      axios
        .post(
          url,
          {
            userID: this.user._id,
            messageText: this.newMessageText,
            sendToEmail: this.sendToEmail,
            subject: this.messageSubject,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response) {
            swal({
              title: 'Message Sent',
              text: 'Your message has been sent',
              icon: 'success',
            })
            this.newMessageText = ''
            this.sendToEmail = ''
            this.messageSubject = ''
            this.compose = false
          }
        })
        .catch(error => {
          console.log(error)
          swal({
            title: 'Error',
            text: error.response.data.errorMessage,
            icon: 'error',
          })
        })
    },

    sendReply() {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/sendReply'
      axios
        .post(
          url,
          {
            userID: this.user._id,
            replyMessageText: this.replyMessageText,
            originalMessage: this.messages[this.currentMessageIndex],
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          if (response) {
            swal({
              title: 'Message Sent',
              text: 'Your message has been sent',
              icon: 'success',
            })
            this.replyMessageText = ''
            this.typeReply = false
          }
        })
        .catch(error => {
          console.log(error)
          swal({
            title: 'Error',
            text: 'Could not find account with that email',
            icon: 'error',
          })
        })
    },
  },
  created() {
    this.getUserDetails()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
  },
}
</script>
<style scoped>
.image-profile {
  display: block;
  max-width: 100%;
  height: auto;
}

.image-label {
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  right: -10px; /* Adjust this value as needed */
  font-size: 2rem; /* Adjust this value to make the plus button smaller */
}

.messageHover:hover {
  cursor: pointer;
  border: 3px solid #363166;
  transform: scale(1.03);
  transition: 0.1s ease-in;
}

.selectedMessage {
  cursor: pointer;
  border: 3px solid #363166;
}

.hasBeenRead {
  background-color: lightgrey;
}

.hasBeenReadAndViewing {
  background-color: #e5e4e2;
}
</style>
