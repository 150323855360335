<template>
  <div>
    <div class="card-input" v-for="account in this.accounts" :key="account._id">
      <AccountCard
        :recruitFirstName="account.firstName"
        :recruitLastName="account.lastName"
      ></AccountCard>
      <AccountCard></AccountCard>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import AccountCard from '../components/AccountCard.vue'
export default {
  components: {
    AccountCard,
  },
  props: {
    accountInputs: Array,
  },
  data() {
    return {
      accounts: [],
    }
  },
  methods: {
    // openGetStartedModal() {
    //   let modalElement = this.$refs.blankModal.$el.querySelector("#blankModal");
    //   let modal = new Modal(modalElement);
    //   modal.show();
    // },
    async loginUser() {
      this.loggingIn = true
      let url = import.meta.env.VITE_APP_DB_URL + 'user/login'
      if (this.validateInfo() == 0) {
        axios
          .post(url, this.login, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          })
          .then(response => {
            console.log(response.data)
            let user = response.data.recruit
            localStorage.setItem('jwt', response.data.token)
            localStorage.setItem('user', JSON.stringify(user))
            if (response.data.recruitLogin) {
              let temp = 'Login Successful! '
              if (
                !(user.recruitProfile.firstName && user.recruitProfile.lastName)
              ) {
                temp = temp + 'Start by filling out your recruit profile. '
              }

              swal({
                title: 'Success',
                text: temp,
                icon: 'success',
              })
              console.log('here')
              if (
                user.recruitProfile.firstName &&
                user.recruitProfile.lastName
              ) {
                if (user.aiWebsites.length >= 1 || !user.accountActive) {
                  this.$router.push('/recruit/Dashboard/')
                } else {
                  this.$router.push('/recruit/Dashboard/Website')
                }
              } else {
                this.$router.push('/recruit/Dashboard/Profile')
              }
            } else {
              let token = response.data.token
              let tempUser = response.data.user
              let user = {
                _id: tempUser._id,
                email: tempUser.email,
                firstName: tempUser.firstName,
                lastName: tempUser.lastName,
                subscription: tempUser.subscription,
                phoneNumber: tempUser.phoneNumber,
                address: tempUser.address,
                city: tempUser.city,
                state: tempUser.state,
                zipCode: tempUser.zipCode,
                accountActive: tempUser.accountActive,
              }
              localStorage.setItem('jwt', token)
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('advertisementShown', 'false')
              if (token) {
                swal('Success', 'Login Successful', 'success')
                if (!user.accountActive) {
                  this.$router.push(
                    '/SubscriptionChoices/Checkout/' +
                      user.subscription.subscriptionMongoID +
                      '/false' +
                      '?lock=true'
                  )
                } else {
                  this.$router.push('/Dashboard')
                }
              }
            }
          })
          .catch(function (err) {
            // console.log(err.response.data.errorMessage.message);
            let error = err.response
            swal('Error', error.data.errorMessage, 'error').then(function () {
              console.log(this)
            })
          })
      }
      setTimeout(() => {
        this.loggingIn = false
      }, 2500)
    },

    validateInfo() {
      if (this.login.email == '') {
        swal('Error', 'Please enter email', 'error')
        return 1
      }
      if (this.login.password == '') {
        swal('Error', 'Please enter password', 'error')
        return 1
      }
      return 0
    },

    loginWithGoogle() {
      axios
        .post(
          import.meta.env.VITE_APP_DB_URL + 'user/googleAuthentication',
          { redirectURL: import.meta.env.VITE_APP_CLIENT_URL + '?auth=google' },
          {}
        )
        .then(response => {
          if (response.data.googleAuthURL) {
            window.location = response.data.googleAuthURL
          } else {
            swal(
              'Error',
              'Your account cannot be integrated with Google at this point in time',
              'error'
            )
          }
        })
        .catch(function (err) {
          console.log(err.response.data.errorMessage.message)
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    loginWithFacebook() {
      window.location =
        'https://www.facebook.com/v4.0/dialog/oauth?client_id=' +
        import.meta.env.VITE_APP_FACEBOOK_CLIENT_ID +
        '&redirect_uri=' +
        import.meta.env.VITE_APP_CLIENT_URL +
        '?auth=facebook&scope=email&response_type=code&auth_type=rerequest&display=popup'
    },
  },
  created() {
    if (this.$route.query.code) {
      if (this.$route.query.auth === 'google') {
        this.authLoading = true
        let token = this.$route.query.code
        axios
          .post(
            import.meta.env.VITE_APP_DB_URL + 'user/googleAuthVerification',
            {
              token: token,
              redirectURL: import.meta.env.VITE_APP_CLIENT_URL + '?auth=google',
            },
            {}
          )
          .then(response => {
            let user = response.data.recruit
            console.log(user)
            localStorage.setItem('jwt', response.data.token)
            localStorage.setItem('user', JSON.stringify(user))
            if (response.data.recruitLogin) {
              let temp = 'Login Successful! '
              if (
                !(user.recruitProfile.firstName && user.recruitProfile.lastName)
              ) {
                temp = temp + 'Start by filling out your recruit profile. '
              }

              swal({
                title: 'Success',
                text: temp,
                icon: 'success',
              })
              console.log('here')
              if (
                user.recruitProfile.firstName &&
                user.recruitProfile.lastName
              ) {
                if (user.aiWebsites.length >= 1 || !user.accountActive) {
                  this.$router.push('/recruit/Dashboard/')
                } else {
                  this.$router.push('/recruit/Dashboard/Website')
                }
              } else {
                this.$router.push('/recruit/Dashboard/Profile')
              }
            } else {
              let token = response.data.token
              let tempUser = response.data.user
              let user = {
                _id: tempUser._id,
                email: tempUser.email,
                firstName: tempUser.firstName,
                lastName: tempUser.lastName,
                subscription: tempUser.subscription,
                phoneNumber: tempUser.phoneNumber,
                address: tempUser.address,
                city: tempUser.city,
                state: tempUser.state,
                zipCode: tempUser.zipCode,
                accountActive: tempUser.accountActive,
              }
              localStorage.setItem('jwt', token)
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('advertisementShown', 'false')
              if (token) {
                swal('Success', 'Login Successful', 'success')
                if (!user.accountActive) {
                  this.$router.push(
                    '/SubscriptionChoices/Checkout/' +
                      user.subscription.subscriptionMongoID +
                      '/false' +
                      '?lock=true'
                  )
                } else {
                  this.$router.push('/Dashboard')
                }
              }
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message)
            let error = err.response
            swal({
              title: 'Error',
              text: error.data.errorMessage,
              icon: 'error',
              buttons: ['Cancel', 'Try Again'],
              dangerMode: true,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location = import.meta.env.VITE_APP_CLIENT_URL
              } else {
                window.location = import.meta.env.VITE_APP_CLIENT_URL
              }
            })
          })
      } else if (this.$route.query.auth === 'facebook') {
        const { data } = axios({
          url: 'https://graph.facebook.com/v4.0/oauth/access_token',
          method: 'get',
          params: {
            client_id: import.meta.env.VITE_APP_FACEBOOK_CLIENT_ID,
            client_secret: import.meta.env.VITE_APP_FACEBOOK_CLIENT_SECRET,
            redirect_uri:
              import.meta.env.VITE_APP_CLIENT_URL + '?auth=facebook',
            code: this.$route.query.code,
          },
        }).then(response => {
          axios({
            url:
              import.meta.env.VITE_APP_CLIENT_URL +
              'user/facebookAuthVerification',
            method: 'get',
            params: {
              accessToken: response.data.access_token,
            },
          })
            .then(response => {
              console.log(response.data)
              let user = response.data.recruit
              localStorage.setItem('jwt', response.data.token)
              localStorage.setItem('user', JSON.stringify(user))
              if (response.data.recruitLogin) {
                let temp = 'Login Successful! '
                if (
                  !(
                    user.recruitProfile.firstName &&
                    user.recruitProfile.lastName
                  )
                ) {
                  temp = temp + 'Start by filling out your recruit profile. '
                }

                swal({
                  title: 'Success',
                  text: temp,
                  icon: 'success',
                })
                console.log('here')
                if (
                  user.recruitProfile.firstName &&
                  user.recruitProfile.lastName
                ) {
                  if (user.aiWebsites.length >= 1 || !user.accountActive) {
                    this.$router.push('/recruit/Dashboard/')
                  } else {
                    this.$router.push('/recruit/Dashboard/Website')
                  }
                } else {
                  this.$router.push('/recruit/Dashboard/Profile')
                }
              } else {
                let token = response.data.token
                let tempUser = response.data.user
                let user = {
                  _id: tempUser._id,
                  email: tempUser.email,
                  firstName: tempUser.firstName,
                  lastName: tempUser.lastName,
                  subscription: tempUser.subscription,
                  phoneNumber: tempUser.phoneNumber,
                  address: tempUser.address,
                  city: tempUser.city,
                  state: tempUser.state,
                  zipCode: tempUser.zipCode,
                  accountActive: tempUser.accountActive,
                }
                localStorage.setItem('jwt', token)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('advertisementShown', 'false')
                if (token) {
                  swal('Success', 'Login Successful', 'success')
                  if (!user.accountActive) {
                    this.$router.push(
                      '/SubscriptionChoices/Checkout/' +
                        user.subscription.subscriptionMongoID +
                        '/false' +
                        '?lock=true'
                    )
                  } else {
                    this.$router.push('/Dashboard')
                  }
                }
              }
            })
            .catch(function (err) {
              console.log(err.response.data.errorMessage.message)
              let error = err.response
              swal({
                title: 'Error',
                text: error.data.errorMessage,
                icon: 'error',
                buttons: ['Cancel', 'Try Again'],
                dangerMode: true,
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location = import.meta.env.VITE_APP_CLIENT_URL
                } else {
                  window.location = import.meta.env.VITE_APP_CLIENT_URL
                }
              })
            })
          console.log(data) // { access_token, token_type, expires_in }
        })
      }
    }
  },
  computed: {
    getRegisterURL() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'register'
    },
    getForgotPasswordURL() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'forgotpassword'
    },
    getSubscriptionRookieCheckoutLink() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'externalcheckoutsecondary'
    },
    hasMultipleAccounts() {
      return this.multipleAccounts
    },
  },
  mounted() {
    var passwordInput = document.getElementById('password')
    var emailInput = document.getElementById('email')

    // Execute a function when the user presses a key on the keyboard
    passwordInput.addEventListener('keypress', function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === 'Enter') {
        // Trigger the button element with a click
        document.getElementById('regularSignInBtn').click()
      }
    })

    emailInput.addEventListener('keypress', function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === 'Enter') {
        // Trigger the button element with a click
        document.getElementById('regularSignInBtn').click()
      }
    })
  },
}
</script>
<style>
.card-form {
  /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom,*/
  /*from(rgba(0,0,1,1)), to(rgba(0,0,0,.9)));*/
  /*box-shadow: 0 30px 40px rgba(0,0,0,.9);*/
}

.gradient-button {
  background: rgb(54, 49, 102);
  background: linear-gradient(
    90deg,
    rgba(54, 49, 102, 1) 8%,
    rgba(25, 212, 18, 1) 78%
  );
}
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 20px 15px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}
</style>
