<template>
  <div class="wrapper container" style="min-width: 100%">
    <ClientLayout :user="user"></ClientLayout>
    <section class="container">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
      >
        <div class="card-form" style="min-width: 100%">
          <center>
            <img
              id="mygif"
              src="../../../../public/1495.gif"
              class="animated-gif center"
              v-show="!this.isLoaded"
            />
          </center>
          <div
            class="card-form__inner"
            style="text-align: center"
            v-if="this.isLoaded"
          >
            <center>
              <img
                :src="getCreative"
                class="card-img-top rounded-corners"
                id="logoTop"
                style="box-shadow: 0 2px 2px 0 rgba(90, 116, 148, 0.4)"
                :style="{
                  display: 'block',
                  maxWidth: !isMobile() ? '100vh' : '100%',
                  maxHeight: '35vh',
                  width: 'auto',
                  height: 'auto',
                }"
              />
            </center>
            <h2 class="card-title">
              <b>{{ getTitle }}</b>
            </h2>
            <div class="row mt-3 justify-content-center">
              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-4': true,

                  'col-sm-6': true,
                  'text-start': true,
                  'justify-content-center': true,
                  'camp-step-0': true,
                }"
              >
                <h3 class="text-center">Campaign Details</h3>
                <!-- <div class="m-2"><b>Type</b>: One-time payment</div> -->
                <div class="m-2"><b>Platform</b>: {{ this.data.platform }}</div>

                <div class="m-2">
                  <b>Clicks</b>:
                  {{ getClicks }}
                </div>

                <div class="m-2"><b>Impressions</b>: {{ getImpressions }}</div>
                <div class="m-2"><b>Reach</b>: {{ getReach }}</div>
                <div class="m-2"><b>Start Time</b>: {{ getStart }}</div>
                <div class="m-2"><b>End Time</b>: {{ getEnd }}</div>

                <div class="m-2"><b>Description</b>: {{ getDescription }}</div>
                <div class="m-2"><b>Post Caption</b>: {{ getCaption }}</div>
                <div class="m-2">
                  <b>Website Target</b>:
                  <a :href="getWebsite">{{ getWebsite }}</a>
                </div>
              </div>
              <div class="information-card col-md-3 col-sm-6 camp-step-1">
                <div>
                  <h3>Age Demographics</h3>
                  <div class="text-start">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Age</th>
                          <th scope="col">Clicks</th>
                          <th scope="col">Reach</th>
                        </tr>
                      </thead>
                      <tr
                        v-for="(data, index) in getAgeCampaignData"
                        :key="index"
                      >
                        <td>{{ data.age }}</td>
                        <td>{{ formatNumber(data.clicks) }}</td>
                        <td>{{ formatNumber(data.reach) }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-4': true,
                  'col-sm-6': true,
                  'text-center': true,
                  'camp-step-2': true,
                }"
              >
                <h3>Location Demographics</h3>
                <hr />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Region</th>
                      <th scope="col">Clicks</th>
                      <th scope="col">Reach</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(data, index) in getRegionCampaignData"
                    :key="index"
                  >
                    <td>{{ data.region }}</td>
                    <td>{{ formatNumber(data.clicks) }}</td>
                    <td>{{ formatNumber(data.reach) }}</td>
                  </tr>
                </table>
                <div class="mt-3">
                  <!-- <VueEllipseProgress
                    :progress="getRevenueGoalProgress"
                    animation="rs 1500 700"
                    color="#19d412"
                    line-mode="in 10"
                    empty-color="#363166"
                  >
                    <br />
                    <br />
                    Keep Going!
                  </VueEllipseProgress> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--End of Message Functionality-->
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
//import { VueEllipseProgress } from "vue-ellipse-progress";
import swal from 'sweetalert'
import ClientLayout from '../../components/ClientLayout.vue'
export default {
  components: {
    ClientLayout,

    //VueEllipseProgress,
  },
  data() {
    return {
      campaignId: 0,
      user: {},
      isLoaded: false,
      data: {
        description: '',
        campaignMetrics: {
          ageInsights: '',
          regionInsights: '',
          creative: '',
          caption: '',
          website: '',
          start: '',
          end: '',
          insights: [
            {
              clicks: '',
              impressions: '',
              reach: '',
            },
          ],
        },
      },
    }
  },
  methods: {
    formatNumber(number) {
      return number.toString()
    },
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1), // Months are zero-based
        day = '' + d.getDate(),
        year = d.getFullYear()

      // Add leading zero to month and day if needed
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [month, day, year].join('-')
    },
    async getCampaignMetrics() {
      try {
        const url =
          import.meta.env.VITE_APP_DB_URL +
          'advertisment/getCampaignMetrics?campaignId=' +
          this.campaignId
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        this.data = response.data
        this.isLoaded = true
      } catch (err) {
        swal(
          'Error',
          'Campaign is still publishing, check back in a few hours.',
          'error'
        )
      }
    },
  },
  created() {
    this.getUserDetails()
    this.campaignId = this.$route.params.campaignId
    this.getCampaignMetrics()
  },
  computed: {
    getDescription() {
      return this.data.description
    },
    getTitle() {
      return this.data.title
    },
    getAgeCampaignData() {
      return this.data.campaignMetrics.ageInsights
    },
    getRegionCampaignData() {
      return this.data.campaignMetrics.regionInsights
    },
    getClicks() {
      return this.formatNumber(this.data.campaignMetrics.insights[0].clicks)
    },
    getImpressions() {
      return this.formatNumber(
        this.data.campaignMetrics.insights[0].impressions
      )
    },
    getReach() {
      return this.formatNumber(this.data.campaignMetrics.insights[0].reach)
    },
    getCreative() {
      return this.data.creative
    },
    getCaption() {
      return this.data.caption
    },
    getWebsite() {
      return this.data.website
    },
    getStart() {
      return this.formatDate(this.data.start)
    },
    getEnd() {
      return this.formatDate(this.data.end)
    },
  },
  mounted: function () {},
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.message_tab {
  background: whitesmoke;
  border-color: whitesmoke;
  border-width: 3px;
  transition: all 0.2s ease-in-out;
}
.message_tab .card-header {
  background: lightgrey;
  transition: 0.3s;
}
/*.message_tab:hover .card-header{*/
/*  background: #19d412;*/
/*}*/
.message_tab:hover {
  background: white;
  cursor: pointer;
  transform: scale(1.1);
}

.off_tab {
  background: whitesmoke;
}
.on_tab {
  background: #19d412;
}

img.rounded-corners {
  border-radius: 30px;
}

.actionFeature {
  background-color: #a39ed0;
}

.action-button {
  background-color: #363166;
}

.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}
</style>
