<template>
  <div
    class="information-card p-4 shadow-sm"
    @click="buySubscription(subscription)"
    v-bind:style="{
      maxWidth: isMobile ? '90%' : '275px',
      cursor: 'pointer',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }"
  >
    <h5 class="card-title text-center mb-3">
      <span class="badge actionFeature mb-2 d-block">{{
        subscription.subscriptionName
      }}</span>
      <span class="text-muted" style="font-size: 15px">{{
        yearly ? 'Billed Annually' : 'Billed Monthly'
      }}</span>
    </h5>

    <h5 class="card-body text-center">
      <div>
        <label
          v-if="yearly"
          class="d-block"
          style="text-decoration: line-through"
          >${{ getFakeYearly }}</label
        >
        <span class="price">
          <i class="fa-solid fa-medal fa-xl m-1" style="color: #363166"></i>
          <label
            class="d-inline-block"
            style="font-size: 24px; font-weight: bold"
          >
            ${{ yearly ? getYearly + ' / year' : getMonthly + ' / month' }}
          </label>
        </span>
      </div>
    </h5>

    <button
      class="btn btn-primary rounded-pill mt-4 w-100"
      id="regularSignInBtn"
      @click="buySubscription(subscription)"
    >
      <h5>
        {{ this.getButtonText }}
      </h5>
    </button>

    <p class="text-muted text-center mt-2">Creating Your Events, Plus...</p>

    <!-- Feature List -->
    <ul class="plan-details__item list-unstyled">
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Customer Checkout Fee ~
          {{ (subscription.customerUpchargePercentage - 3).toFixed(1) }}%
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          AI Generated Website
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Personalized Branding
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Download Customer Data
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Unlimited Mass Emailing
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import numeral from 'numeral'

export default {
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: '',
        subscriptionTier: 0,
      },
    }
  },
  methods: {
    buySubscription(plan) {
      this.$emit('buySubscription', plan)
    },
    getSubscription() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'subscription/getSubscriptionByName?name=Pro'
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer' + localStorage.getItem('token'),
          },
        })
        .then(response => {
          this.subscription = response.data.subscription
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format('0,0.00')
    },
    getYearly() {
      return numeral(this.subscription.yearlyPrice).format('0,0.00')
    },
    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format('0,0.00')
    },
    getButtonText() {
      return 'Get Started'
    },
  },
  mounted() {
    this.getSubscription()
  },
}
</script>

<style scoped>
.actionFeature {
  background-color: #363166;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.information-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
}

.btn-primary {
  background-color: #363166;
  border-color: #363166;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.list-unstyled li {
  display: flex;
  align-items: center;
}

.list-unstyled li i {
  margin-right: 8px;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
  color: #363166;
}

.price {
  font-size: 24px;
  color: #363166;
}

.plan-details__item-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

.promotional-price {
  font-weight: bold;
  color: #4caf50;
}
</style>
