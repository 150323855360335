<template>
  <div class="container">
    <section style="margin-top: 7%">
      <div class="container mt-5">
        <div class="card-form">
          <h2 class="text-center mb-3">Additional Fields</h2>
          <draggable :list="this.customFields" class="draggable" item-key="id">
            <template #item="{ element, index }">
              <li
                class="list-group-item"
                v-bind:key="'customField' + index"
                v-if="
                  element.fieldType != 'fileUpload' &&
                  element.fieldType != 'dropdown'
                "
              >
                <div class="row">
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      >Field Name</label
                    >
                    <input
                      v-model="element.fieldName"
                      required
                      id="form6Example1"
                      class="card-input__input"
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    />
                  </div>
                  <div class="col" style="margin-top: 5px">
                    <label
                      class="form-label"
                      for="form6Example3"
                      :style="{ marginRight: isMobile ? '7px' : '' }"
                      ><span
                        >Input Type
                        <i
                          v-if="editable"
                          class="col fa-solid fa-trash removeField"
                          @click="removeField(index)"
                        ></i></span
                    ></label>
                    <select
                      class="card-input__input -select"
                      v-model="element.fieldType"
                      required
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    >
                      <option disabled selected value="">Type</option>
                      <option value="height" class="option">Height</option>
                      <option value="email" class="option">Email</option>
                      <option value="phoneNumber" class="option">
                        Phone Number
                      </option>
                      <option value="textShort" class="option">
                        Text-Short
                      </option>
                      <option value="textLong" class="option">Text-Long</option>
                      <option value="number" class="option">Number</option>
                      <option value="yesNo" class="option">Yes/No</option>
                      <option
                        v-if="!membership"
                        value="dropdown"
                        class="option"
                      >
                        Dropdown
                      </option>
                      <option
                        value="fileUpload"
                        class="option"
                        v-if="!membership"
                      >
                        File Upload
                      </option>
                    </select>
                  </div>
                </div>
              </li>
              <li
                class="list-group-item"
                v-bind:key="'customField' + index"
                v-else-if="element.fieldType === 'fileUpload'"
              >
                <div class="row">
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      >Field Name</label
                    >
                    <input
                      v-model="element.fieldName"
                      required
                      id="form6Example1"
                      class="card-input__input"
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    />
                  </div>
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      ><span
                        >Input Type
                        <i
                          v-if="editable"
                          class="col fa-solid fa-trash removeField"
                          @click="removeField(index)"
                        ></i></span
                    ></label>
                    <select
                      class="card-input__input -select"
                      v-model="element.fieldType"
                      required
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    >
                      <option disabled selected value="">Type</option>
                      <option value="height" class="option">Height</option>
                      <option value="email" class="option">Email</option>
                      <option value="phoneNumber" class="option">
                        Phone Number
                      </option>
                      <option value="textShort" class="option">
                        Text-Short
                      </option>
                      <option value="textLong" class="option">Text-Long</option>
                      <option value="number" class="option">Number</option>
                      <option value="yesNo" class="option">Yes/No</option>
                      <option value="dropdown" class="option">Dropdown</option>
                      <option
                        value="fileUpload"
                        class="option"
                        v-if="!membership"
                      >
                        File Upload
                      </option>
                    </select>
                    <input
                      type="file"
                      accept="*"
                      @change="uploadCamperFile($event, index)"
                      :id="'files' + index"
                      class="hidden"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    /><label class="link-primary" :for="'files' + index"
                      >Select file</label
                    >
                    <p style="color: red">{{ element.fileName }}</p>
                  </div>
                </div>
              </li>
              <li
                class="list-group-item"
                v-bind:key="'customField' + index"
                v-else-if="element.fieldType === 'dropdown'"
              >
                <div class="row">
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      >Field Name</label
                    >
                    <input
                      v-model="element.fieldName"
                      required
                      id="form6Example1"
                      class="card-input__input"
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    />
                  </div>
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      ><span
                        >Input Type
                        <i
                          v-if="editable"
                          class="col fa-solid fa-trash removeField"
                          @click="removeField(index)"
                        ></i></span
                    ></label>
                    <select
                      class="card-input__input -select"
                      v-model="element.fieldType"
                      required
                      @change="backToCamp"
                      :disabled="!editable && index < startNewCustomFieldsIndex"
                    >
                      <option disabled selected value="">Type</option>
                      <option value="height" class="option">Height</option>
                      <option value="email" class="option">Email</option>
                      <option value="phoneNumber" class="option">
                        Phone Number
                      </option>
                      <option value="textShort" class="option">
                        Text-Short
                      </option>
                      <option value="textLong" class="option">Text-Long</option>
                      <option value="number" class="option">Number</option>
                      <option value="yesNo" class="option">Yes/No</option>
                      <option value="dropdown" class="option">Dropdown</option>
                      <option
                        value="fileUpload"
                        class="option"
                        v-if="!membership"
                      >
                        File Upload
                      </option>
                    </select>
                  </div>
                  <div class="row">
                    <div class="col m-3">
                      Dropdown Options
                      <i
                        class="fa-solid fa-circle-info"
                        style="color: #45487a; cursor: pointer"
                        @click="showDropdownOptionsExplanation"
                      ></i>
                    </div>

                    <div class="col m-3">
                      <span>
                        <input
                          v-model="element.fieldOptions[0]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>

                      <span>
                        <input
                          v-model="element.fieldOptions[1]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                      <span>
                        <input
                          v-model="element.fieldOptions[2]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                      <span>
                        <input
                          v-model="element.fieldOptions[3]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                      <span>
                        <input
                          v-model="element.fieldOptions[4]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                      <span>
                        <input
                          v-model="element.fieldOptions[5]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                      <span>
                        <input
                          v-model="element.fieldOptions[6]"
                          required
                          class="card-input__input m-1"
                          @change="backToCamp"
                          :disabled="
                            !editable && index < startNewCustomFieldsIndex
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </draggable>
          <div class="row justify-content-center">
            <button
              class="btn btn-primary col-sm-6 col-md-5 m-3 rounded-pill"
              @click="addField"
            >
              Add Custom Field
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import swal from 'sweetalert'
import axios from 'axios'
export default {
  components: {
    draggable,
  },
  data() {
    return {
      customFields: [
        {
          fieldName: '',
          fieldType: '',
          fieldOptions: ['', '', '', '', '', '', ''],
        },
      ],
    }
  },
  props: {
    fields: Array,
    membership: Boolean,
    isMobile: Boolean,
    editable: Boolean,
    startNewCustomFieldsIndex: Number,
  },
  methods: {
    handleFieldChange() {},
    prepopulate() {
      if (this.fields) {
        if (this.fields.length > 0) {
          this.customFields = [...this.fields, ...this.customFields]
        }
      }
      // this.options = [{ optionName: "", dropdownIndex: 0 }];
    },
    addField() {
      this.customFields.push({
        fieldName: '',
        fieldType: '',
        fieldOptions: ['', '', '', '', '', '', ''],
      })
    },
    // addOption(customFieldIndex) {
    //   console.log(customFieldIndex);
    //   this.customFields[customFieldIndex].fieldOptions.push("");
    // },
    showDropdownOptionsExplanation() {
      swal({
        title: 'Dropdown Options',
        text: "Fill out each text-box with the dropdown value you would like to display on the customer's signup form. There is a maximum of 7 options. Only text-boxes that have been filled out will be shown as an option. \n\n Any of the 7 options you do not wish to use should be left blank.",
        icon: 'info',
      })
    },
    backToCamp() {
      console.log(this.customFields)

      this.$emit(
        'back',
        this.customFields.filter(
          x => x.fieldName && x.fieldType && x.fieldOptions
        )
      )
    },
    removeField(index) {
      console.log('Deleting ' + index)
      this.customFields.splice(index, 1)
      this.backToCamp()
    },
    removeOption(customFieldIndex, optionIndex) {
      this.customFields[customFieldIndex].fieldOptions.splice(optionIndex, 1)
    },
    uploadCamperFile(event, index) {
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadCampFile'
      let data = new FormData()
      data.append('file', event.target.files[0])
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(res => {
          let copy = { ...this.customFields[index] }
          copy.fileLocation = res.data.location
          copy.fileName = res.data.name
          this.customFields[index] = copy
          // Vue.set(this.customFields, index, copy);
          this.backToCamp()
        })
        .catch(function (err) {
          let copy = { ...this.customFields[index] }
          copy.fileLocation = ''
          copy.fileName = ''
          this.customFields[index] = copy
          // Vue.set(this.customFields, index, copy);
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },
  },
  mounted() {
    this.prepopulate()
  },
}
</script>
<style>
.removeField {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeField:hover {
  color: darkred;
  cursor: pointer;
}
.draggable > li:hover {
  cursor: grab;
}
.hidden {
  display: none;
}
</style>
