<template>
  <div class="wrapper container" style="max-width: 90%">
    <MemberNav :member="member.firstName"></MemberNav>
    <section class="container" style="margin-top: 4%; max-width: 100%">
      <div class="container mt-5">
        <div class="row justify-content-center">
          <b>
            <h1 v-if="this.memberships.length != 0" style="text-align: center">
              My Recurring Payments
            </h1>
          </b>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipCard
                class="grow"
                v-if="index % 3 == 1"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :memberID="member._id"
                :isActive="membership.isActive"
                :memberName="
                  membership.memberFirstName + ' ' + membership.memberLastName
                "
                :subscriptionID="membership.subscriptionID"
              ></MembershipCard>
            </div>
          </div>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <h2 v-if="this.memberships.length == 0" style="text-align: center">
              You have no memberships currently
            </h2>
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipCard
                class="grow"
                v-if="index % 3 == 0"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :memberID="member._id"
                :isActive="membership.isActive"
                :memberName="
                  membership.memberFirstName + ' ' + membership.memberLastName
                "
                :subscriptionID="membership.subscriptionID"
              ></MembershipCard>
            </div>
          </div>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipCard
                class="grow"
                v-if="index % 3 == 2"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :memberID="member._id"
                :isActive="membership.isActive"
                :memberName="
                  membership.memberFirstName + ' ' + membership.memberLastName
                "
                :subscriptionID="membership.subscriptionID"
              ></MembershipCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import MemberNav from '../components/MemberNav.vue'
import MembershipCard from '../components/MembershipCard.vue'
import swal from 'sweetalert'

export default {
  components: {
    MemberNav,
    MembershipCard,
  },
  data() {
    return {
      member: {},
      memberships: [],
    }
  },
  methods: {
    getMemberDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.member = decoded
      console.log(this.member)
    },

    async getMemberships() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'membership/getMember?memberID=' +
        this.member._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.member = response.data.member
          this.memberships = response.data.member.memberships
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },

  created() {
    this.getMemberDetails()
    this.getMemberships()
  },

  computed: {},
}
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}
</style>
