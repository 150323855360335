<template>
  <div
    class="modal fade"
    id="addCalendar"
    tabindex="-1"
    aria-labelledby="addCalendarLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addCalendarLabel">New Calendar</h5>

          <button
            type="button"
            class="btn-close"
            id="addCalendarClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label class="text-start" for="form6Example3"
            >Calendar Name <span class="text-danger">*</span></label
          >
          <input
            v-model="calendar.name"
            required
            id="form6Example1"
            class="form-control mb-3"
            type="text"
          />
          <label class="text-start" for="form6Example3"
            >Associated Email <span class="text-danger">*</span></label
          >
          <input
            v-model="calendar.email"
            required
            id="form6Example1"
            class="form-control mb-3"
            type="email"
          />
          <!--          <label class="text-start" for="form6Example3"-->
          <!--            >Calendar Description</label-->
          <!--          >-->
          <!--          <input-->
          <!--            v-model="calendar.description"-->
          <!--            required-->
          <!--            id="form6Example1"-->
          <!--            class="form-control mb-3"-->
          <!--            type="textarea"-->
          <!--          />-->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-primary" @click="addCalendar()">
            Add Calendar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'
export default {
  data() {
    return {
      calendar: {
        name: '',
        email: '',
        description: '',
      },
    }
  },
  components: {},
  props: {
    userID: String,
  },
  methods: {
    addCalendar() {
      if (this.calendar.name == '' || this.calendar.email == '') {
        swal({
          title: 'Error',
          text: 'Please fill out name and email',
          icon: 'error',
        })
        return
      }
      let url = import.meta.env.VITE_APP_DB_URL + 'scheduling/addCalendar'
      axios
        .post(
          url,
          {
            calendar: this.calendar,
            userID: this.userID,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          this.$emit('addedCalendar', {
            calendars: response.data.calendars,
          })
          document.getElementById('addAvailabilityClose').click()
          swal(
            'Success',
            this.calendar.name + ' has been added successfully',
            'success'
          )
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },
  computed: {},
}
</script>

<style scoped></style>
