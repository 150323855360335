<template>
  <div class="wrapper" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div class="card-form">
          <div class="card-input">
            <label class="card-input__label">Reset Code</label>
            <div class="row justify-content-center">
              <input
                type="text"
                id="ist"
                class="col-md-1 col-sm-1 card-input__input m-1"
                :maxlength="getMaxLength"
                v-model="resetTokens[0]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @keyup="clickEvent('sec')"
                @paste="onPaste"
                @input="onInput"
              />
              <input
                type="text"
                id="sec"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[1]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @keyup="clickEvent('third')"
              />
              <input
                type="text"
                id="third"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[2]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @keyup="clickEvent('fourth')"
              />
              <input
                type="text"
                id="fourth"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[3]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @keyup="clickEvent('fifth')"
              />
              <input
                type="text"
                id="fifth"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[4]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                @keyup="clickEvent('sixth')"
              />
              <input
                type="text"
                id="sixth"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[5]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @keyup="clickEvent('seventh')"
              />
              <input
                type="text"
                id="seventh"
                class="col-md-1 col-sm-1 card-input__input m-1"
                maxlength="1"
                v-model="resetTokens[6]"
                :style="{ maxWidth: isMobile ? '15%' : '' }"
                autocomplete="off"
                @focus="focus()"
              />
            </div>
          </div>
          <div class="card-input">
            <label for="email" class="card-input__label">Current Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              placeholder="Email"
              v-model="coach.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">New Password</label>
            <input
              type="password"
              id="newPassword"
              class="card-input__input"
              placeholder="New Password"
              v-model="coach.newPassword"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Confirm Password</label>
            <input
              type="password"
              id="passwordConfirm"
              class="card-input__input"
              placeholder="Confirm Password"
              v-model="coach.passwordConfirm"
            />
          </div>
        </div>

        <button
          class="card-form__button gradient-button"
          @click="this.resetPassword"
        >
          Reset Password
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
export default {
  data() {
    return {
      coach: {
        email: '',
        resetPasswordToken: '',
        newPassword: '',
        passwordConfirm: '',
      },
      resetTokens: ['', '', '', '', '', '', ''],
      data: '',
    }
  },
  methods: {
    clickEvent(id) {
      document.getElementById(id).focus()
    },
    onPaste(evt) {
      for (let i = 0; i < 7; i++) {
        this.resetTokens[i] = evt.clipboardData.getData('text')[i]
      }
    },

    //on paste for mobile devices
    onInput(e) {
      if (e.data) {
        if (e.data.length === 7) {
          for (let i = 0; i < 7; i++) {
            this.resetTokens[i] = e.data[i]
          }
        }
      }
    },
    async resetPassword() {
      this.coach.resetPasswordToken = this.resetTokens.join('').toString()
      let url = import.meta.env.VITE_APP_DB_URL + 'user/resetPassword'
      if (this.validateInfo()) {
        axios
          .post(url, this.coach)
          .then(response => {
            let success = response.data.success
            if (success) {
              swal('Success', 'Password Updated Successfully', 'success')
              this.$router.push('/')
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message)
            let error = err.response
            swal('Error', error.data.errorMessage, 'error')
          })
      }
    },
    validateInfo() {
      if (this.coach.email == '') {
        swal('Error', 'Please enter email', 'error')
        return false
      } else if (this.coach.newPassword == '') {
        swal('Error', 'Please enter password', 'error')
        return false
      }
      if (this.coach.newPassword !== this.coach.passwordConfirm) {
        swal(
          'Error',
          'Passwords do not match. Please check your spelling and capitalization and try again.',
          'error'
        )
        return false
      }
      return true
    },
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    getMaxLength() {
      return this.isMobile ? 7 : 1
    },
  },
}
</script>
