<template>
  <div
    class="modal fade"
    id="PeopleDetailsModal"
    tabindex="-1"
    aria-labelledby="PeopleDetailsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="PeopleDetailsModalLabel">
            Details
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            id="PeopleDetailsCloseButton"
            aria-label="Close"
          ></button>

          <button
            type="button"
            id="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            style="display: none"
          ></button>
        </div>
        <div class="modal-body">
          <PeopleDetails
            :firstName="firstName"
            :lastName="lastName"
            :camps="camps"
            :teamCamps="teamCamps"
            :memberships="memberships"
          ></PeopleDetails>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import PeopleDetails from '@/client/components/CRM/PeopleDetails.vue'

export default {
  components: { PeopleDetails },
  props: {
    firstName: String,
    lastName: String,
    camps: Array,
    teamCamps: Array,
    memberships: Array,
  },
}
</script>

<style scoped></style>
