<template>
  <div class="wrapper container" id="checkIn">
    <ClientLayout :user="user"></ClientLayout>
    <section class="main container">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
      >
        <div class="row justify-content-center">
          <div class="row" style="min-width: 300px; max-width: 500px">
            <div class="col-md-6 col-sm-6">
              <center><h1>Customers</h1></center>
            </div>
            <div
              class="col-md-5 col-sm-3 mt-2"
              :style="{ maxWidth: isMobile ? '200px' : '' }"
            >
              <div
                class="btn btn-secondary"
                v-if="this.user.subscription.subscriptionTier >= 2"
                style="color: white; background-color: #363166"
              >
                <JsonExcel
                  :data="resultQuery"
                  :name="campName + '.xls'"
                  :fields="json_fields"
                >
                  Download Data
                </JsonExcel>
              </div>
              <div
                v-else
                class="btn btn-secondary"
                @click="showDownloadAd()"
                style="color: white; background-color: #363166"
              >
                Download Data
              </div>
            </div>
            <div
              class="col-md-1 col-sm-3 mt-2"
              :style="{ maxWidth: isMobile ? '50px' : '' }"
            >
              <center>
                <a :href="getLinktoCamp" class="btn btn-primary mb-3"> Back</a>
              </center>
            </div>
          </div>
          <div class="row justify-content-center">
            <form class="d-flex justify-content-end col-md-5">
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                style="
                  margin-bottom: 10px;
                  min-width: 300px;
                  max-width: 500px;
                  margin-left: 15px;
                "
                v-model="searchQuery"
              />
            </form>
            <div class="col-md-2">
              <div
                class="btn"
                style="color: white; background-color: #363166"
                @click="goToUpload()"
              >
                <i class="fa-solid fa-upload"></i> Upload Customer
              </div>
            </div>
          </div>
        </div>
        <div v-if="resultQuery.length > 0">
          <div
            v-for="camper in resultQuery"
            :key="camper._id"
            class="row justify-content-center"
          >
            <CamperCard
              :firstName="camper.firstName"
              :lastName="camper.lastName"
              :tShirtSize="camper.tShirtSize"
              :isCheckedIn="camper.isCheckedIn"
              :tShirtReceived="camper.tShirtReceived"
              :campName="campName"
              :camperId="camper._id"
              :discountCode="camper.discountCodeApplied"
              :camperAddOns="camper.customAddOnValues"
              :camperProfileImage="camper.camperProfileImage"
              :bundlePricePaid="camper.bundlePricePaid"
              :scheduledSession="camper.scheduledSession"
              :pricePaid="camper.pricePaid"
              class="grow"
            >
            </CamperCard>
          </div>
        </div>
        <div v-else>
          <div class="row justify-content-center mt-5">
            <div class="col-md-6 col-sm-6">
              <center><h1>No results found</h1></center>
              <div class="row justify-content-center mt-3">
                <a class="card2 col-md-6 col-sm-6 order" :href="getAdLink">
                  <h5 class="text-center">Advertise My Event!</h5>
                  <div class="go-corner" href="#">
                    <div class="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import CamperCard from '../../components/Campers/CamperCard.vue'
import swal from 'sweetalert'
import ClientLayout from '../../components/ClientLayout.vue'
import JsonExcel from 'vue-json-excel3'
export default {
  components: { ClientLayout, CamperCard, JsonExcel },
  data() {
    return {
      user: {},
      camp: {},
      campID: '',
      campName: '',
      campers: [],
      searchQuery: '',
      subscriptions: {},
      json_fields: {
        'Camper First Name': 'firstName',
        'Camper Last Name': 'lastName',
        'Camper T-shirt size': 'tShirtSize',
        'Camper Emergency Contact First Name': {
          callback: value => {
            return value.emergencyContactInfo[0].firstName
          },
        },
        'Camper Emergency Contact Last Name': {
          callback: value => {
            return value.emergencyContactInfo[0].lastName
          },
        },
        'Camper Emergency Contact Email': {
          callback: value => {
            return value.emergencyContactInfo[0].email
          },
        },
        'Camper Emergency Contact Phone Number': {
          callback: value => {
            return value.emergencyContactInfo[0].phoneNumber
          },
        },
        'Camper Emergency Contact Address': {
          callback: value => {
            return value.emergencyContactInfo[0].address
          },
        },
        'Camper Emergency Contact City': {
          callback: value => {
            return value.emergencyContactInfo[0].city
          },
        },
        'Camper Emergency Contact State': {
          callback: value => {
            return value.emergencyContactInfo[0].state
          },
        },
        'Camper Emergency Contact Zip Code': {
          callback: value => {
            return value.emergencyContactInfo[0].zipCode
          },
        },
        'Amount Paid (USD)': {
          callback: value => {
            return value.pricePaid
          },
        },
      },
    }
  },
  methods: {
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },

    goToUpload() {
      this.$router.push(`/Dashboard/UploadCamper/${this.campID}`)
    },

    showDownloadAd() {
      swal({
        icon: 'info',
        title: 'Upgrade Your Plan',
        text: 'You need to be at least a Pro user to download customer data locally. Please upgrade your plan to continue.',
      })
      document.getElementById('openAdvertisementPopUp').click()
    },

    addCustomFieldsExport() {
      for (let x = 0; x < this.camp.customFields.length; x++) {
        console.log(x)
        this.json_fields[this.camp.customFields[x]?.fieldName] = {
          callback: value => {
            return value.customFieldValues[x]?.fieldValue
          },
        }
      }
    },
    addCustomAddOnsExport() {
      for (let x = 0; x < this.camp.customAddOns.length; x++) {
        console.log(x)
        this.json_fields[
          'Camper Purchased ' + this.camp.customAddOns[x]?.addOnName
        ] = {
          callback: value => {
            return value?.customAddOnValues[x]?.isPurchased
          },
        }
      }
    },

    discountsExport() {
      for (let x = 0; x < this.camp.discounts.length; x++) {
        console.log(x)
        this.json_fields['Discount Code Used'] = {
          callback: value => {
            return value.usedDiscountCode ? value.discountCodeApplied : '---'
          },
        }
      }
    },

    async getCamp() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getCampByCampID?campID=' +
        this.campID
      await axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.camp = response.data.camp
          if (response.data.camp.sessionSupported) {
            this.campers = response.data.camp.campers.sort((a, b) => {
              return (
                new Date(a.scheduledSession?.date) -
                  new Date(b.scheduledSession?.date) ||
                a.scheduledSession?.time - b.scheduledSession?.time
              )
            })
          } else {
            this.campers = response.data.camp.campers
          }

          this.campName = response.data.camp.campName
          this.addCustomFieldsExport()
          this.addCustomAddOnsExport()
          this.discountsExport()

          //this.camps = response.data;
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },

  created() {
    this.campID = this.$route.params.campId
    this.getUserDetails()
    this.getCamp()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getLinktoCamp() {
      return '/Dashboard/ViewCamp/Individual/' + this.campID
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.campers.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(' ')
            .every(
              v =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v)
            )
        })
      } else {
        return this.campers
      }
    },
    getAdLink() {
      if (this.user.subscription.subscriptionTier >= 4) {
        return '/Dashboard/AdStore'
      } else {
        let adSub = this.subscriptions[this.subscriptions.length - 1]
        return '/SubscriptionChoices/Checkout/' + adSub?._id + '/false'
      }
    },
  },
  mounted() {
    let url =
      import.meta.env.VITE_APP_DB_URL + 'subscription/getAllSubscriptions'
    axios.get(url).then(response => {
      this.subscriptions = response.data.subscriptions
    })
  },
}
</script>
<style scoped>
.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.07);
}
</style>
