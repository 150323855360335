<template>
  <div class="wrapper container justify-content-center" id="app">
    <Header
      :title="this.websiteInfo.title"
      :primaryColor="primaryColor"
    ></Header>
    <div class="container mt-5">
      <h1 style="text-align: center">Active Links</h1>
      <div class="d-flex row justify-content-center">
        <div class="col-md-6 col-sm-6">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            style="margin-bottom: 10px; min-width: 80%"
            v-model="searchQuery"
          />
        </div>
        <div class="col-md-3 col-sm-6">
          <multiselect
            v-model="searchValues"
            placeholder="Type of event"
            label="name"
            track-by="code"
            :options="options"
            :multiple="true"
            :style="primaryColor"
          ></multiselect>
        </div>
        <div class="col-md-3 col-sm-6 text-start">
          <div class="card" style="min-height: 40px">
            <VueDatePicker
              :color="this.primaryColor"
              :max-date="this.getMaxDate"
              :min-date="this.getMinDate"
              :format="this.format"
              :enable-time="false"
              v-model="chosenDate"
              :ui="{
                menu: 'purple-menu',
                input: 'purple-menu',
                navBtnNext: 'purple-menu',
              }"
              id="datePicker"
            ></VueDatePicker>
          </div>
        </div>
      </div>
      <div class="row mt-3 justify-content-center">
        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <ul
            class="list-group"
            v-for="(event, index) in resultQuery"
            :key="event.id"
          >
            <EventSummaryCard
              v-if="index % 3 == 0"
              :date="event.date"
              :price="event.price"
              :title="event.name"
              :type="event.type"
              :logo="event.logo"
              :id="event.id"
              :primaryColor="primaryColor"
              :secondaryColor="secondaryColor"
            ></EventSummaryCard>
          </ul>
        </div>

        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <h2 v-if="this.events.length == 0" style="text-align: center">
            There are no links currently posted
          </h2>

          <ul
            class="list-group"
            v-for="(event, index) in resultQuery"
            :key="event.id"
          >
            <EventSummaryCard
              v-if="index % 3 == 1"
              :date="event.date"
              :price="event.price"
              :title="event.name"
              :type="event.type"
              :logo="event.logo"
              :id="event.id"
              :primaryColor="primaryColor"
              :secondaryColor="secondaryColor"
            ></EventSummaryCard>
          </ul>
        </div>

        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <ul
            class="list-group"
            v-for="(event, index) in resultQuery"
            :key="event.id"
          >
            <EventSummaryCard
              v-if="index % 3 == 2"
              :date="event.date"
              :price="event.price"
              :title="event.name"
              :type="event.type"
              :logo="event.logo"
              :id="event.id"
              :primaryColor="primaryColor"
              :secondaryColor="secondaryColor"
            ></EventSummaryCard>
          </ul>
        </div>
      </div>
    </div>
    <Footer :primaryColor="this.primaryColor"></Footer>
  </div>
</template>
<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import EventSummaryCard from '../components/EventSummaryCard.vue'
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import swal from 'sweetalert'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import moment from 'moment/moment'
export default {
  components: {
    VueDatePicker,
    Header,
    EventSummaryCard,
    Multiselect,
    Footer,
  },
  data() {
    return {
      coachId: '',
      searchQuery: '',
      events: [],
      searchValues: [],
      primaryColor: '#363166',
      secondaryColor: '#19D412',
      chosenDate: null,
      availability: [],
      options: [
        { name: 'One-time', code: 'camp' },
        { name: 'Recurring', code: 'membership' },
        //{ name: "Memberships", code: "membership" },
        //{ name: "One-on-One", code: "trainingSession" },
      ],
      websiteInfo: {},
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    async getAllEvents() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'website/getAllEvents?coachId=' +
        this.coachId
      axios
        .get(url)
        .then(response => {
          this.events = response.data
          //this.events.sort((a, b) => (a.date > b.date ? 1 : -1));
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
          swal('Error', 'Coach events could not be found.', 'error')
        })
    },

    getAllowedDate(item) {
      let currentDateInRange = moment(this.chosenDate).format('YYYY-MM-DD')

      for (const availability of this.availability) {
        //Check if this availability applies to this specific camp
        let availabilityApplies = false
        for (const camp of availability.selectedCamps) {
          if (camp.campStripeId == item.id) {
            availabilityApplies = true
          }
        }

        //if it applies, check if the date is in the available range
        if (availabilityApplies) {
          let availabilityDate = moment(availability.date).format('YYYY-MM-DD')
          if (
            availabilityDate == currentDateInRange &&
            !availability.isDeleted
          ) {
            return true
          } else if (availability.repeating && !availability.isDeleted) {
            if (moment(currentDateInRange).isAfter(moment(availabilityDate))) {
              for (const alteration of availability.alterations) {
                let alterationDate = moment(alteration.date).format(
                  'YYYY-MM-DD'
                )
                if (currentDateInRange == alterationDate) {
                  if (alteration.isDeleted) {
                    return false
                  } else {
                    return true
                  }
                }
              }
              if (availability.repeatingSetting === 'DAY') {
                return true
              } else if (availability.repeatingSetting === 'WEEK') {
                for (
                  let weekIndex = 0;
                  weekIndex < item.sessionInfo.maxAdvanceNotice;
                  weekIndex++
                ) {
                  let testDate = moment(availabilityDate)
                    .add(weekIndex, 'weeks')
                    .format('YYYY-MM-DD')
                  if (testDate == currentDateInRange) {
                    return true
                  }
                }
              } else if (availability.repeatingSetting === 'MONTH') {
                for (
                  let monthIndex = 0;
                  monthIndex < item.sessionInfo.maxAdvanceNotice;
                  monthIndex++
                ) {
                  let testDate = moment(availabilityDate)
                    .add(monthIndex, 'months')
                    .format('YYYY-MM-DD')
                  if (testDate == currentDateInRange) {
                    return true
                  }
                }
              }
            }
          }
        }
      }
      return false
    },

    getAvailabilityForAllSessions() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'scheduling/getAvailabilityForAllSessions?userID=' +
        this.coachId
      axios
        .get(url)
        .then(response => {
          this.availability = response.data.availability
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
          swal('Error', 'Availability could not be found.', 'error')
        })
    },
    format(date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `Date: ${month}/${day}/${year}`
    },
  },
  created() {
    this.coachId = this.$route.params.coachId
    if (this.$route.params.primaryColor) {
      this.primaryColor = '#' + this.$route.params.primaryColor
    }
    if (this.$route.params.secondaryColor) {
      this.secondaryColor = '#' + this.$route.params.secondaryColor
    }
    this.getAllEvents()
    this.getAvailabilityForAllSessions()
  },
  computed: {
    resultQuery() {
      if (this.searchValues.length == 0 && !this.chosenDate) {
        if (this.searchQuery) {
          return this.events.filter(item => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        } else {
          return this.events
        }
      } else if (this.searchValues.length == 0 && this.chosenDate) {
        let temp = [...this.events]
        temp = temp.filter(item => {
          if (item.sessionSupported) {
            return this.getAllowedDate(item)
          } else {
            if (item.date && !item.acceptLateRegistration) {
              return (
                moment(item.date).format('YYYY-MM-DD') ==
                moment(this.chosenDate).format('YYYY-MM-DD')
              )
            } else if (item.endDate && item.acceptLateRegistration) {
              return (
                moment(this.chosenDate).format('YYYY-MM-DD') >=
                  moment(item.date).format('YYYY-MM-DD') &&
                moment(this.chosenDate).format('YYYY-MM-DD') <=
                  moment(item.endDate).format('YYYY-MM-DD')
              )
            } else {
              return true
            }
          }
        })

        if (this.searchQuery) {
          temp = temp.filter(item => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        }

        return temp
      } else {
        let temp = []
        //filter by multiselect
        if (this.searchValues.length > 0) {
          let values = this.searchValues
          for (let x = 0; x < this.searchValues.length; x++) {
            temp.push(
              ...this.events.filter(function (y) {
                return y.type == values[x].code
              })
            )
          }
        }

        // filter further by search query
        if (this.searchQuery) {
          temp = temp.filter(item => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        }

        temp = temp.filter(item => {
          if (item.sessionSupported) {
            return this.getAllowedDate(item)
          } else {
            if (item.date && !item.acceptLateRegistration) {
              return (
                moment(item.date).format('YYYY-MM-DD') ==
                moment(this.chosenDate).format('YYYY-MM-DD')
              )
            } else if (item.endDate && item.acceptLateRegistration) {
              return (
                moment(this.chosenDate).format('YYYY-MM-DD') >=
                  moment(item.date).format('YYYY-MM-DD') &&
                moment(this.chosenDate).format('YYYY-MM-DD') <=
                  moment(item.endDate).format('YYYY-MM-DD')
              )
            } else {
              return true
            }
          }
        })

        return temp
      }
    },

    getMaxDate() {
      let today = new Date()
      let maxDate = moment(today)
      for (const event of this.events) {
        if (event.sessionSupported) {
          let testMaxDate = moment(today).add(
            event.sessionInfo.maxAdvanceNotice,
            'days'
          )
          if (testMaxDate > maxDate) {
            maxDate = testMaxDate
          }
        } else if (event.campDates) {
          //check camp date
          if (event.campDates.length > 0) {
            let testMaxDate = moment(
              event.campDates[event.campDates.length - 1].start
            )
            if (testMaxDate > maxDate) {
              maxDate = testMaxDate
            }
          }
        }
      }

      if (maxDate == moment(today)) {
        maxDate = moment(today).add(120, 'days')
      }

      return maxDate.format('YYYY-MM-DD')
    },
    getMinDate() {
      return moment(new Date()).format('YYYY-MM-DD')
    },

    menuCss() {
      return `{background-color: red; color: green;}`
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

.dp__theme_light {
  --dp-primary-color: v-bind(primaryColor);
  --dp-secondary-color: v-bind(secondaryColor);

  /* ... and so on for other colors */
}
</style>
