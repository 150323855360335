<template>
  <div style="min-width: 100%">
    <section style="min-width: 100%">
      <div class="card-form__inner mt-5">
        <div class="card-form__header">
          <div class="row">
            <div class="col-md-10">
              <h2 class="card-form__title text-center">
                {{ this.user.recruitProfile.firstName }}'s Athlete Profile
              </h2>
              <center>
                <a :href="getWebsiteURL" target="_blank">{{ getWebsiteURL }}</a>
              </center>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary btn-block" @click="saveChanges()">
                Save Changes
              </button>

              <button
                class="btn btn-danger btn-block"
                style="margin-top: 5px"
                @click="deleteWebsite"
              >
                Delete Website
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="row mt-5 justify-content-center">
          <div class="col-md-5 col-sm-6 m-2">
            <label class="form-label">Primary Color</label>
            <i
              class="fa-solid fa-circle-info fa-lg"
              style="color: #45487a; cursor: pointer; margin-left: 3px"
              @click="pColorExplanation"
            ></i>
            <br />
            <input
              id="colorPicker"
              type="color"
              v-model="website.primaryColor"
            />
            <div
              class="color-display rounded"
              :style="{ backgroundColor: website.primaryColor }"
            >
              {{ website.primaryColor }}
            </div>
          </div>

          <div class="col-md-5 col-sm-6 m-2">
            <label class="form-label">Accent Color</label>
            <i
              class="fa-solid fa-circle-info fa-lg"
              style="color: #45487a; cursor: pointer; margin-left: 3px"
              @click="sColorExplanation"
            ></i
            ><br />
            <input
              id="colorPicker"
              type="color"
              v-model="website.secondaryColor"
            />
            <div
              class="color-display rounded"
              :style="{ backgroundColor: website.secondaryColor }"
            >
              {{ website.secondaryColor }}
            </div>
          </div>
        </div>
        <hr />
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              @click="showHighlightButtons = false"
            >
              {{
                this.user.recruitProfile.firstName +
                ' ' +
                this.user.recruitProfile.lastName
              }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="metrics-tab"
              data-bs-toggle="tab"
              data-bs-target="#metrics"
              type="button"
              role="tab"
              aria-controls="metrics"
              aria-selected="true"
              @click="showHighlightButtons = false"
            >
              My Metrics
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="highlights-tab"
              data-bs-toggle="tab"
              data-bs-target="#highlights"
              type="button"
              role="tab"
              aria-controls="highlights"
              aria-selected="true"
              @click="showHighlightButtons = true"
            >
              Highlights
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active rounded-3"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
            :style="getWebsiteBackground"
          >
            <br />
            <div class="row justify-content-center m-5">
              <div class="col-md-6">
                <input
                  type="text"
                  class="card-input__input"
                  v-model="website.title"
                  :style="{ color: website.primaryColor }"
                />
                <input
                  type="text"
                  class="card-input__input"
                  v-model="website.homePage.subheadingOne"
                  :style="{ color: website.secondaryColor }"
                />
                <VueEditor
                  class="center"
                  v-model="website.homePage.descriptionOne"
                  style="color: white"
                ></VueEditor>
              </div>
              <div class="col-md-6">
                <div class="row justify-content-center">
                  <img
                    v-if="!gif"
                    :src="website.homePage.photoOne"
                    class="card-img-top rounded-5"
                    style="max-width: 400px; max-height: 425px"
                  />
                  <img
                    id="mygif"
                    src="../../../../public/1495.gif"
                    class="animated-gif center"
                    v-show="this.gif"
                    style="max-width: 300px; max-height: 300px"
                  />
                </div>
                <div class="row justify-content-center">
                  <input
                    class="form-control v-step-0 mt-3"
                    type="file"
                    id="formFile"
                    style="max-width: 400px"
                    @change="uploadImage($event, 'photoOne')"
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center m-5">
              <div class="col-md-3">
                <div class="row justify-content-center">
                  <img
                    v-if="!gif"
                    :src="website.homePage.photoTwo"
                    class="card-img-top rounded-5"
                    style="max-width: 300px; max-height: 350px"
                  />
                  <img
                    id="mygif"
                    src="../../../../public/1495.gif"
                    class="animated-gif center"
                    v-show="this.gif"
                    style="max-width: 300px; max-height: 300px"
                  />
                </div>
                <div class="row justify-content-center">
                  <input
                    class="form-control v-step-0 mt-3"
                    type="file"
                    id="formFile"
                    style="max-width: 300px"
                    @change="uploadImage($event, 'photoTwo')"
                  />
                </div>
              </div>
              <div class="col-md-9">
                <input
                  type="text"
                  class="card-input__input"
                  v-model="website.homePage.subheadingTwo"
                  :style="{ color: this.website.primaryColor }"
                />
                <VueEditor
                  class="center"
                  v-model="website.homePage.descriptionTwo"
                  style="color: white"
                ></VueEditor>
              </div>
            </div>
            <div
              class="row justify-content-center mt-5 promo"
              :style="getMetricBannerCSS"
            ></div>
            <div class="row justify-content-center m-5">
              <div class="col-md-9">
                <input
                  type="text"
                  class="card-input__input"
                  v-model="website.homePage.subheadingThree"
                  :style="{ color: this.website.secondaryColor }"
                />
                <VueEditor
                  class="center"
                  v-model="website.homePage.descriptionThree"
                  style="color: white"
                ></VueEditor>
              </div>
              <div class="col-md-3">
                <div class="row justify-content-center">
                  <img
                    v-if="!gif"
                    :src="website.homePage.photoThree"
                    class="card-img-top rounded-5"
                    style="max-width: 300px; max-height: 350px"
                  />
                  <img
                    id="mygif"
                    src="../../../../public/1495.gif"
                    class="animated-gif center"
                    v-show="this.gif"
                    style="max-width: 300px; max-height: 300px"
                  />
                </div>
                <div class="row justify-content-center">
                  <input
                    class="form-control v-step-0 mt-3"
                    type="file"
                    id="formFile"
                    style="max-width: 300px"
                    @change="uploadImage($event, 'photoThree')"
                  />
                </div>
              </div>
            </div>
            <br />
          </div>
          <div
            class="tab-pane fade rounded-3"
            id="metrics"
            role="tabpanel"
            aria-labelledby="metrics-tab"
            :style="getWebsiteBackground"
          >
            <br />
            <div class="row justify-content-center m-5">
              <div class="col-md-6">
                <div class="row justify-content-center">
                  <img
                    v-if="!gif"
                    :src="website.metricsPage.photoOne"
                    class="card-img-top rounded-5"
                    style="max-width: 300px; max-height: 350px"
                  />
                  <img
                    id="mygif"
                    src="../../../../public/1495.gif"
                    class="animated-gif center"
                    v-show="this.gif"
                    style="max-width: 300px; max-height: 300px"
                  />
                </div>
                <div class="row justify-content-center">
                  <input
                    class="form-control v-step-0 mt-3"
                    type="file"
                    id="formFile"
                    style="max-width: 300px"
                    @change="uploadImage($event, 'metricsPhotoOne')"
                  />
                </div>
              </div>
              <div class="col-md-6 center text-center">
                <input
                  type="text"
                  class="card-input__input"
                  v-model="website.metricsPage.subheadingOne"
                  :style="{ color: website.primaryColor }"
                />
                <ul class="postcard__tagbox">
                  <li class="tag__item">
                    Primary Sport:
                    {{ user.recruitProfile.athleticInfo.primarySport }}
                  </li>
                  <li class="tag__item">
                    State: {{ user.recruitProfile.state }}
                  </li>
                  <li class="tag__item">
                    Height: {{ user.recruitProfile.athleticInfo.height }}
                  </li>
                  <li class="tag__item">
                    Weight: {{ user.recruitProfile.athleticInfo.weight }}lbs
                  </li>
                  <li
                    v-for="metric in user.recruitProfile.athleticInfo
                      .additionalMetrics"
                    :key="metric._id"
                    :class="{ tag__item: true }"
                  >
                    {{ metric.title }}: {{ metric.value }}
                  </li>
                  <!--          <li class="tag__item">Position: Quarterback</li>-->
                  <!--          <li class="tag__item">Graduating Class: 2027</li>-->
                  <!--          <div class="mt-3"></div>-->
                  <!--          <li class="tag__item">Touchdowns: 27</li>-->
                  <!--          <li class="tag__item">Interceptions: 4</li>-->
                  <!--          <li class="tag__item">Run-ins: 6</li>-->
                </ul>
                <br />
                <button
                  class="btn center text-center"
                  :style="{
                    color: website.secondaryColor,
                    'background-color': website.primaryColor,
                  }"
                >
                  Get in Touch
                </button>
              </div>
            </div>
            <br />
          </div>
          <div
            class="tab-pane fade rounded-3"
            id="highlights"
            role="tabpanel"
            aria-labelledby="highlights-tab"
            :style="getWebsiteBackground"
          >
            <div class="m-1 justify-content-center">
              <div
                class="row justify-content-center mt-5"
                style="max-height: 350px; min-height: 350px"
              >
                <div class="col-md-6" v-if="this.selectedPhoto.url">
                  <img
                    :src="this.selectedPhoto.url"
                    class="rounded-3 center text-center mt-5"
                    style="
                      max-width: 100%;
                      max-height: 350px;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  />
                </div>
                <div class="col-md-6" v-else>
                  <video
                    :src="this.selectedVideo.url"
                    controls
                    class="rounded-3 center text-center mt-5"
                    style="
                      max-width: 100%;
                      max-height: 350px;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  />
                </div>
              </div>
              <br />
              <div class="row justify-content-center mt-5">
                <div
                  class="col-2 mb-5"
                  v-for="(photo, index) in website.galleryPage.photos"
                  :key="photo._id"
                >
                  <button
                    class="btn btn-danger"
                    @click="removePhoto(index)"
                    title="Remove Metric"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                  <img
                    @click="selectPhoto(photo)"
                    :src="photo.url"
                    :alt="photo.description"
                    class="img-fluid rounded-3"
                    style="
                      max-height: 100px;
                      min-height: 100px;
                      max-width: 100px;
                      min-width: 100px;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      cursor: pointer;
                    "
                  />
                </div>
                <div
                  class="col-2 mb-5"
                  v-for="(video, index) in website.galleryPage.videos"
                  :key="video._id"
                >
                  <button
                    class="btn btn-danger"
                    @click="removeVideo(index)"
                    title="Remove Metric"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                  <video
                    @click="selectVideo(video)"
                    :src="video.url"
                    class="img-fluid rounded-3"
                    style="
                      max-height: 100px;
                      min-height: 100px;
                      max-width: 100px;
                      min-width: 100px;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      cursor: pointer;
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div
          class="row justify-content-center m-3"
          v-if="this.showHighlightButtons"
        >
          <div class="col-md-6 center text-center">
            <button
              class="card-form__button rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#uploadImages"
              style="color: white; background-color: #363166"
            >
              Add New Photos <i class="fa-solid fa-images"></i>
            </button>
          </div>
          <div class="col-md-6 center text-center">
            <button
              class="card-form__button rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#uploadImages"
              style="background-color: #363166"
            >
              Add New Videos <i class="fa-solid fa-video"></i>
            </button>
          </div>

          <UploadFilesModal
            :isForEdit="true"
            @uploadAdditionalFile="uploadGalleryFile"
          ></UploadFilesModal>
        </div>

        <div class="row justify-content-center m-3">
          <div class="col-md-6 center text-center">
            <button
              class="card-form__button rounded-pill"
              @click="saveChanges()"
            >
              Save Website Changes
            </button>
          </div>
        </div>
        <br />
        <br />
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import { VueEditor } from 'vue3-editor'
import swal from 'sweetalert'
import UploadFilesModal from '../UploadFilesModal.vue'

export default {
  components: {
    VueEditor,
    UploadFilesModal,
  },
  props: {
    website: Object,
    user: Object,
  },
  data() {
    return {
      gif: false,
      selectedPhoto: {},
      selectedVideo: {},
      showHighlightButtons: false,
    }
  },
  methods: {
    formatEditorText(text) {
      if (text.includes('class="ql-align-left"')) {
        text = text.replaceAll(
          'class="ql-align-left"',
          'style="text-align: left;"'
        )
      }
      if (text.includes('class="ql-align-center"')) {
        text = text.replaceAll(
          'class="ql-align-center"',
          'style="text-align: center;"'
        )
      }
      if (text.includes('class="ql-align-right"')) {
        text = text.replaceAll(
          'class="ql-align-right"',
          'style="text-align: right;"'
        )
      }
      return text
    },

    pColorExplanation() {
      swal({
        title: 'Primary Color',
        text: 'Here you will pick the primary color of your website. Our AI will make this be the primary color in the theme of the website.',
        icon: 'info',
      })
    },
    sColorExplanation() {
      swal({
        title: 'Secondary Color',
        text: 'Here you will pick the secondary color of your website. Our AI will make this be an accent color on your website.',
        icon: 'info',
      })
    },

    deleteWebsite() {
      this.clickedConfirm = true
      swal({
        title: 'Confirm Deletion',
        text: 'Once a website is deleted, it cannot be undone. After deleting, you will be able to create a new website.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let subdomain = this.website.subdomain
          console.log('here')
          let url = import.meta.env.VITE_APP_DB_URL + 'ai/deleteRecruitWebsite'
          axios
            .post(
              url,
              {
                id: this.user._id,
                subdomain: subdomain,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              console.log(response.data)
              swal({
                title: 'Success!',
                text: 'Website deleted successfully!',
                icon: 'success',
                button: 'Ok',
              })
              this.$router.push('/Recruit/Dashboard')
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.error, 'error')
              return
            })
        }
      })
    },
    uploadImage(event, photoPlacement) {
      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadAIWebsiteImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          if (photoPlacement === 'photoOne') {
            this.website.homePage.photoOne = response.data.location
          } else if (photoPlacement === 'photoTwo') {
            this.website.homePage.photoTwo = response.data.location
          } else if (photoPlacement === 'photoThree') {
            this.website.homePage.photoThree = response.data.location
          } else if (photoPlacement === 'metricsPhotoOne') {
            this.website.metricsPage.photoOne = response.data.location
          }
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },

    uploadGalleryFile(event) {
      this.gif = true
      const file = event.target.files[0]
      const fileType = file.type.split('/')[0]
      const url =
        fileType === 'video'
          ? import.meta.env.VITE_APP_DB_URL + 'util/uploadAIWebsiteVideo'
          : import.meta.env.VITE_APP_DB_URL + 'util/uploadAIWebsiteImage'
      let data = new FormData()
      data.append('name', file.name)
      data.append('file', file)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(url, data, config)
        .then(response => {
          console.log(response)
          this.gif = false

          //TODO Add the new gallery photo or video to the appropriate array
          if (fileType === 'video') {
            this.website.galleryPage.videos.push({
              url: response.data.location,
            })
          } else {
            this.website.galleryPage.photos.push({
              url: response.data.location,
            })
          }
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 50MB.',
            'error'
          )
        })
    },

    selectPhoto(photo) {
      this.selectedVideo = {}
      this.selectedPhoto = photo
    },

    selectVideo(video) {
      this.selectedPhoto = {}
      this.selectedVideo = video
    },

    removePhoto(index) {
      this.website.galleryPage.photos.splice(index, 1)
    },

    removeVideo(index) {
      this.website.galleryPage.videos.splice(index, 1)
    },

    saveChanges() {
      this.clickedConfirm = true
      swal({
        title: 'Confirm Details',
        text: 'Once the website has been updated it cannot be undone.  Are you sure you wish to save all current changes?',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          let url = import.meta.env.VITE_APP_DB_URL + 'website/updateWebsite'
          this.website.homePage.descriptionOne = this.formatEditorText(
            this.website.homePage.descriptionOne
          )
          this.website.homePage.descriptionTwo = this.formatEditorText(
            this.website.homePage.descriptionTwo
          )
          this.website.homePage.descriptionThree = this.formatEditorText(
            this.website.homePage.descriptionThree
          )
          axios
            .post(
              url,
              {
                recruitID: this.user._id,
                subdomain: this.subdomain,
                website: this.website,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              console.log(response.data)
              swal({
                title: 'Website Updated',
                text: 'Your website has been updated',
                icon: 'success',
                button: 'OK',
              })
            })
        }
      })
    },
  },

  created() {
    if (this.website) {
      if (this.website.galleryPage.photos) {
        this.selectedPhoto = this.website.galleryPage.photos[0]
      }
    }
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getWebsiteURL() {
      return 'https://' + this.website.subdomain + '.summerathletes.com'
    },

    getWebsiteBackground() {
      return `background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);`
    },

    getMetricBannerCSS() {
      return `background: linear-gradient(0.75turn, #000, ${
        this.website.primaryColor
      }); max-width: 100%; min-height: ${
        this.isMobile ? '350px' : '330px'
      }; max-height: ${
        this.isMobile ? '350px' : ''
      }; overflow-y: hidden; margin-top: ${
        this.isMobile ? '100px' : '200px'
      }; margin-left: 0px`
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.clickedConfirm) {
      swal({
        title: 'Leave without saving?',
        text: 'Do you want to leave without saving?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: 'Leave without saving',
        },
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
}
</script>

<style>
.centered-text {
  position: absolute;
  text-align: left;
  top: 12%;
  width: 100%;
}

.postcard__tagbox {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0;
  justify-content: center;

  .tag__item {
    color: white;
    display: inline-block;
    background: rgba(83, 83, 83, 0.4);
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    user-select: none;
    transition: background-color 0.3s;

    &:hover {
      background: rgba(83, 83, 83, 0.8);
    }
  }
}
</style>
