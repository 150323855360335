<template>
  <div class="wrapper container" id="checkIn">
    <section class="main container">
      <div class="card-form__inner mt-5">
        <div class="card-form__header">
          <div class="row">
            <div class="col-md-10">
              <h2 class="card-form__title text-center">
                {{ this.user.recruitProfile.firstName }}'s Athlete Profile
              </h2>
              <center>
                <a :href="getWebsiteURL" target="_blank">{{ getWebsiteURL }}</a>
              </center>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary btn-block" @click="saveChanges">
                Save Changes
              </button>

              <button
                class="btn btn-danger btn-block"
                style="margin-top: 5px"
                @click="deleteWebsite"
              >
                Delete Website
              </button>
            </div>
          </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              About Me
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <br />
            <div :class="{ 'w-25': !isMobile }">
              <center class="m-3">
                <img
                  v-if="this.headerLogo"
                  :src="this.headerLogo"
                  class="card-img-top rounded-3"
                  style="max-width: 100px; max-height: 150px"
                />
                <img
                  id="mygif"
                  src="../../../../public/1495.gif"
                  class="animated-gif center"
                  v-show="this.gif"
                />
              </center>
              <div>
                <label for="formFile" class="form-label"
                  >Edit Profile Photo</label
                >
                <input
                  class="form-control v-step-0"
                  type="file"
                  id="formFile"
                  @change="uploadImage($event, 'headerLogo')"
                />
              </div>
            </div>

            <br />
            <hr />
            <br />
            <div
              v-for="(homeParagraph, index) in homeParagraphs"
              :key="'homeParagraph-' + index"
            >
              <div v-if="index % 2 === 0">
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div v-if="index === 0">
                      <input
                        type="text"
                        id="homePageMainTitle"
                        class="card-input__input"
                        v-model="homePageMainTitle"
                      />
                      <br />
                    </div>
                    <input
                      type="text"
                      class="card-input__input"
                      v-model="homeTitles[index]"
                    />
                    <br />
                    <VueEditor
                      class="center"
                      v-model="homeParagraphs[index]"
                    ></VueEditor>
                  </div>
                  <div class="col-md-6">
                    <img
                      v-if="headerLogo"
                      :src="homePageImages[index]"
                      class="card-img-top rounded-3"
                      style="max-width: 500px; max-height: 400px"
                    />
                    <img
                      id="mygif"
                      src="../../../../public/1495.gif"
                      class="animated-gif center"
                      v-show="gif"
                    />
                    <input
                      class="form-control v-step-0 mt-3"
                      type="file"
                      id="formFile"
                      style="max-width: 500px; max-height: 400px"
                      @change="uploadImage($event, 'homeParagraph-' + index)"
                    />
                  </div>
                </div>
                <div v-if="index === 2">
                  <br />
                  <hr />
                  <br />
                  <div style="background-color: #eef1f4">
                    <input
                      type="text"
                      class="card-input__input"
                      v-model="homePageBreakTitle"
                    />
                    <br />
                    <VueEditor
                      class="center"
                      v-model="homePageBreakParagraph"
                    ></VueEditor>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <img
                      :src="homePageImages[index]"
                      class="card-img-top rounded-3"
                      style="max-width: 500px; max-height: 400px"
                    />
                    <img
                      id="mygif"
                      src="../../../../public/1495.gif"
                      class="animated-gif center"
                      v-show="gif"
                    />
                    <input
                      class="form-control v-step-0 mt-3"
                      type="file"
                      id="formFile"
                      style="max-width: 500px; max-height: 400px"
                      @change="uploadImage($event, 'homeParagraph-' + index)"
                    />
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="card-input__input"
                      v-model="homeTitles[index]"
                    />
                    <br />
                    <VueEditor
                      class="center"
                      v-model="homeParagraphs[index]"
                    ></VueEditor>
                  </div>
                </div>
              </div>

              <br />
              <hr />
            </div>

            <br />
            <br />
            <br />

            <div class="row justify-content-center">
              <div
                v-for="(homeCardParagraph, index) in homeCardParagraphs"
                :key="'homeCardParagraph' + index"
                class="col-md-3"
              >
                <input
                  type="text"
                  class="card-input__input"
                  v-model="homeCardTitles[index]"
                />
                <br />
                <VueEditor v-model="homeCardParagraphs[index]"></VueEditor>
              </div>
            </div>

            <br />
            <hr />

            <br />
          </div>
          <div
            class="tab-pane fade"
            id="about"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <br />
            <input
              type="text"
              class="card-input__input text-center"
              v-model="aboutMainTitle"
            />
            <br />
            <br />
            <div class="row justify-content-center">
              <img
                v-if="!gif"
                :src="aboutImage"
                class="card-img-top rounded-3"
                style="max-width: 500px; max-height: 400px"
              />
              <img
                id="mygif"
                src="../../../../public/1495.gif"
                class="animated-gif center"
                v-show="this.gif"
                style="max-width: 300px; max-height: 300px"
              />
            </div>
            <div class="row justify-content-center">
              <input
                class="form-control v-step-0 mt-3"
                type="file"
                id="formFile"
                style="max-width: 500px; max-height: 400px"
                @change="uploadImage($event, 'aboutImage')"
              />
            </div>

            <br />
            <div
              v-for="(aboutParagraph, index) in aboutParagraphs"
              :key="aboutParagraph.id"
            >
              <VueEditor
                class="center"
                v-model="aboutParagraphs[index]"
              ></VueEditor>
              <br />
              <hr />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import { VueEditor } from 'vue3-editor'
import swal from 'sweetalert'

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      user: {},
      website: {},
      homePageMainTitle: '',
      homePageBreakTitle: '',
      homePageBreakParagraph: '',
      homeTitles: [],
      homeParagraphs: [],
      homeCardTitles: [],
      homeCardParagraphs: [],
      aboutMainTitle: '',
      aboutParagraphs: [],
      subdomain: '',
      headerLogo: '',
      footerLogo: '',
      homePageImages: [],
      aboutImage: '',
      gif: false,
      clickedConfirm: false,
    }
  },
  methods: {
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    deleteWebsite() {
      this.clickedConfirm = true
      swal({
        title: 'Confirm Deletion',
        text: 'Once a website is deleted, it cannot be undone. After deleting, you will be able to create a new website.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willDelete => {
        if (willDelete) {
          let subdomain = this.subdomain
          console.log('here')
          let url = import.meta.env.VITE_APP_DB_URL + 'ai/deleteRecruitWebsite'
          axios
            .post(
              url,
              {
                id: this.user._id,
                subdomain: subdomain,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              console.log(response.data)
              swal({
                title: 'Success!',
                text: 'Website deleted successfully!',
                icon: 'success',
                button: 'Ok',
              })
              this.$router.push('/Recruit/Dashboard')
            })
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.error, 'error')
              return
            })
        }
      })
    },
    uploadImage(event, type) {
      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadAIWebsiteImage'
      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .put(URL, data, config)
        .then(response => {
          if (type === 'headerLogo') {
            this.headerLogo = response.data.location
          } else if (type === 'footerLogo') {
            this.footerLogo = response.data.location
          } else if (type === 'aboutImage') {
            this.aboutImage = response.data.location
          } else {
            let index = type.split('-')[1]
            this.homePageImages[index] = response.data.location
          }
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },
    getWebsite() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'website/getWebsiteForEdit?recruitID=' +
        this.user._id +
        '&subdomain=' +
        this.subdomain
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.website = response.data.website
          this.homePageMainTitle = response.data.homePageMainTitle
          this.homePageBreakTitle = response.data.homePageBreakTitle
          this.homePageBreakParagraph = response.data.homePageBreakParagraph
          this.homeTitles = response.data.homeTitles
          this.homeParagraphs = response.data.homeParagraphs
          this.homeCardTitles = response.data.homeCardTitles
          this.homeCardParagraphs = response.data.homeCardParagraphs
          this.aboutMainTitle = response.data.aboutMainTitle
          this.aboutParagraphs = response.data.aboutParagraphs
          this.headerLogo = response.data.headerLogo
          this.footerLogo = response.data.footerLogo
          this.homePageImages = response.data.homePageImages
          this.aboutImage = response.data.aboutImage
        })
    },
    saveChanges() {
      this.clickedConfirm = true
      swal({
        title: 'Confirm Details',
        text: 'Once the website has been updated it cannot be undone.  Are you sure you wish to save all current changes?',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          let url = import.meta.env.VITE_APP_DB_URL + 'website/updateWebsite'
          axios
            .post(
              url,
              {
                homePageMainTitle: this.homePageMainTitle,
                homePageBreakTitle: this.homePageBreakTitle,
                homePageBreakParagraph: this.homePageBreakParagraph,
                homeTitles: this.homeTitles,
                homeParagraphs: this.homeParagraphs,
                homeCardTitles: this.homeCardTitles,
                homeCardParagraphs: this.homeCardParagraphs,
                aboutMainTitle: this.aboutMainTitle,
                aboutParagraphs: this.aboutParagraphs,
                headerLogo: this.headerLogo,
                footerLogo: this.footerLogo,
                homePageImages: this.homePageImages,
                aboutImage: this.aboutImage,
                recruitID: this.user._id,
                subdomain: this.subdomain,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .then(response => {
              console.log(response.data)
              swal({
                title: 'Website Updated',
                text: 'Your website has been updated',
                icon: 'success',
                button: 'OK',
              })
            })
        }
      })
    },
  },

  created() {
    this.getUserDetails()
    this.subdomain = this.user.aiWebsites[0].subdomain
    this.getWebsite()
  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getWebsiteURL() {
      return 'https://' + this.website.subdomain + '.summerathletes.com'
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.clickedConfirm) {
      swal({
        title: 'Leave without saving?',
        text: 'Do you want to leave without saving?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          confirm: 'Leave without saving',
        },
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
}
</script>
<style scoped>
.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.07);
}
</style>
