<template>
  <div id="paypal-button-container" :style-object="style"></div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
//import Vue from "vue";
import swal from 'sweetalert'
// import axios from "axios";

export default {
  components: {},
  props: {
    campId: String,
    camperInfo: Object,
    emergencyContactInfo: Object,
    customFieldValues: Array,
    billingInfo: Object,
    addOns: Array,
    discountCode: String,
    fullPrice: String,
    paymentType: String,
    dates: String,
    additionalCampers: Array,
    multipleCamperPrices: Array,
    recruitPurchase: Boolean,
    hideRecruitAds: Boolean,
  },
  data() {
    return {
      paypalRequestId: '',
    }
  },

  methods: {},
  computed: {
    style: function () {
      return {
        shape: 'pill',
        color: 'blue',
        layout: 'horizontal',
        label: 'paypal',
        tagline: false,
      }
    },
  },
  async mounted() {
    // let url = import.meta.env.VITE_APP_DB_URL + "paypal/createOrder";
    // Order is created on the server and the order id is returned
    // await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((response) => console.log(response.body));
    const paypalSdk = await loadScript({
      clientId: import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
      currency: 'USD',
      components: 'buttons',
      enableFunding: 'venmo,paylater',
    })
    let fundingSource = ''
    switch (this.paymentType) {
      case 'paypal':
        fundingSource = paypalSdk.FUNDING.PAYPAL
        break
      case 'paylater':
        fundingSource = paypalSdk.FUNDING.PAYLATER
        break
      case 'venmo':
        fundingSource = paypalSdk.FUNDING.VENMO
        break
      default:
        fundingSource = paypalSdk.FUNDING.PAYPAL
        break
    }
    console.log(fundingSource)
    await paypalSdk
      .Buttons({
        fundingSource: fundingSource,
        style: {
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'buynow',
          tagline: false,
        },
        createOrder: async () => {
          try {
            let url = import.meta.env.VITE_APP_DB_URL + 'paypal/createOrder'
            const response = await fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                fullPrice: this.fullPrice,
              }),
            })
            const details = await response.json()
            console.log(details.id)
            this.paypalRequestId = details.id
            return details.id
          } catch (error) {
            console.error(error)
            // Handle the error or display an appropriate error message to the user
          }
        },
        onApprove: async (data, actions) => {
          try {
            console.log(this.paypalRequestId)
            let url = import.meta.env.VITE_APP_DB_URL + 'paypal/approveOrder'
            const response = await fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                orderID: this.paypalRequestId,
                transactionType: 'camp',
                chargeData: {
                  campId: this.campId,
                  camperInfo: this.camperInfo,
                  emergencyContactInfo: this.emergencyContactInfo,
                  customFieldValues: this.customFieldValues,
                  billingInfo: this.billingInfo,
                  addOns: this.addOns,
                  discountCode: this.discountCode,
                  paymentType: this.paymentType,
                  fullPrice: this.fullPrice,
                  dates: this.dates,
                  additionalCampers: this.additionalCampers,
                  recruitPurchase: this.recruitPurchase,
                },
              }),
            })

            const details = await response.json()
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you message

            // This example reads a v2/checkout/orders capture response, propagated from the server
            // You could use a different API or structure for your 'orderData'
            const errorDetail =
              Array.isArray(details.details) && details.details[0]

            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
              return actions.restart()
              // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            }

            if (errorDetail) {
              let msg = 'Sorry, your transaction could not be processed.'
              msg += errorDetail.description
                ? ' ' + errorDetail.description
                : ''
              msg += details.debug_id ? ' (' + details.debug_id + ')' : ''
              alert(msg)
            }

            if (details.status === 500 || response.status === 500) {
              swal({
                title: 'Error',
                text: details.error,
                icon: 'error',
              })
            } else {
              if (this.hideRecruitAds) {
                swal('Success', 'Registration Successful', 'success')
                this.$router.push('/Camps/Success')
              } else {
                if (!this.recruitPurchase) {
                  swal('Success', 'Registration Successful', 'success')
                }
                this.$emit('checkedOut')
              }
            }

            // Successful capture! For demo purposes:

            // console.log(
            //   "Capture result",
            //   details,
            //   JSON.stringify(details, null, 2)
            // );
            // const transaction = details.purchase_units[0].payments.captures[0];
            // alert(
            //   "Transaction " +
            //     transaction.status +
            //     ": " +
            //     transaction.id +
            //     "See console for all available details"
            // );
          } catch (error) {
            console.error(error)
            // Handle the error or display an appropriate error message to the user
          }
        },
      })
      .render('#paypal-button-container')
  },
}
</script>
