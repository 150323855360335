<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section
      class="container justify-content-center"
      v-bind:style="{ marginLeft: isMobile() ? '21px' : '' }"
    >
      <ViewScheduledSession
        :currentScheduledSession="currentScheduledSession"
        :fromBookedSessions="true"
      >
      </ViewScheduledSession>
      <ViewScheduledSessionsOnDay
        :allEventsOnThisDay="allEventsOnThisDay"
        :dateOnThisDay="dateOnThisDay"
      ></ViewScheduledSessionsOnDay>
      <button
        data-bs-toggle="modal"
        data-bs-target="#viewScheduledSession"
        style="display: none"
        id="openScheduledSession"
      ></button>
      <button
        data-bs-toggle="modal"
        data-bs-target="#viewScheduledSessionsOnDay"
        style="display: none"
        id="openScheduledSessionsOnDay"
      ></button>
      <div class="container mt-5 justify-content-center">
        <div class="card card-form__inner">
          <div class="text-end">
            <button
              class="btn"
              @click="goToMySchedule()"
              style="background-color: #e6e6fa; color: gray"
            >
              View My Schedule
            </button>
          </div>
          <h1 class="card-title text-center">All Booked Sessions</h1>
          <br />
          <br />
          <div class="card-body justify-content-center center text-center">
            <!--            <full-calendar-->
            <!--              :events="fcEvents"-->
            <!--              locale="en"-->
            <!--              @dayClick="logDate"-->
            <!--              @eventClick="logEvent"-->
            <!--            ></full-calendar>-->
            <FullCalendar
              :options="calendarOptions"
              locale="en"
              @dayClick="logDate"
              @eventClick="logEvent"
            ></FullCalendar>
          </div>
          <br />
          <br />
          <div class="row justify-content-center">
            <div class="col-md-4 text-end">
              <label class="mt-2">Filter by Sessions: </label>
            </div>
            <div class="col-md-5 text-start">
              <multiselect
                v-model="sessionSupportedEventsViewing"
                placeholder="Events"
                label="name"
                track-by="code"
                :options="getSessionOptions"
                :multiple="true"
                @input="filterFcEvents"
              ></multiselect>
            </div>
            <div class="col-md-3 text-end"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import axios from "axios";
import ClientLayout from '../../components/ClientLayout.vue'
import ViewScheduledSession from '../../components/Scheduling/ViewScheduledSession.vue'
import ViewScheduledSessionsOnDay from '../../components/Scheduling/ViewScheduledSessionsOnDay.vue'
import axios from 'axios'
import swal from 'sweetalert'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

// import swal from "sweetalert";

export default {
  components: {
    FullCalendar,
    Multiselect,
    ClientLayout,
    ViewScheduledSession,
    ViewScheduledSessionsOnDay,
  },
  data() {
    return {
      user: {},
      calendarID: '',
      currentScheduledSession: {
        title: '',
        start: '',
        end: '',
        camperName: '',
        campName: '',
        campID: '',
        isCamp: false,
        camperLength: 0,
      },
      allAvailabilities: [],
      sessionSupportedEvents: [],
      sessionSupportedEventsViewing: [],
      calendars: [],
      selectedCalendarIndex: 0,
      fcEvents: [],
      scheduledSessions: [],
      allEventsOnThisDay: [],
      dateOnThisDay: '',
      campsWithDates: [],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },

    goToMySchedule() {
      this.$router.push('/Dashboard/Calendar/MySchedule')
    },

    addFormattedSession(scheduledSession) {
      let startTime = scheduledSession.time.split(' - ')[0].split(':')[0]
      let endTime = scheduledSession.time.split(' - ')[1].split(':')[0]
      let startam = true
      let endam = true

      if (Number(startTime) > 12) {
        startTime = Number(startTime) - 12
        startam = false
      } else {
        startTime = Number(startTime)
      }
      if (Number(endTime) > 12) {
        endTime = Number(endTime) - 12
        endam = false
      } else {
        endTime = Number(endTime)
      }

      const finalStartTime =
        startTime + ':' + scheduledSession.time.split(' - ')[0].split(':')[1]
      const finalEndTime =
        endTime + ':' + scheduledSession.time.split(' - ')[1].split(':')[1]

      const startTag = startam ? 'am' : 'pm'
      const endTag = endam ? 'am' : 'pm'

      this.fcEvents.push({
        title: finalStartTime + startTag + ' - ' + finalEndTime + endTag,
        start: moment(new Date(scheduledSession.date))
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        end: moment(new Date(scheduledSession.date))
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        id: `{"title": "${
          finalStartTime + startTag + ' - ' + finalEndTime + endTag
        }", "camperName": "${scheduledSession.camperName}", "campName": "${
          scheduledSession.campName
        }", "camperID": "${scheduledSession.camperID}", "campID": "${
          scheduledSession.campID
        }", "start": "${moment(new Date(scheduledSession.date)).add(
          1,
          'days'
        )}", "end": "${moment(new Date(scheduledSession.date)).add(
          1,
          'days'
        )}"}`,
        camperName: scheduledSession.camperName,
        campName: scheduledSession.campName,
        camperID: scheduledSession.camperID,
        campID: scheduledSession.campID,
        isCamp: false,
      })
    },

    addScheduledSessionsToCalendar() {
      this.fcEvents = []
      this.addCampsWithDatesToCalendar()
      let allScheduledSessions = [...this.scheduledSessions]
      allScheduledSessions = allScheduledSessions.sort((a, b) => {
        return (
          Number(a.time.split(' - ')[0].split(':')[0]) -
          Number(b.time.split(' - ')[0].split(':')[0])
        )
      })
      allScheduledSessions = allScheduledSessions.sort((a, b) => {
        return b.date - a.date
      })
      this.scheduledSessions = allScheduledSessions
      for (const scheduledSession of allScheduledSessions) {
        this.addFormattedSession(scheduledSession)
      }
    },

    addCampsWithDatesToCalendar() {
      let campsWithDates = [...this.campsWithDates]
      campsWithDates = campsWithDates.sort((a, b) => {
        return Number(a.campDates[0].start) - Number(b.campDates[0].start)
      })

      for (const camp of campsWithDates) {
        console.log(camp)
        this.fcEvents.push({
          title: camp.campName,
          start: moment(new Date(camp.campDates[0].start)).format('YYYY-MM-DD'),
          end: moment(
            new Date(camp.campDates[camp.campDates.length - 1].end)
          ).format('YYYY-MM-DD'),
          id: `{"campName": "${camp.campName}", "campID": "${
            camp._id
          }", "isCamp": "true", "camperLength": "${
            camp.campers.length
          }", "start": "${moment(
            new Date(camp.campDates[0].start)
          )}", "end": "${moment(new Date(camp.campDates[0].start))}"}`,
          campName: camp.campName,
          campID: camp._id,
          isCamp: true,
          camperLength: camp.campers.length,
        })
      }
    },

    filterFcEvents(selectedOptions) {
      console.log(selectedOptions)

      this.fcEvents = []
      this.addCampsWithDatesToCalendar()
      let allScheduledSessions = [...this.scheduledSessions]
      if (selectedOptions.length > 0) {
        allScheduledSessions = allScheduledSessions.filter(a => {
          for (const selectedOption of selectedOptions) {
            if (a.campName === selectedOption.name) {
              return true
            }
          }
          return false
        })
      }

      allScheduledSessions = allScheduledSessions.sort((a, b) => {
        return (
          Number(a.time.split(' - ')[0].split(':')[0]) -
          Number(b.time.split(' - ')[0].split(':')[0])
        )
      })
      allScheduledSessions = allScheduledSessions.sort((a, b) => {
        return b.date - a.date
      })
      // this.scheduledSessions = allScheduledSessions;
      for (const scheduledSession of allScheduledSessions) {
        this.addFormattedSession(scheduledSession)
      }
    },
    getUserCalendars() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'scheduling/getUserCalendars?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.calendars = response.data.calendars
          this.calendarID = response.data.calendars[0]._id
        })
        .catch(error => {
          console.log(error)
        })
    },

    logDate(day) {
      console.log(day)
      this.allEventsOnThisDay = this.fcEvents.filter(event => {
        return event.start === day.startStr
      })
      this.dateOnThisDay = new Date(day.start).toLocaleDateString()
      document.getElementById('openScheduledSessionsOnDay').click()
    },

    async logEvent(event) {
      console.log(event)
      let eventDetails = JSON.parse(event.event.id)
      console.log(eventDetails)
      this.currentScheduledSession = JSON.parse(event.event.id)

      document.getElementById('openScheduledSession').click()
    },

    addedCalendar(allCalendars) {
      this.calendars = allCalendars.calendars
      document.getElementById('addCalendarClose').click()
    },

    getAllScheduledSessions() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'scheduling/getAllScheduledSessions?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.scheduledSessions = response.data.scheduledSessions
          this.addScheduledSessionsToCalendar()
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    addCalendar() {
      document.getElementById('openAddCalendar').click()
    },

    getAllCampsWithDates() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getAllCampsWithDates?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.campsWithDates = response.data.campsWithDates
          this.addCampsWithDatesToCalendar()
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    getAllSessionSupportedEvents() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'individualCamp/getAllSessionSupportedEvents?userID=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.sessionSupportedEvents = response.data.sessionSupportedEvents
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
  },

  created() {
    this.getUserDetails()
    this.getUserCalendars()
    // this.getAllCampsWithDates();
    this.getAllScheduledSessions()
    this.getAllSessionSupportedEvents()
  },

  computed: {
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        weekends: true,
        selectable: true,
        select: this.logDate,
        eventClick: this.logEvent,
        events: this.fcEvents,
        eventColor: '#4B4A85',
        eventMouseEnter: function (info) {
          info.el.style.cursor = 'pointer'
          info.el.style.backgroundColor = '#6A68A9' // Lighter shade of blue
        },
        eventMouseLeave: function (info) {
          info.el.style.backgroundColor = '#4B4A85' // Revert to original color
        },
        dayCellDidMount: function (info) {
          info.el.style.cursor = 'pointer'
          info.el.addEventListener('mouseenter', function () {
            info.el.style.backgroundColor = '#E6E6FA' // Pretty color on hover
          })
          info.el.addEventListener('mouseleave', function () {
            info.el.style.backgroundColor = '' // Revert to original color
          })
        },
      }
    },
    getSessionOptions() {
      let options = []
      for (const event of this.sessionSupportedEvents) {
        options.push({
          name: event.campName,
          code: event.campStripeId,
        })
      }
      return options
    },
  },
  mounted() {
    // let days = document.getElementsByClassName('events-day')
    // for (let day of days) {
    //   day.style.minHeight = '99px'
    // }
    //
    // let title = document.getElementsByClassName('title')[0]
    // title.className = 'title h5'
    //
    // let previousArrow = document.getElementsByClassName('prev-month')[0]
    // let nextArrow = document.getElementsByClassName('next-month')[0]
    // previousArrow.textContent = ''
    // nextArrow.textContent = ''
    // let backArrow = document.createElement('i')
    // backArrow.className = 'fa fa-solid fa-arrow-circle-o-left fa-xl'
    // backArrow.style.color = '#363166'
    // let forwardArrow = document.createElement('i')
    // forwardArrow.className = 'fa fa-solid fa-arrow-circle-o-right fa-xl'
    // forwardArrow.style.color = '#363166'
    // previousArrow.appendChild(backArrow)
    // nextArrow.appendChild(forwardArrow)
  },
}
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.currencyinput {
  border: 1px inset #ccc;
}
.currencyinput input {
  border: 0;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.newGradient {
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #32bd28 0%,
    #45487a 50%,
    #45487a 100%
  );
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
