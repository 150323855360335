<template>
  <div class="wrapper" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div class="card-form">
          <div class="card-input">
            <label for="email" class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              placeholder="Email"
              v-model="coach.email"
            />
          </div>
        </div>
        <button
          class="card-form__button gradient-button"
          @click="this.resetPassword"
        >
          Reset Password
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
export default {
  data() {
    return {
      coach: {
        email: '',
        currentURL: import.meta.env.VITE_APP_CLIENT_URL,
      },
    }
  },
  methods: {
    async resetPassword() {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/forgotPassword'
      if (this.validateInfo()) {
        axios
          .post(url, this.coach)
          .then(response => {
            let success = response.data.success
            if (success) {
              swal(
                'Success',
                'Reset Code Sent Successfully. Please check your texts and/or email for the reset code.',
                'success'
              )
              this.$router.push('/resetPassword')
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message)
            let error = err.response
            swal('Error', error.data.errorMessage, 'error')
          })
      }
    },
    validateInfo() {
      if (this.coach.email == '') {
        swal('Error', 'Please enter email', 'error')
        return false
      }
      return true
    },
  },
}
</script>
