<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section class="container justify-content-center">
      <button
        class="btn"
        data-bs-toggle="modal"
        data-bs-target="#uploadImages"
        id="openCampaignInput"
        style="color: white; background-color: #363166; display: none"
      ></button>
      <!--              <i-->
      <!--                class="fa-solid fa-circle-info fa-lg"-->
      <!--                style="color: #45487a; cursor: pointer; margin-left: 12px"-->
      <!--                @click="additionalImagesExplanation"-->
      <!--              ></i>-->
      <br /><br />
      <div
        class="modal fade"
        id="uploadImages"
        tabindex="-1"
        aria-labelledby="uploadImagesLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-center" id="uploadImagesLabel">
                Campaign Details
              </h5>
            </div>
            <div class="modal-body">
              <label
                class="form-label"
                for="creativeDropdown"
                style="margin-top: 5px"
                >Ad Creative</label
              >
              <select
                class="card-input__input"
                id="creativeDropdown"
                v-model="campaignDetails.creative"
              >
                <option
                  v-for="creative in adCreatives"
                  :key="creative.id"
                  :value="creative.mediaURL"
                >
                  {{ creative.title }}
                </option>
              </select>
              <br />
              <label
                class="form-label"
                for="creativeDropdown"
                style="margin-top: 5px"
                >Website Target</label
              >
              <select
                class="card-input__input"
                id="creativeDropdown"
                v-model="campaignDetails.website"
              >
                <option
                  v-for="website in websites"
                  :key="website.id"
                  :value="website.url"
                >
                  {{ website.title }}
                </option>
              </select>
              <br />
              <label
                class="form-label"
                for="bankDetailsForm"
                style="margin-top: 5px"
                >Caption</label
              >
              <textarea
                v-model="campaignDetails.caption"
                required
                id="form6Example1"
                class="form-control"
                type="text"
              />
              <br />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                id="websiteSettingsClose"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                id="websiteSettingsClose"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="launchCampaign"
              >
                Launch Campaign
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 justify-content-center">
        <div class="row justify-content-center">
          <div class="col-md-5 col-sm-5">
            <div class="card-form v-step-1" style="margin-bottom: 15px">
              <div
                class="card-form__inner"
                v-bind:style="{
                  width: isMobile() ? '99%' : '',
                  marginLeft: isMobile() ? '21px' : '',
                  textAlign: isMobile() ? 'center' : '',
                }"
              >
                <div class="card-body">
                  <h3 class="card-title text-center">
                    <b>Ad credits</b
                    ><i
                      class="fa-solid fa-circle-info fa-sm"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="adCreditExplanation"
                    ></i>
                  </h3>

                  <hr />
                  <!-- <img
                    src="../../../../public/SaCoin.png"
                    style="float: left"
                    height="29"
                  /> -->
                  <h4 style="float: left">{{ getCredits }}</h4>
                  <img src="../../../../public/SaCoin.png" height="29" />
                  <button
                    class="btn rounded-pill"
                    style="
                      background-color: #363166;
                      color: white;
                      float: right;
                    "
                    @click="purchaseCredits()"
                  >
                    Purchase Credits
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5 col-sm-5 justify-content-center">
            <div class="card-form v-step-0" style="margin-bottom: 15px">
              <div
                class="card-form__inner"
                v-bind:style="{
                  width: isMobile() ? '99%' : '',
                  marginLeft: isMobile() ? '21px' : '',
                  textAlign: isMobile() ? 'center' : '',
                }"
              >
                <div>
                  <h3 class="card-title">
                    <b>My Inventory </b
                    ><i
                      class="fa-solid fa-circle-info fa-sm"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="inventoryExplanation"
                    ></i>
                    <button
                      class="btn rounded-pill"
                      style="
                        background-color: #363166;
                        color: white;
                        float: right;
                        margin-top: 0;
                      "
                      @click="toAdStore()"
                    >
                      Ad Store
                    </button>
                  </h3>

                  <br />
                  <h4 class="card-text">
                    {{ getTotalMetaCampaigns }} Meta Campaigns
                    <i
                      class="fa-solid fa-circle-info fa-sm"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="metaExplanation"
                    ></i>
                  </h4>

                  <hr />
                  <h4 class="card-text">
                    {{ getTotalGoogleCampaigns }} Google Campaigns
                    <i
                      class="fa-solid fa-circle-info fa-sm"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="googleExplanation"
                    ></i>
                  </h4>

                  <hr />
                  <h4 class="card-text">
                    {{ getTotalTwitterCampaigns }} X Campaigns
                    <i
                      class="fa-solid fa-circle-info fa-sm"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="xExplanation"
                    ></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center v-step-3" style="margin-top: 35px">
        <b
          ><h1
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
              textAlign: 'center',
            }"
          >
            My Ad Campaigns
          </h1></b
        >
        <div
          class="col-md-9"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            marginLeft: isMobile() ? '21px' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            style="margin-bottom: 10px; min-width: 80%"
            v-model="searchQuery"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
            }"
          />
        </div>

        <div class="col-md-3 col-sm-6">
          <multiselect
            v-model="searchValues"
            placeholder="Status"
            label="name"
            track-by="code"
            :options="options"
            :multiple="true"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
            }"
          ></multiselect>
        </div>
        <div class="row text-center v-step-3" style="margin-top: 35px">
          <div
            class="col-md-4 col-sm-6"
            v-bind:style="{
              width: isMobile() ? '99%' : '',
              marginLeft: isMobile() ? '21px' : '',
              textAlign: isMobile() ? 'center' : '',
            }"
          >
            <ul
              class="list-group"
              v-for="(pack, index) in resultQuery"
              :key="pack._id"
            >
              <AdInventoryCard
                class="grow"
                v-if="index % 3 == 0"
                :id="pack._id"
                :price="pack.price"
                :description="pack.description"
                :image="pack.logo"
                :title="pack.title"
                :status="pack.status"
                @launchCampaign="openModal"
                @viewResults="viewResults"
              >
              </AdInventoryCard>
            </ul>
          </div>
          <div
            class="col-md-4 col-sm-6"
            v-bind:style="{
              width: isMobile() ? '99%' : '',
              marginLeft: isMobile() ? '21px' : '',
              textAlign: isMobile() ? 'center' : '',
            }"
          >
            <ul
              class="list-group"
              v-for="(pack, index) in resultQuery"
              :key="pack._id"
            >
              <AdInventoryCard
                class="grow"
                v-if="index % 3 == 1"
                :id="pack._id"
                :price="pack.price"
                :description="pack.description"
                :image="pack.logo"
                :title="pack.title"
                :tokens="pack.tokens"
                :status="pack.status"
                @launchCampaign="openModal"
                @viewResults="viewResults"
              >
              </AdInventoryCard>
            </ul>
          </div>
          <div
            class="col-md-4 col-sm-6"
            v-bind:style="{
              width: isMobile() ? '99%' : '',
              marginLeft: isMobile() ? '21px' : '',
              textAlign: isMobile() ? 'center' : '',
            }"
          >
            <ul
              class="list-group"
              v-for="(pack, index) in resultQuery"
              :key="pack._id"
            >
              <AdInventoryCard
                class="grow"
                v-if="index % 3 == 2"
                :id="pack._id"
                :description="pack.description"
                :status="pack.status"
                :image="pack.logo"
                :title="pack.title"
                @launchCampaign="openModal"
                @viewResults="viewResults"
              >
              </AdInventoryCard>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'
import AdInventoryCard from '../../components/Advertisements/AdInventoryCard.vue'
import ClientLayout from '../../components/ClientLayout.vue'
import Multiselect from 'vue-multiselect'
import swal from 'sweetalert'
export default {
  components: {
    ClientLayout,
    Multiselect,
    AdInventoryCard,
  },
  data() {
    return {
      user: {},
      credits: null,
      adCampaigns: [],
      searchValues: [],
      adCreatives: [],
      websites: [],
      adCampaignId: '',
      campaignDetails: {
        creative: '',
      },
      options: [
        { name: 'Available', code: 'Available' },
        { name: 'Launched', code: 'Launched' },
      ],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem('user'))
    },
    purchaseCredits() {
      this.$router.push('/Dashboard/CreditStore')
    },
    toAdStore() {
      this.$router.push('/Dashboard/AdStore')
    },
    async getAllWebsites() {
      let url =
        import.meta.env.VITE_APP_DB_URL +
        'ai/getAllWebsites?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log('here')
          console.log(response.data)
          this.websites = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getAllCampaigns() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'advertisment/getAllCampaigns?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.adCampaigns = response.data
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    async getAdCredits() {
      const url =
        import.meta.env.VITE_APP_DB_URL +
        'advertisment/getUserCreditBalance?id=' +
        this.user._id
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          this.credits = response.data.creditBalance
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    openModal(id) {
      this.adCampaignId = id
      console.log(id)
      document.getElementById('openCampaignInput').click()
    },
    async getAdCreatives() {
      try {
        const url =
          import.meta.env.VITE_APP_DB_URL + 'advertisment/getAllAdCreatives'
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        this.adCreatives = response.data.adCreatives
      } catch (err) {
        swal('Error', 'Failed to fetch ad creatives', 'error')
      }
    },
    async launchCampaign() {
      console.log('launching')
      const url =
        import.meta.env.VITE_APP_DB_URL + 'advertisment/launchCampaign/'
      axios
        .post(
          url,
          {
            adPackageId: this.adCampaignId,
            creativeUrl: this.campaignDetails.creative,
            website: this.campaignDetails.website,
            caption: this.campaignDetails.caption,
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(() => {
          swal('Success', 'Campaign Launched', 'success')
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    adCreditExplanation() {
      swal({
        title: 'Ad Credits',
        text: "These are your ad credits. You can purchase different ad campaigns in the Ad Store with credits. You can purchase more credits from your existing account balance using the 'Purchase Credits' button below. ",
        icon: 'info',
      })
    },
    inventoryExplanation() {
      swal({
        title: 'My Inventory',
        text: "This is your inventory of all of the ad campaigns that you have purchased. After puchasing an ad campaign, you will be able to launch the campaign at any time. To view options and purchase campaigns, click on the 'Ad Store' button on the right.",
        icon: 'info',
      })
    },
    metaExplanation() {
      swal({
        title: 'Meta Campaigns',
        text: 'This is number  of Meta Campaigns that you have purchased. Each campaign will populate on both facebook and instagram. These are generally the most efficent campaigns at driving an increase in sales.',
        icon: 'info',
      })
    },
    googleExplanation() {
      swal({
        title: 'Google Campaigns',
        text: 'This is number  of Google Campaigns that you have purchased. These campaigns are great for driving awareness and making sure your website shows up high when a someone searches relevant search terms.',
        icon: 'info',
      })
    },
    xExplanation() {
      swal({
        title: 'X (Twitter) Campaigns',
        text: 'This is number of X (formerly Twitter) Campaigns that you have purchased. These campaigns are great for driving awareness and perform very well for camps geared towards high school athletes focused on playing college athletics.',
        icon: 'info',
      })
    },
  },

  created() {
    this.getUserDetails()
    this.isMobile()
    this.getAdCredits()
    this.getAllCampaigns()
    this.getAdCreatives()
    this.getAllWebsites()
  },

  mounted() {},

  computed: {
    getTotalMetaCampaigns() {
      let sum = 0
      for (let count = 0; count < this.adCampaigns.length; count++) {
        if (this.adCampaigns[count].platform == 'meta') {
          sum = sum + 1
        }
      }
      return sum
    },
    getTotalGoogleCampaigns() {
      let sum = 0
      for (let count = 0; count < this.adCampaigns.length; count++) {
        if (this.adCampaigns[count].platform == 'google') {
          sum = sum + 1
        }
      }
      return sum
    },
    getTotalTwitterCampaigns() {
      let sum = 0
      for (let count = 0; count < this.adCampaigns.length; count++) {
        if (this.adCampaigns[count].platform == 'twitter') {
          sum = sum + 1
        }
      }
      return sum
    },
    getCredits() {
      return this.credits
    },
    resultQuery() {
      if (this.searchValues.length == 0) {
        if (this.searchQuery) {
          return this.adCampaigns.filter(item => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        } else {
          return this.adCampaigns
        }
      } else {
        let temp = []
        let values = this.searchValues
        for (let x = 0; x < this.searchValues.length; x++) {
          temp.push(
            ...this.adCampaigns.filter(function (y) {
              return y.status == values[x].code
            })
          )
        }
        //return temp;
        if (this.searchQuery) {
          return temp.filter(item => {
            return this.searchQuery
              .toLowerCase()
              .split(' ')
              .every(v => item.name.toLowerCase().includes(v))
          })
        } else {
          return temp
        }
      }
    },
  },
}
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
