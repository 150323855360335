<template>
  <div class="wrapper container">
    <ClientLayout :user="user"></ClientLayout>
    <v-tour name="createTeamCampTour" :steps="determineSteps"></v-tour>

    <section
      class="container"
      style="margin-top: 7%"
      v-show="!this.toggle && !this.dynamicToggle"
    >
      <div class="wrapper" id="app">
        <div class="card-form">
          <div
            class="card-form__inner"
            v-bind:style="{ width: isMobile() ? '23rem' : '' }"
          >
            <center>
              <img
                v-if="
                  (this.newCamp.campLogo == '' ||
                    this.newCamp.campLogo == null) &&
                  !this.gif
                "
                src="../../../public/SummerAthletes.png"
                class="card-img-top"
              />

              <img v-else :src="this.newCamp.campLogo" class="card-img-top" />
              <img
                id="mygif"
                src="../../../public/1495.gif"
                class="animated-gif center"
                v-show="this.gif"
              />
            </center>
            <label
              for="file-input"
              class="card-form__button mb-3 create-team-camp-step-0"
              ><center class="mt-2">
                &#8593; Upload Your Own Logo &#8593;
              </center></label
            >
            <input
              type="file"
              accept="image/*"
              @change="uploadImage($event)"
              id="file-input"
              hidden
            />

            <label class="form-label mt-1" for="form6Example1"
              >League/Tournament Organization</label
            >
            <input
              type="text"
              id="form6Example1"
              class="form-control"
              placeholder="e.g. George Washington High School Boys Basketball Team"
              v-model="newCamp.campOrganization"
              required
            />
            <label
              class="form-label"
              for="form6Example1"
              style="margin-top: 5px"
              >League/Tournament Name</label
            >
            <input
              type="text"
              id="form6Example1"
              class="form-control"
              placeholder="e.g. Junior High Boys Basketball Camp"
              v-model="newCamp.campName"
              required
            />
            <label
              class="form-label create-team-camp-step-1"
              for="form6Example3"
              style="margin-top: 5px"
              >Dates and Times of League/Tournament Days</label
            >
            <!--Start of camp day input-->
            <li
              class="list-group-item"
              v-for="index in this.numDays"
              :key="index"
            >
              <div class="row">
                <label class="form-label" for="form6Example3"
                  >Day {{ index }}</label
                >
                <input
                  v-model="days[index - 1]"
                  required
                  id="form6Example1"
                  class="form-control"
                  type="date"
                />
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Start Time</label
                  >
                  <input
                    v-model="startTimes[index - 1]"
                    required
                    id="form6Example1"
                    class="form-control"
                    type="time"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3">End Time</label>
                  <input
                    v-model="endTimes[index - 1]"
                    required
                    id="form6Example1"
                    class="form-control"
                    type="time"
                  />
                </div>
                <i
                  class="fa-solid fa-trash removeDay"
                  @click="removeDay(index)"
                ></i>
              </div>
            </li>
            <div>
              <center>
                <button
                  class="btn btn-primary btn-block mb-4"
                  @click="addDay"
                  style="margin-top: 10px"
                >
                  Add Additional Day
                </button>
              </center>
            </div>

            <!--
                          <input
                            v-model="newCamp.campDate"
                            required
                            id="form6Example1"
                            class="form-control"
                            type="date"
                          />-->
            <label
              class="form-label"
              for="form6Example4"
              style="margin-top: 5px"
              >League/Tournament Location</label
            >
            <input
              type="text"
              id="form6Example4"
              class="form-control"
              placeholder="e.g. George Washington High School South Gym"
              v-model="newCamp.campLocation"
              required
            />
            <label
              class="form-label create-team-camp-step-2"
              for="form6Example6"
              style="margin-top: 5px"
              >Price in USD</label
            >
            <input
              type="number"
              id="form6Example6"
              class="form-control"
              placeholder="75"
              min="0"
              v-model="newCamp.campPrice"
              required
            />
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="team"
                v-model="newCamp.collectFrom"
              />
              <label class="form-check-label" for="inlineRadio1"
                >Price per team</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="camper"
                v-model="newCamp.collectFrom"
              />
              <label class="form-check-label" for="inlineRadio2"
                >Price per individual</label
              >
            </div>
            <br />
            <label
              class="form-label create-team-camp-step-3"
              for="form6Example6"
              style="margin-top: 5px"
              >Max Teams</label
            >
            <input
              type="number"
              id="form6Example6"
              class="form-control"
              placeholder="Leave blank if no maximum"
              v-model="newCamp.campMaxTeams"
            />
            <label
              class="form-label create-team-camp-step-4"
              for="form6Example6"
              style="margin-top: 5px"
              >Max Participants per team</label
            >
            <input
              type="number"
              id="form6Example6"
              class="form-control"
              placeholder="Leave blank if no maximum"
              v-model="newCamp.campMaxParticipantsPerTeam"
            />
            <label
              class="form-label create-team-camp-step-5"
              for="form6Example3"
              style="margin-top: 5px"
              >Registration Deadline</label
            >
            <input
              v-model="newCamp.campRegistrationDeadline"
              required
              id="form6Example1"
              class="form-control"
              type="date"
            />
            <ul>
              <div class="form-check d-flex mb-4" style="margin-top: 20px">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  value=""
                  id="form6Example8"
                  v-model="newCamp.acceptLateRegistration"
                />
                <label
                  class="form-check-label create-team-camp-step-6"
                  for="form6Example8"
                >
                  Accept late registration
                </label>
              </div>

              <div class="form-check d-flex mb-4">
                <input
                  class="form-check-input me-2 create-team-camp-step-7"
                  type="checkbox"
                  value=""
                  id="form6Example8"
                  v-model="newCamp.requireWaiver"
                />
                <label class="form-check-label" for="form6Example8">
                  Require campers to sign waiver during registration
                </label>
              </div>
              <div v-if="newCamp.requireWaiver == true">
                <label class="form-label">Custom Waiver</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Leave blank if using Summer Athletes default waiver"
                  v-model="newCamp.customWaiver"
                  required
                ></textarea>
              </div>
              <div class="form-check d-flex mb-4">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  value=""
                  id="form6Example8"
                  v-model="newCamp.tShirtsProvided"
                />
                <label
                  class="form-check-label create-team-camp-step-8"
                  for="form6Example8"
                >
                  Collect campers t-shirt size
                </label>
              </div>

              <div class="form-check d-flex mb-4">
                <input
                  class="form-check-input me-2 create-team-camp-step-9"
                  type="checkbox"
                  value=""
                  v-model="newCamp.showPlatformFees"
                />
                <label class="form-check-label">
                  Show Platform Fees at Checkout
                </label>
              </div>
            </ul>
            <label class="form-label" for="form6Example7"
              >Description of League/Tournament</label
            >
            <textarea
              class="form-control"
              id="form6Example7"
              rows="4"
              placeholder="e.g. The George Washington Junior High Basketball will teach your child about the fundamentals of shooting, dribbling and passing. This tournament is for boys entering grades 6-8 this fall. The tournament will start 9:00am and conclude at noon."
              v-model="newCamp.campDescription"
              required
            ></textarea>
            <button
              class="card-form__button create-team-camp-step-10"
              @click="customFields"
              style="margin-top: 10px"
            >
              Add custom fields to sign-up
            </button>
            <button
              class="card-form__button create-team-camp-step-11"
              @click="dynamicPricing"
              style="margin-top: 10px"
            >
              Add additional pricing options
            </button>
            <!-- Submit button -->
            <button
              class="card-form__button create-team-camp-step-12"
              type="submit"
              style="margin-top: 10px"
              @click="createCamp"
            >
              Create League/Tournament
            </button>
          </div>
        </div>
      </div>
    </section>
    <TeamCustomFields
      v-if="this.toggle"
      @back="onBack"
      :customFields="this.newCamp.campCustomFields"
      ref="custom"
    ></TeamCustomFields>
    <TeamCampDynamicPricing
      v-if="this.dynamicToggle"
      @back="onBackDynamic"
      :addOns="this.newCamp.campCustomAddOns"
      ref="custom"
    ></TeamCampDynamicPricing>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import TeamCampDynamicPricing from '../components/TeamCampDynamicPricing.vue'
import TeamCustomFields from '../components/TeamCustomFields.vue'
import VueJwtDecode from 'vue-jwt-decode'
import ClientLayout from '../components/ClientLayout.vue'

export default {
  components: { ClientLayout, TeamCampDynamicPricing, TeamCustomFields },
  name: 'create-team-camp-tour',
  data() {
    return {
      gif: false,
      toggle: false,
      dynamicToggle: false,
      savedData: {},
      user: {},
      numDays: 1,
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false,
      },
      startTimes: [],
      endTimes: [],
      days: [],
      newCamp: {
        collectFrom: 'camper',
        campName: '',
        campDescription: '',
        campLocation: '',
        campLogo: '',
        campPrice: null,
        campOrganization: '',
        campDates: [{ start: '', end: '' }],
        campRegistrationDeadline: '',
        campMaxTeams: '',
        campMaxParticipantsPerTeam: '',
        acceptLateRegistration: false,
        requireWaiver: false,
        tShirtsProvided: false,
        showPlatformFees: false,
      },
      steps: [
        {
          target: '.create-team-camp-step-0', // We're using document.querySelector() under the hood
          content: `Here is where you can upload your own custom logo. When uploaded, your registrants will see your brand instead of ours!`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-1', // We're using document.querySelector() under the hood
          content: `Type in the exact date and times you want the day of your event to be held. If your event spans multiple days, just click "Add Additional Day" and add the new date and times.<br/><br/> You can add as many days as you want to an event, but we recommend anything over 1 week be made into a membership. (Click <a href="/Dashboard/CreateMembership">Here</a> to do that instead)`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-2', // We're using document.querySelector() under the hood
          content: `Enter the price of your League/Tournament here. The number you enter will be the League/Tournament's base price, and is the number that will be directly paid out to you for each signup. <br/><br/> Select "price per team" if you'd like the whole team to pay in one lump sum. Select "price per individual" if you'd like each team member to individually pay for themselves.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-3', // We're using document.querySelector() under the hood
          content: `Enter this number to set the maximum number of teams you can have in this League/Tournament. We recommend you leave this blank if you are unsure.`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-4', // We're using document.querySelector() under the hood
          content: `Enter your Max Participants value here. Once you've hit your max number of registrants, we will no longer allow people to sign up. <br/> <br /> We recommend you leave this blank if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-5', // We're using document.querySelector() under the hood
          content: `Set the registration deadline to prevent anyone from signing up after this date. <br/> <br /> We recommend you chose the day before the start of your League/Tournament if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-6', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to accept walk-ons. If selected, registrants are charged the same price <strong><em>even when signing up after the deadline</em></strong><br/> <br /> We recommend you <strong>check</strong> this box if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-7', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to do your waiver electronically! Avoid the hassle, and just type your waiver's text into the text-box once selected. Registrants will be required to agree to your terms at registration <br/> <br /> We recommend you type up a general waiver even if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-8', // We're using document.querySelector() under the hood
          content: `Make your life easier! Check this box to have us collect team members' T-Shirt size at signup, and keep track of your sales and inventory count for you! <br/> <br /> We recommend you absolutely check this box if you are providing T-Shirts for this event`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-9', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to show your registrants a total breakdown of all costs. This includes the base price, the platform fee, the credit card fee, etc. <br/> <br /> We recommend you <strong>do not </strong> check this box if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-10', // We're using document.querySelector() under the hood
          content: `Click here to add "custom fields" to your registration form. All you need to do is add the name of the field, its type, and who to collect it from (team or individual), and we'll do the rest! <br/> <br /> <strong>Example:</strong> Entering "Grade", selecting "Number", and selecting "Individual", will add a small text-box labeled "Grade" to the individual team member's registration form. This is how you would keep track of each registrant's school grade`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-11', // We're using document.querySelector() under the hood
          content: `Click here to add "pricing add-ons" to your registration form. All you need to do is add the name of the add-on and its price, and we'll do the rest! You can even add a donation box if you want! <br/> <br /> <strong>Example:</strong> Entering "T-Shirt" for name and typing "19.99" for price will add a purchasable option of "T-Shirt" to your registration form, for $19.99 USD`,
          params: {
            enableScrolling: false,
            placement: 'left',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-12', // We're using document.querySelector() under the hood
          content: `Once you're ready, click "Create League/Tournament" to create your event! Be sure to go into the event and post your registration link/QR Code on social media as soon as possible!`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
      ],
      mobileSteps: [
        {
          target: '.create-team-camp-step-0', // We're using document.querySelector() under the hood
          content: `Here is where you can upload your own custom logo. When uploaded, your registrants will see your brand instead of ours!`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-1', // We're using document.querySelector() under the hood
          content: `Type in the exact date and times you want the day of your event to be held. If your event spans multiple days, just click "Add Additional Day" and add the new date and times.<br/><br/> You can add as many days as you want to an event, but we recommend anything over 1 week be made into a membership. (Click <a href="/Dashboard/CreateMembership">Here</a> to do that instead)`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-2', // We're using document.querySelector() under the hood
          content: `Enter the price of your League/Tournament here. The number you enter will be the League/Tournament's base price, and is the number that will be directly paid out to you for each signup. <br/><br/> Select "price per team" if you'd like the whole team to pay in one lump sum. Select "price per individual" if you'd like each team member to individually pay for themselves.`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-3', // We're using document.querySelector() under the hood
          content: `Enter this number to set the maximum number of teams you can have in this League/Tournament. We recommend you leave this blank if you are unsure.`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-4', // We're using document.querySelector() under the hood
          content: `Enter your Max Participants value here. Once you've hit your max number of registrants, we will no longer allow people to sign up. <br/> <br /> We recommend you leave this blank if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-5', // We're using document.querySelector() under the hood
          content: `Set the registration deadline to prevent anyone from signing up after this date. <br/> <br /> We recommend you chose the day before the start of your League/Tournament if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-6', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to accept walk-ons. If selected, registrants are charged the same price <strong><em>even when signing up after the deadline</em></strong><br/> <br /> We recommend you <strong>check</strong> this box if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-7', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to do your waiver electronically! Avoid the hassle, and just type your waiver's text into the text-box once selected. Registrants will be required to agree to your terms at registration <br/> <br /> We recommend you type up a general waiver even if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-8', // We're using document.querySelector() under the hood
          content: `Make your life easier! Check this box to have us collect team members' T-Shirt size at signup, and keep track of your sales and inventory count for you! <br/> <br /> We recommend you absolutely check this box if you are providing T-Shirts for this event`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-9', // We're using document.querySelector() under the hood
          content: `Check this box if you would like to show your registrants a total breakdown of all costs. This includes the base price, the platform fee, the credit card fee, etc. <br/> <br /> We recommend you <strong>do not </strong> check this box if you're unsure`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-10', // We're using document.querySelector() under the hood
          content: `Click here to add "custom fields" to your registration form. All you need to do is add the name of the field, its type, and who to collect it from (team or individual), and we'll do the rest! <br/> <br /> <strong>Example:</strong> Entering "Grade", selecting "Number", and selecting "Individual", will add a small text-box labeled "Grade" to the individual team member's registration form. This is how you would keep track of each registrant's school grade`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-11', // We're using document.querySelector() under the hood
          content: `Click here to add "pricing add-ons" to your registration form. All you need to do is add the name of the add-on and its price, and we'll do the rest! You can even add a donation box if you want! <br/> <br /> <strong>Example:</strong> Entering "T-Shirt" for name and typing "19.99" for price will add a purchasable option of "T-Shirt" to your registration form, for $19.99 USD`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
        {
          target: '.create-team-camp-step-12', // We're using document.querySelector() under the hood
          content: `Once you're ready, click "Create League/Tournament" to create your event! Be sure to go into the event and post your registration link/QR Code on social media as soon as possible!`,
          params: {
            enableScrolling: false,
            placement: 'bottom',
            // highlight: true,
          },
        },
      ],
    }
  },
  methods: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    uploadImage(event) {
      this.gif = true
      const URL = import.meta.env.VITE_APP_DB_URL + 'util/uploadFile'

      let data = new FormData()
      data.append('name', 'my-picture')
      data.append('file', event.target.files[0])
      console.log(data)

      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          'Content-Type': 'multipart/form-data',
        },
      }

      axios
        .put(URL, data, config)
        .then(response => {
          this.newCamp.campLogo = response.data
          this.gif = false
        })
        .catch(function (err) {
          console.log(err)
          swal(
            'Error',
            'Error uploading file. Please only upload image files less than 2mb.',
            'error'
          )
        })
    },
    async presetFields() {
      let preset = this.$route.params.id
      if (preset) {
        this.getCampInfo(preset)
      } else {
        console.log('normal')
      }
    },
    async getCampInfo(preset) {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'teamCamp/getTeamCamp?id=' + preset
      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .then(response => {
          console.log(response.data)
          this.newCamp = response.data
          delete this.newCamp.teams
          delete this.newCamp.campStripeId
          delete this.newCamp.campDates
          this.newCamp.campDates = [{ start: '', end: '' }]
          delete this.newCamp.campRegistrationDeadline
          this.newCamp.campCustomFields = []
          this.formatFields()
        })
        .catch(function (err) {
          let error = err.response
          console.log(error)
        })
    },
    formatFields() {
      for (
        let camperField = 0;
        camperField < this.newCamp.camperCustomFields.length;
        camperField++
      ) {
        let obj = {
          fieldName: this.newCamp.camperCustomFields[camperField].fieldName,
          fieldType: this.newCamp.camperCustomFields[camperField].fieldType,
          fieldPerson: 'player',
        }
        this.newCamp.campCustomFields.push(obj)
      }
      this.newCamp.camperCustomFields = []
      for (
        let coachField = 0;
        coachField < this.newCamp.coachCustomFields.length;
        coachField++
      ) {
        let obj = {
          fieldName: this.newCamp.coachCustomFields[coachField].fieldName,
          fieldType: this.newCamp.coachCustomFields[coachField].fieldType,
          fieldPerson: 'coach',
        }
        this.newCamp.campCustomFields.push(obj)
      }
      this.newCamp.coachCustomFields = []
    },
    customFields() {
      this.toggle = true
    },
    onBack(x) {
      console.log('back')
      this.toggle = false
      console.log(x)
      let name
      let type
      let person
      this.newCamp.campCustomFields = []
      for (let temp = 0; temp < x[0].length; temp++) {
        name = x[0][temp]
        type = x[1][temp]
        person = x[2][temp]
        let obj = { fieldName: name, fieldType: type, fieldPerson: person }
        this.newCamp.campCustomFields.push(obj)
      }
      this.formatFields()
    },
    onBackDynamic(x) {
      this.dynamicToggle = false
      this.newCamp.campCustomAddOns = [...x]
    },
    dynamicPricing() {
      this.dynamicToggle = true
    },
    addDay() {
      this.numDays++
      this.newCamp.campDates.push({ start: '', end: '' })
    },

    logUserOut() {
      localStorage.removeItem('jwt')
      this.$router.push('/')
    },
    validateInfo() {
      if (this.newCamp.campOrganization == '') {
        swal(
          'Error',
          'League/Tournament Organization must be filled out.',
          'error'
        )
        return 1
      }
      if (this.newCamp.campName == '') {
        swal('Error', 'League/Tournament name must be filled out.', 'error')
        return 1
      }
      if (this.newCamp.campLocation == '') {
        swal('Error', 'League/Tournament Location must be filled out.', 'error')
        return 1
      }
      if (this.newCamp.campPrice == null) {
        swal('Error', 'League/Tournament Price must be filled out.', 'error')
        return 1
      }
      if (this.newCamp.campDescription == '') {
        swal(
          'Error',
          'League/Tournament Description must be filled out.',
          'error'
        )
        return 1
      }
      if (
        this.newCamp.campRegistrationDeadline == null ||
        this.newCamp.campRegistrationDeadline == '' ||
        !isNaN(this.newCamp.campRegistrationDeadline)
      ) {
        swal(
          'Error',
          'League/Tournament Registration Deadline must be filled out.',
          'error'
        )
        return 1
      }
    },
    validateDays() {
      console.log('val')
      let today = new Date()
      let tempCalc = new Date()
      let tempReg = new Date(
        this.newCamp.campRegistrationDeadline + 'T23:59:59'
      )
      console.log('temp= ' + tempReg)
      this.newCamp.campRegistrationDeadline = tempReg
      if (today.getHours() != 0) {
        today.setHours(tempCalc.getHours() - 1)
      } else if (today.getMinutes() >= 6) {
        today.setMinutes(tempCalc.getMinutes - 5)
      }
      if (today > this.newCamp.campRegistrationDeadline) {
        swal(
          'Error',
          'League/Tournament Registration Deadline must be in future ',
          'error'
        )
        return 1
      }
      if (this.days.length == 0) {
        swal('Error', 'League/Tournament Dates must be filled out', 'error')
        return 1
      }
      if (this.days.length < this.numDays) {
        swal('Error', 'All League/Tournament Dates must be filled out', 'error')
        return 1
      }
      for (let day = 0; day < this.days.length; day++) {
        if (this.startTimes[day] == null || this.startTimes[day] == '') {
          swal('Error', 'League/Tournament Dates must be filled out', 'error')
          return 1
        }
        if (this.endTimes[day] == null || this.endTimes[day] == '') {
          swal('Error', 'League/Tournament Dates must be filled out', 'error')
          return 1
        }

        if (this.startTimes[day] > this.endTimes[day]) {
          console.log('dbg1')
          let temp1 = day + 1
          swal(
            'Error',
            'Start time must be before the end time for day ' + temp1,
            'error'
          )
          return 1
        } else {
          let tempStart = new Date(this.days[day] + 'T' + this.startTimes[day])
          let tempEnd = new Date(this.days[day] + 'T' + this.endTimes[day])
          if (today > tempStart) {
            swal(
              'Error',
              'All League/Tournament Dates must be in future ',
              'error'
            )
            return 1
          }

          if (this.newCamp.campRegistrationDeadline > tempStart) {
            swal(
              'Error',
              'League/Tournament registration deadline must be not after dates of the event ',
              'error'
            )
            return 1
          }
          if (today > this.newCamp.campRegistrationDeadline) {
            swal(
              'Error',
              'League/Tournament registration deadline must be in future',
              'error'
            )
            return 1
          }
          this.newCamp.campDates[day].start = tempStart
          this.newCamp.campDates[day].end = tempEnd
        }
        console.log(this.newCamp.campDates[0].start)
      }
    },
    getUserDetails() {
      let token = localStorage.getItem('jwt')
      let decoded = VueJwtDecode.decode(token)
      this.user = decoded
    },

    async createCamp() {
      this.savedData = this.newCamp
      if (this.validateInfo() == 1) {
        return
      }
      if (this.validateDays() == 1) {
        return
      }
      swal({
        title: 'Confirm Details',
        text: 'Once the event is created, no changes are allowed.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willCreate => {
        if (willCreate) {
          let url = import.meta.env.VITE_APP_DB_URL + 'teamCamp/createTeamCamp'
          axios
            .post(
              url,
              {
                id: this.user._id,
                campName: this.newCamp.campName,
                campDescription: this.newCamp.campDescription,
                campLocation: this.newCamp.campLocation,
                campLogo: this.newCamp.campLogo,
                campPrice: this.newCamp.campPrice,
                campOrganization: this.newCamp.campOrganization,
                campDates: this.newCamp.campDates,
                campRegistrationDeadline: this.newCamp.campRegistrationDeadline,
                campMaxTeams: this.newCamp.campMaxTeams,
                campMaxParticipantsPerTeam:
                  this.newCamp.campMaxParticipantsPerTeam,
                acceptLateRegistration: this.newCamp.acceptLateRegistration,
                requireWaiver: this.newCamp.requireWaiver,
                tShirtsProvided: this.newCamp.tShirtsProvided,
                hidePlatformFees: !this.newCamp.showPlatformFees,
                campCustomFields: this.newCamp.campCustomFields,
                campCustomAddOns: this.newCamp.campCustomAddOns,
                customWaiver: this.newCamp.customWaiver,
                collectFrom: this.newCamp.collectFrom,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
              }
            )
            .catch(function (err) {
              let error = err.response
              swal('Error', error.data.errorMessage, 'error')
              this.newCamp = this.savedData
            })
          swal(
            'Success',
            'League/Tournament Created! Refresh page to see new camp',
            'success'
          )
          this.$router.push('/Dashboard')
        }
      })
    },
    removeDay(index) {
      if (this.numDays > 1) {
        this.numDays = this.numDays - 1
        this.days.splice(index - 1, 1)
        this.newCamp.campDates.splice(index - 1, 1)
      }
    },
  },
  created() {
    this.getUserDetails()
    this.presetFields()
  },
  mounted: function () {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('jwt'),
        'Content-Type': 'multipart/form-data',
      },
    }
    axios
      .get(
        import.meta.env.VITE_APP_DB_URL +
          'user/hasTeamCamps?coachID=' +
          this.user._id,
        config
      )
      .then(response => {
        console.log(response.data)
        if (!response.data.hasTeamCamps) {
          this.$tours['createTeamCampTour'].start()
        }
      })
  },
  computed: {
    determineSteps() {
      if (this.isMobile()) {
        return this.mobileSteps
      }
      return this.steps
    },
  },
}
</script>
<style>
.editor-datetime {
  position: relative;
}

.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
</style>
