<template>
  <div>
    <sidebar-menu
      v-if="isMobile"
      :menu="this.getMenu"
      style="margin-top: 50px"
      widthCollapsed="45px"
      :collapsed="true"
      :user="user"
    >
    </sidebar-menu>
    <sidebar-menu
      v-else
      :menu="this.getMenu"
      style="margin-top: 50px"
      class="menu-step-0"
      widthCollapsed="60px"
      :collapsed="true"
      :user="user"
    />
  </div>
</template>
<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import axios from 'axios'

export default {
  components: { SidebarMenu },

  props: {
    user: Object,
  },
  data() {
    return {
      menu: [],
      steps: [
        {
          target: '.menu-step-0', // We're using document.querySelector() under the hood
          content: `The <strong>Menu Bar</strong> is your action hub. <br/> <br /> Create, View, Edit, and Check all your information.`,
          params: {
            enableScrolling: false,
            placement: 'right',
            // highlight: true,
          },
        },
      ],
      foundUser: {
        subscription: {
          subscriptionTier: 0,
          subscriptionMongoID: '',
        },
      },
      subscriptions: [],
    }
  },
  methods: {

  },
  computed: {
    isMobile: function () {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },
    getMenu() {
      return [
        {
          header: true,
          title: 'Main Navigation',
          hiddenOnCollapse: true,
        },
        {
          href: '/Recruit/Dashboard',
          title: 'Home',
          icon: 'fa-solid fa-house',
        },

        {
          href: '/Recruit/Dashboard/Profile',
          title: 'Athlete Information',
          icon: 'fa-solid fa-user',
        },
        {
          href: '/Recruit/Dashboard/Website',
          title: 'My Athlete Profile',
          icon: 'fa-solid fa-pen-to-square',
        },
        {
          href: '/Recruit/Dashboard/Inbox',
          title: 'Messages',
          icon: 'fa-solid fa-comments',
          badge: {
            text: this.getMessageCount,
            class: 'vsm--badge_default',
            attributes: {
              'data-badge-variant': 'danger',
              'data-badge-size': 'lg',
              'data-badge-position': 'bottom-right',
            },
            element: 'span',
          },
        },
        {
          href: '/Recruit/Dashboard/Activities',
          title: 'Activites',
          icon: 'fa-solid fa-person-running',
        },
        {
          href: '/Recruit/Dashboard/Settings',
          title: 'Account Settings',
          icon: 'fa-solid fa-gear',
        },
      ]
    },
    getMessageCount() {
      let unReadMessages = this.user.messages.filter(x => !x.hasBeenRead)
      return unReadMessages.length ? unReadMessages.length : '0'
    },
    itemPadding() {
      return this.isMobile ? '5px' : '10px'
    },
  },
  mounted() {
    this.foundUser = this.user
    let url =
      import.meta.env.VITE_APP_DB_URL +
      'recruit/getRecruit?id=' +
      this.foundUser._id
    axios.get(url).then(response => {
      this.user = response.data
      localStorage.setItem('user', this.user)
    })
  },
}
</script>
<style>
.v-sidebar-menu .vsm--badge_default {
  font-size: 12px;
  border-radius: 3px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.v-sidebar-menu .vsm--badge_default {
  color: #fff;
  background-color: red;
}

.v-sidebar-menu .vsm--link_level-[n] {
  background-color: #363166;
}

.v-sidebar-menu {
  --vsm-primary-color: #19d412;
  --vsm-base-bg: #363166;
  --vsm-item-color: #fff;
  --vsm-item-active-color: '';
  --vsm-item-active-bg: '';
  --vsm-item-active-line-color: #363166;
  --vsm-item-open-color: #fff;
  --vsm-item-hover-color: '';
  --vsm-item-open-bg: #363166;
  --vsm-item-hover-bg: #262626ff;
  --vsm-icon-color: var(--vsm-item-color);
  --vsm-icon-bg: #1e1e21;
  --vsm-icon-active-color: '';
  --vsm-icon-active-bg: #1e1e21;
  --vsm-icon-open-color: '';
  --vsm-icon-open-bg: '';
  --vsm-mobile-item-color: #fff;
  --vsm-mobile-item-bg: #19d412;
  --vsm-mobile-item-width: 300px;
  --vsm-mobile-icon-color: var(--vsm-mobile-item-color);
  --vsm-mobile-icon-bg: transparent;
  --vsm-dropdown-bg: #36363b;
  --vsm-header-item-color: #19d412;
  --vsm-toggle-btn-color: #fff;
  --vsm-toggle-btn-bg: #1e1e21;
  --vsm-item-font-size: 16px;
  --vsm-item-line-height: 35px;
  --vsm-icon-height: 35px;
  --vsm-icon-width: 35px;
  --vsm-item-padding: v-bind(itemPadding);
}
</style>
