<template>
  <div class="wrapper" id="app">
    <div class="card-form shadow-lg">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div class="card-form center text-center justify-content-center">
          <img
            src="../../../public/1495.gif"
            alt="Loading"
            class="center text-center justify-content-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
export default {
  data() {
    return {
      otl: '',
    }
  },
  methods: {
    async loginUser() {
      let url = import.meta.env.VITE_APP_DB_URL + 'user/otllogin'
      axios
        .post(
          url,
          { otl: this.otl },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          }
        )
        .then(response => {
          let user = response.data.recruit
          localStorage.setItem('jwt', response.data.token)
          localStorage.setItem('user', JSON.stringify(user))

          if (response.data.recruitLogin) {
            let temp = 'Login Successful! '
            if (
              !(user.recruitProfile.firstName && user.recruitProfile.lastName)
            ) {
              temp = temp + 'Start by filling out your recruit profile. '
            }

            swal({
              title: 'Success',
              text: temp,
              icon: 'success',
            })
            if (user.recruitProfile.firstName && user.recruitProfile.lastName) {
              if (user.aiWebsites.length >= 1) {
                this.$router.push('/recruit/Dashboard/')
              } else {
                this.$router.push('/recruit/Dashboard/Website')
              }
            } else {
              this.$router.push('/recruit/Dashboard/Profile')
            }
          } else {
            let token = response.data.token
            let tempUser = response.data.user
            let user = {
              _id: tempUser._id,
              email: tempUser.email,
              firstName: tempUser.firstName,
              lastName: tempUser.lastName,
              subscription: tempUser.subscription,
              phoneNumber: tempUser.phoneNumber,
              address: tempUser.address,
              city: tempUser.city,
              state: tempUser.state,
              zipCode: tempUser.zipCode,
            }
            localStorage.setItem('jwt', token)
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('advertisementShown', 'false')
            if (token) {
              swal('Success', 'Login Successful', 'success')

              this.$router.push('/Dashboard')
            }
          }
        })
        .catch(function (err) {
          // console.log(err.response.data.errorMessage.message);
          let error = err.response
          swal('Error', error.data.errorMessage, 'error').then(function () {
            console.log(this)
          })
        })
    },
  },
  created() {
    this.otl = this.$route.params.id
    this.loginUser()
  },
  computed: {},
  mounted() {},
}
</script>
<style>
.card-form {
  /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom,*/
  /*from(rgba(0,0,1,1)), to(rgba(0,0,0,.9)));*/
  /*box-shadow: 0 30px 40px rgba(0,0,0,.9);*/
}

.gradient-button {
  background: rgb(54, 49, 102);
  background: linear-gradient(
    90deg,
    rgba(54, 49, 102, 1) 8%,
    rgba(25, 212, 18, 1) 78%
  );
}
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 20px 15px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}
</style>
