<template>
  <div class="wrapper" id="app">
    <div class="card-form shadow-lg">
      <div class="card-form__inner">
        <img src="/SummerAthletes.png" class="card-img-top" />
        <div v-show="!this.hasMultipleAccounts">
          <div
            v-if="authLoading"
            class="card-form center text-center justify-content-center"
          >
            <img
              src="/1495.gif"
              alt="Loading"
              class="center text-center justify-content-center"
            />
          </div>
          <div v-else class="card-form">
            <div class="card-input">
              <label for="email" class="card-input__label">Email</label>
              <input
                type="email"
                id="email"
                class="card-input__input"
                placeholder="Email"
                v-model="login.email"
              />
            </div>
            <div class="card-input">
              <label class="card-input__label">Password</label>
              <input
                type="password"
                id="password"
                class="card-input__input"
                placeholder="Password"
                v-model="login.password"
              />
              <router-link
                to="/forgotpassword"
                style="text-align: center; color: dodgerblue"
                class="text-center"
                >Forgot Password?</router-link
              >
            </div>

            <div class="signup-buttons">
              <a
                href="#"
                class="google-signup"
                @click.prevent="loginWithGoogle"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  aria-hidden="true"
                >
                  <title>Google</title>
                  <g fill="none" fill-rule="evenodd">
                    <path
                      fill="#4285F4"
                      d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                    ></path>
                    <path
                      fill="#EA4335"
                      d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                    ></path>
                  </g>
                </svg>
                Sign in with Google
              </a>
              <a
                href="#"
                class="facebook-signup"
                @click.prevent="loginWithFacebook"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#3578E5"
                >
                  <path
                    d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                  />
                </svg>
                Sign in with Facebook
              </a>
            </div>
          </div>

          <button
            class="card-form__button gradient-button"
            id="regularSignInBtn"
            @click="this.loginUser"
          >
            <div v-if="this.loggingIn">
              <div class="spinner-grow spinner-grow-sm m-1"></div>
              <div class="spinner-grow spinner-grow-sm m-1"></div>
              <div class="spinner-grow spinner-grow-sm m-1"></div>
            </div>
            <div v-else>Sign in</div>
          </button>
          <div>
            <div class="center mt-3">
              <div class="text-center center">
                <a
                  class="card2 camp-step-3"
                  data-bs-toggle="modal"
                  data-bs-target="#getStartedModal"
                  target="_blank"
                  style="cursor: pointer; color: #363166"
                >
                  <h5>Don't have an account? Let's Get Started</h5>
                  <div class="go-corner" href="#">
                    <div class="go-arrow">→</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <ChooseAccount
          v-show="this.hasMultipleAccounts"
          :accounts="this.accounts"
        ></ChooseAccount>
        <GetStartedFlowModal></GetStartedFlowModal>
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import GetStartedFlowModal from './GetStartedFlowModal.vue'
import ChooseAccount from './ChooseAccount.vue'
export default {
  components: {
    GetStartedFlowModal,
    ChooseAccount,
  },
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      loggingIn: false,
      authLoading: false,
      multipleAccounts: false,
      accounts: [],
      authProcessed: false,
    }
  },
  methods: {
    // openGetStartedModal() {
    //   let modalElement = this.$refs.blankModal.$el.querySelector("#blankModal");
    //   let modal = new Modal(modalElement);
    //   modal.show();
    // },
    async loginUser() {
      this.loggingIn = true
      let url = import.meta.env.VITE_APP_DB_URL + 'user/login'
      if (this.validateInfo() == 0) {
        try {
          const response = await axios.post(url, this.login, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('jwt'),
            },
          })

          const accounts = response.data
          console.log('accounts')
          console.log(accounts)

          if (accounts.length > 1) {
            this.multipleAccounts = true
            this.accounts = accounts
          } else if (accounts.length === 1) {
            let account = accounts[0]
            localStorage.setItem('jwt', account.token)
            localStorage.setItem('user', JSON.stringify(account.data))

            if (account.type === 'recruit') {
              let temp = 'Login Successful! '
              if (
                !(
                  account.data.recruitProfile.firstName &&
                  account.data.recruitProfile.lastName
                )
              ) {
                temp = temp + 'Start by filling out your recruit profile. '
              }

              swal({
                title: 'Success',
                text: temp,
                icon: 'success',
              })

              if (
                account.data.recruitProfile.firstName &&
                account.data.recruitProfile.lastName
              ) {
                if (
                  account.data.aiWebsites.length >= 1 ||
                  !account.data.accountActive
                ) {
                  this.$router.push('/recruit/Dashboard/')
                } else if (account.data.activities.length >= 1) {
                  this.$router.push('/recruit/Dashboard/Activities')
                } else {
                  this.$router.push('/recruit/Dashboard/Profile')
                }
              } else {
                this.$router.push('/recruit/Dashboard/Profile')
              }
            } else {
              let user = {
                _id: account.data._id,
                email: account.data.email,
                firstName: account.data.firstName,
                lastName: account.data.lastName,
                subscription: account.data.subscription,
                phoneNumber: account.data.phoneNumber,
                address: account.data.address,
                city: account.data.city,
                state: account.data.state,
                zipCode: account.data.zipCode,
                accountActive: account.data.accountActive,
              }
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('advertisementShown', 'false')

              if (account.token) {
                swal('Success', 'Login Successful', 'success')
                if (!user.accountActive) {
                  this.$router.push(
                    '/SubscriptionChoices/Checkout/' +
                      user.subscription.subscriptionMongoID +
                      '/false' +
                      '?lock=true'
                  )
                } else {
                  this.$router.push('/Dashboard')
                }
              }
            }
          }
        } catch (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error').then(function () {
            console.log(this)
          })
        } finally {
          this.loggingIn = false
        }
      }
    },

    validateInfo() {
      if (this.login.email == '') {
        swal('Error', 'Please enter email', 'error')
        return 1
      }
      if (this.login.password == '') {
        swal('Error', 'Please enter password', 'error')
        return 1
      }
      return 0
    },

    loginWithGoogle() {
      axios
        .post(
          import.meta.env.VITE_APP_DB_URL + 'user/googleAuthentication',
          { redirectURL: import.meta.env.VITE_APP_CLIENT_URL + '?auth=google' },
          {}
        )
        .then(response => {
          console.log(response)
          if (response.data.googleAuthURL) {
            window.location = response.data.googleAuthURL
          } else {
            swal(
              'Error',
              'Your account cannot be integrated with Google at this point in time',
              'error'
            )
          }
        })
        .catch(function (err) {
          console.log(err.response.data.errorMessage.message)
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },

    loginWithFacebook() {
      window.location =
        'https://www.facebook.com/v4.0/dialog/oauth?client_id=' +
        import.meta.env.VITE_APP_FACEBOOK_CLIENT_ID +
        '&redirect_uri=' +
        import.meta.env.VITE_APP_CLIENT_URL +
        '?auth=facebook&scope=email&response_type=code&auth_type=rerequest&display=popup'
    },
  },
  created() {
    if (this.$route.query.code && !this.authProcessed) {
      this.authProcessed = true
      if (this.$route.query.auth === 'google') {
        this.authLoading = true
        console.log('created')
        let token = this.$route.query.code

        axios
          .post(
            import.meta.env.VITE_APP_DB_URL + 'user/googleAuthVerification',
            {
              token: token,
              redirectURL: import.meta.env.VITE_APP_CLIENT_URL + '?auth=google',
              testingData: Math.random(),
            },
            {}
          )
          .then(response => {
            const accounts = response.data
            console.log('accounts')
            console.log(accounts)

            if (accounts.length > 1) {
              this.multipleAccounts = true
              this.accounts = accounts
            } else if (accounts.length === 1) {
              let account = accounts[0]
              localStorage.setItem('jwt', account.token)
              localStorage.setItem('user', JSON.stringify(account.data))

              if (account.type === 'recruit') {
                let temp = 'Login Successful! '
                if (
                  !(
                    account.data.recruitProfile.firstName &&
                    account.data.recruitProfile.lastName
                  )
                ) {
                  temp = temp + 'Start by filling out your recruit profile. '
                }

                swal({
                  title: 'Success',
                  text: temp,
                  icon: 'success',
                })

                if (
                  account.data.recruitProfile.firstName &&
                  account.data.recruitProfile.lastName
                ) {
                  if (
                    account.data.aiWebsites.length >= 1 ||
                    !account.data.accountActive
                  ) {
                    this.$router.push('/recruit/Dashboard/')
                  } else if (account.data.activities.length >= 1) {
                    this.$router.push('/recruit/Dashboard/Activities')
                  } else {
                    this.$router.push('/recruit/Dashboard/Profile')
                  }
                } else {
                  this.$router.push('/recruit/Dashboard/Profile')
                }
              } else {
                let user = {
                  _id: account.data._id,
                  email: account.data.email,
                  firstName: account.data.firstName,
                  lastName: account.data.lastName,
                  subscription: account.data.subscription,
                  phoneNumber: account.data.phoneNumber,
                  address: account.data.address,
                  city: account.data.city,
                  state: account.data.state,
                  zipCode: account.data.zipCode,
                  accountActive: account.data.accountActive,
                }
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('advertisementShown', 'false')

                if (account.token) {
                  swal('Success', 'Login Successful', 'success')
                  if (!user.accountActive) {
                    this.$router.push(
                      '/SubscriptionChoices/Checkout/' +
                        user.subscription.subscriptionMongoID +
                        '/false' +
                        '?lock=true'
                    )
                  } else {
                    this.$router.push('/Dashboard')
                  }
                }
              }
            }
          })
          .catch(function (err) {
            console.log('error1')
            console.log(err.response.data.errorMessage.message)
            let error = err.response
            swal({
              title: 'Error',
              text: error.data.errorMessage,
              icon: 'error',
              buttons: ['Cancel', 'Try Again'],
              dangerMode: true,
            }).then(function (isConfirm) {
              if (isConfirm) {
                window.location = import.meta.env.VITE_APP_CLIENT_URL
              } else {
                window.location = import.meta.env.VITE_APP_CLIENT_URL
              }
            })
          })
      } else if (this.$route.query.auth === 'facebook') {
        const { data } = axios({
          url: 'https://graph.facebook.com/v4.0/oauth/access_token',
          method: 'get',
          params: {
            client_id: import.meta.env.VITE_APP_FACEBOOK_CLIENT_ID,
            client_secret: import.meta.env.VITE_APP_FACEBOOK_CLIENT_SECRET,
            redirect_uri:
              import.meta.env.VITE_APP_CLIENT_URL + '?auth=facebook',
            code: this.$route.query.code,
          },
        }).then(response => {
          axios({
            url:
              import.meta.env.VITE_APP_CLIENT_URL +
              'user/facebookAuthVerification',
            method: 'get',
            params: {
              accessToken: response.data.access_token,
            },
          })
            .then(response => {
              console.log(response.data)
              let user = response.data.recruit
              localStorage.setItem('jwt', response.data.token)
              localStorage.setItem('user', JSON.stringify(user))
              if (response.data.recruitLogin) {
                let temp = 'Login Successful! '
                if (
                  !(
                    user.recruitProfile.firstName &&
                    user.recruitProfile.lastName
                  )
                ) {
                  temp = temp + 'Start by filling out your recruit profile. '
                }

                swal({
                  title: 'Success',
                  text: temp,
                  icon: 'success',
                })
                console.log('here')
                if (
                  user.recruitProfile.firstName &&
                  user.recruitProfile.lastName
                ) {
                  if (user.aiWebsites.length >= 1 || !user.accountActive) {
                    this.$router.push('/recruit/Dashboard/')
                  } else {
                    this.$router.push('/recruit/Dashboard/Website')
                  }
                } else {
                  this.$router.push('/recruit/Dashboard/Profile')
                }
              } else {
                let token = response.data.token
                let tempUser = response.data.user
                let user = {
                  _id: tempUser._id,
                  email: tempUser.email,
                  firstName: tempUser.firstName,
                  lastName: tempUser.lastName,
                  subscription: tempUser.subscription,
                  phoneNumber: tempUser.phoneNumber,
                  address: tempUser.address,
                  city: tempUser.city,
                  state: tempUser.state,
                  zipCode: tempUser.zipCode,
                  accountActive: tempUser.accountActive,
                }
                localStorage.setItem('jwt', token)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('advertisementShown', 'false')
                if (token) {
                  swal('Success', 'Login Successful', 'success')
                  if (!user.accountActive) {
                    this.$router.push(
                      '/SubscriptionChoices/Checkout/' +
                        user.subscription.subscriptionMongoID +
                        '/false' +
                        '?lock=true'
                    )
                  } else {
                    this.$router.push('/Dashboard')
                  }
                }
              }
            })
            .catch(function (err) {
              console.log(err.response.data.errorMessage.message)
              let error = err.response
              swal({
                title: 'Error',
                text: error.data.errorMessage,
                icon: 'error',
                buttons: ['Cancel', 'Try Again'],
                dangerMode: true,
              }).then(function (isConfirm) {
                if (isConfirm) {
                  window.location = import.meta.env.VITE_APP_CLIENT_URL
                } else {
                  window.location = import.meta.env.VITE_APP_CLIENT_URL
                }
              })
            })
          console.log(data) // { access_token, token_type, expires_in }
        })
      }
    }
  },
  computed: {
    getRegisterURL() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'register'
    },
    getForgotPasswordURL() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'forgotpassword'
    },
    getSubscriptionRookieCheckoutLink() {
      return import.meta.env.VITE_APP_CLIENT_URL + 'externalcheckoutsecondary'
    },
    hasMultipleAccounts() {
      return this.multipleAccounts
    },
  },

  mounted() {
    if (!this.$route.query.auth) {
      var passwordInput = document.getElementById('password')
      var emailInput = document.getElementById('email')
      // Execute a function when the user presses a key on the keyboard
      passwordInput?.addEventListener('keyup', function (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === 'Enter') {
          // Trigger the button element with a click
          document.getElementById('regularSignInBtn').click()
          event.stopPropagation()
          event.preventDefault()
        }
      })
      emailInput?.addEventListener('keypress', function (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === 'Enter') {
          // Trigger the button element with a click
          document.getElementById('regularSignInBtn').click()
        }
      })
    }
  },
}
</script>
<style>
.card-form {
  /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom,*/
  /*from(rgba(0,0,1,1)), to(rgba(0,0,0,.9)));*/
  /*box-shadow: 0 30px 40px rgba(0,0,0,.9);*/
}

.gradient-button {
  background: rgb(54, 49, 102);
  background: linear-gradient(
    90deg,
    rgba(54, 49, 102, 1) 8%,
    rgba(25, 212, 18, 1) 78%
  );
}
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 20px 15px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}
</style>
