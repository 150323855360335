<template>
  <div class="container" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          src="https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png"
          class="card-img-top rounded-corners"
          id="logoTop"
        />
        <div class="card-body">
          <center>
            <h1 class="card-title">Activate Athlete Profile</h1>
            <br />
            <h5>
              Create an athlete profile, personalized website, get added to a
              system of thousands of coaches, and get added to our specialized
              list of recruits. Promote your profile online, and allow college
              coaches and recruiters to contact you for ${{
                this.subscription.monthlyPrice
              }}
              a month.
            </h5>
            <br />
            <button
              class="card-form__button rounded-pill"
              @click="buyRecruitment"
              style="max-width: 55%"
            >
              Create Athlete Profile
            </button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import swal from 'sweetalert'

export default {
  props: {
    billingInfo: Object,
    achBillingInfo: Object,
    camperInfo: Object,
    campLogo: String,
    subscription: Object,
    activityId: String,
  },
  data() {
    return {}
  },
  methods: {
    buyRecruitment() {
      let url = import.meta.env.VITE_APP_DB_URL + 'recruit/registerRecruit'
      let initialActivity = {
        activityId: this.activityId,
        activityType: 'camp',
      }
      axios
        .post(url, {
          billingInfo: this.billingInfo,
          achBillingInfo: this.achBillingInfo,
          camperInfo: this.camperInfo,
          initialActivity: initialActivity,
        })
        .then(response => {
          let token = response.data.token
          let user = response.data.tempRecruit
          localStorage.setItem('jwt', token)
          localStorage.setItem('user', JSON.stringify(user))
          if (token) {
            swal('Success', 'Purchase Successful', 'success')
            this.$router.push('/recruit/Dashboard/Activities')
          }
          console.log(response.data)
        })
        .catch(error => {
          this.$emit('error', error.response.data) // Emit the error event with the response data
          swal({
            title: 'Error',
            icon: 'error',
            text: error.response.data.errorMessage,
          })
        })
    },
  },
}
</script>
