<template>
  <div>
    <section
      class="container justify-content-center"
      v-bind:style="{ marginLeft: isMobile() ? '10px' : '' }"
    >
      <div v-if="!isMobile()" class="container mt-5 justify-content-center">
        <div
          class="card"
          style="max-height: 60vh; overflow: auto; min-height: 60vh"
        >
          <table class="table table-hover" style="">
            <thead>
              <tr>
                <th
                  scope="col"
                  @click="sortTable('type')"
                  style="position: sticky; top: 0px"
                >
                  Type
                  <i :class="getSortIcon('type')"></i>
                </th>
                <th
                  scope="col"
                  @click="sortTable('name')"
                  style="position: sticky; top: 0px"
                >
                  Name
                  <i :class="getSortIcon('name')"></i>
                </th>
                <th
                  scope="col"
                  @click="sortTable('date')"
                  style="position: sticky; top: 0px"
                >
                  Date
                  <i :class="getSortIcon('date')"></i>
                </th>
                <th
                  scope="col"
                  @click="sortTable('totalcustomers')"
                  style="position: sticky; top: 0px"
                >
                  Number of Customers
                  <i :class="getSortIcon('totalcustomers')"></i>
                </th>
                <th scope="col" style="position: sticky; top: 0px">Link</th>
                <th scope="col" style="position: sticky; top: 0px">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="link in sortedLinks" :key="link.id">
                <td>{{ link.type }}</td>
                <td>{{ link.name }}</td>
                <td>
                  {{
                    link.date ? new Date(link.date).toLocaleDateString() : ''
                  }}
                </td>
                <td>{{ link.totalcustomers }}</td>
                <td>
                  <div
                    class="btn btn-sm"
                    style="
                      background-color: #363166;
                      color: white;
                      min-width: 100px;
                    "
                    @click="viewLink(link.type, link.id, link.stripeId)"
                  >
                    Open Link
                  </div>
                </td>
                <td>
                  <div
                    class="btn btn-sm"
                    style="
                      background-color: #363166;
                      color: white;
                      min-width: 100px;
                    "
                    @click="viewDetails(link.type, link.id)"
                  >
                    View Details
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Mobile view section remains unchanged -->
      <div v-else style="position: relative">
        <div
          class="card mt-3"
          style="
            max-height: 700px;
            overflow-y: auto;
            margin-left: 21px;
            position: relative;
            max-width: 321px;
          "
        >
          <table
            class="table table-hover"
            style="display: block; position: relative; max-width: 321px"
          >
            <thead style="position: relative">
              <tr>
                <th
                  scope="col"
                  @click="sortTable('name')"
                  style="position: sticky; top: 0px"
                >
                  Name
                  <i :class="getSortIcon('name')"></i>
                </th>
                <th
                  scope="col"
                  @click="sortTable('date')"
                  style="position: sticky; top: 0px"
                >
                  Date
                  <i :class="getSortIcon('date')"></i>
                </th>
                <th scope="col" style="position: sticky; top: 0px">Details</th>
              </tr>
            </thead>
            <tbody style="position: relative">
              <tr v-for="link in sortedLinks" :key="link.id">
                <td style="max-width: 50%">
                  {{ link.name }}
                </td>
                <td style="max-width: 50%">
                  {{
                    link.date ? new Date(link.date).toLocaleDateString() : ''
                  }}
                </td>
                <td>
                  <div
                    class="btn btn-sm"
                    style="background-color: #363166; color: white"
                    @click="viewDetails(link.type, link.id)"
                  >
                    View Details
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: { resultQuery: Array },
  data() {
    return {
      sortKey: '',
      sortOrder: 'asc', // 'asc' or 'desc'
    }
  },
  methods: {
    isMobile() {
      var check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    },

    sortTable(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortKey = key
        this.sortOrder = 'asc'
      }
    },
    getSortIcon(key) {
      if (this.sortKey !== key) return 'fa fa-sort'
      return this.sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    },
    viewDetails(type, id) {
      let url
      if (type === 'Membership') {
        url = '/Dashboard/ViewMembership/' + id
      } else {
        url = '/Dashboard/ViewCamp/Individual/' + id
      }
      if (url) {
        this.$router.push(url)
      }
    },
    viewLink(type, id, stripeId) {
      let url
      if (type === 'Membership') {
        url =
          import.meta.env.VITE_APP_CLIENT_URL +
          'memberships/membershipCheckout/' +
          id
      } else {
        url =
          import.meta.env.VITE_APP_CLIENT_URL + 'camps/campcheckout/' + stripeId
      }
      if (url) {
        window.open(url, '_blank')
      }
    },
  },
  computed: {
    sortedLinks() {
      return [...this.resultQuery].sort((a, b) => {
        let result = 0
        if (this.sortKey === 'date') {
          // Compare dates as Date objects
          const dateA = new Date(a.date)
          const dateB = new Date(b.date)
          if (dateA < dateB) result = -1
          if (dateA > dateB) result = 1
        } else {
          if (a[this.sortKey] < b[this.sortKey]) result = -1
          if (a[this.sortKey] > b[this.sortKey]) result = 1
        }
        return this.sortOrder === 'asc' ? result : -result
      })
    },
    getCustomerList() {
      return []
    },
  },
}
</script>

<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
