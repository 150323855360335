<template>
  <div
    :class="{
      forgot: true,
      loginFootball: getBackgroundClass === 'loginFootball',
      loginBaseball: getBackgroundClass === 'loginBaseball',
      loginBasketball: getBackgroundClass === 'loginBasketball',
      loginSoccer: getBackgroundClass === 'loginSoccer',
    }"
  >
    <ForgotPasswordComponent />
  </div>
</template>
<script>
import ForgotPasswordComponent from '../components/ForgotPasswordComponent.vue'
export default {
  components: {
    ForgotPasswordComponent,
  },
  computed: {
    getBackgroundClass() {
      const classes = [
        'loginFootball',
        'loginBaseball',
        'loginBasketball',
        'loginSoccer',
      ]
      return classes[Math.floor(Math.random() * classes.length)]
    },
  },
}
</script>
