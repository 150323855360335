<template>
  <div>
    <div class="card" v-if="camps.length != 0">
      <div class="card-header">One-time Payments</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="(camp, index) in camps" :key="index">
          {{ camp.campName }}
          <div class="col-lg-4 col-lg-offset-4">
            <label for="Received">Check In </label>
            <input
              type="checkbox"
              id="Recieved"
              name="Recieved"
              v-model="camp.isCheckedIn"
              @change="updateCamperStatus(index, camp.isCheckedIn)"
              style="
                width: 17px;
                height: 17px;
                accent-color: #19d412;
                margin-left: 5px;
              "
              class="checkbox"
            />
          </div>
          <button
            class="btn btn-sm float-end rounded-pill"
            style="background-color: #363166; color: white"
            @click="navigateIndividualCamp(index)"
          >
            View Details
          </button>
        </li>
      </ul>
    </div>
    <div class="card" v-if="teamCamps.length != 0">
      <div class="card-header">Leagues/Tournaments</div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="(teamCamp, index) in teamCamps"
          :key="index"
        >
          {{ teamCamp.campName }}
          <button
            class="btn btn-sm float-end"
            style="background-color: #363166; color: white"
            @click="navigateTeamCamp(index)"
          >
            View Details
          </button>
        </li>
      </ul>
    </div>
    <div class="card" v-if="memberships.length != 0">
      <div class="card-header">Recurring Payments</div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="(membership, index) in memberships"
          :key="index"
        >
          {{ membership.membershipName }}
          <button
            class="btn btn-sm float-end"
            style="background-color: #363166; color: white"
            @click="navigateMembership(index)"
          >
            View Details
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import axios from 'axios'

export default {
  props: {
    firstName: String,
    lastName: String,
    camps: Array,
    memberships: Array,
    teamCamps: Array,
  },
  methods: {
    updateCamperStatus(index, checkedIn) {
      let obj = {
        campId: this.camps[index].campId,
        camperId: this.camps[index].camperId,
        isCheckedIn: checkedIn,
      }
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/setCamperStatus'
      axios
        .post(url, obj, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        })
        .catch(function (err) {
          let error = err.response
          swal('Error', error.data.errorMessage, 'error')
        })
    },
    navigateIndividualCamp(index) {
      document.getElementById('PeopleDetailsCloseButton').click()
      this.$router.push(
        '/Dashboard/CheckIn/' +
          this.camps[index].campId +
          '/' +
          this.camps[index].camperId
      )
    },
    navigateTeamCamp(index) {
      document.getElementById('PeopleDetailsCloseButton').click()

      this.$router.push(
        '/Dashboard/TeamList/' +
          this.teamCamps[index].campId +
          '/' +
          this.teamCamps[index].teamId +
          '/' +
          this.teamCamps[index].camperId
      )
    },
    navigateMembership(index) {
      document.getElementById('PeopleDetailsCloseButton').click()

      this.$router.push(
        '/Dashboard/MembershipCheckIn/' +
          this.memberships[index].membershipId +
          '/' +
          this.memberships[index].memberId +
          '/' +
          this.memberships[index].subscriptionId
      )
    },
  },
}
</script>

<style>
.checkbox {
  width: 17px;
  height: 17px;
  accent-color: #19d412;
  margin-left: 5px;
}
</style>
