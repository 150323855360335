<template>
  <OrderSummary
    :campLogo="this.campLogo"
    :campName="this.campName"
    :camperInfo="this.camperInfo"
    :campId="this.campId"
    :initial="this.initial"
    :addOns="this.addOns"
    :customFieldValues="this.customFieldValues"
    :discounts="this.discounts"
    :hidePlatformFees="this.hidePlatformFees"
    :emergencyContactInfo="this.emergencyContactInfo"
    :expressCheckout="true"
    :paymentType="this.paymentType"
    :paymentStructure="this.paymentStructure"
    :dates="this.dates"
    :totalUpchargePercentage="this.totalUpchargePercentage"
    :additionalCampers="this.additionalCampers"
    :multipleCamperPrices="this.multipleCamperPrices"
    :isMobile="this.isMobile"
    :primaryColor="this.primaryColor"
    :secondaryColor="this.secondaryColor"
    :recruitPurchase="this.recruitPurchase"
    :recruitSubscriptionPrice="this.recruitSubscriptionPrice"
    :hideRecruitAds="this.hideRecruitAds"
    @proceed="registerCamper"
    @addingRecruitPurchase="addingRecruitPurchase"
    @checkedOut="checkedOut"
    @back="back"
  ></OrderSummary>
</template>
<script>
import OrderSummary from './OrderSummary.vue'
import swal from 'sweetalert'
import axios from 'axios'
export default {
  components: {
    OrderSummary,
  },
  props: {
    campLogo: String,
    campName: String,
    initial: Number,
    addOns: Array,
    customFieldValues: Array,
    discounts: Array,
    hidePlatformFees: Boolean,
    camperInfo: Object,
    emergencyContactInfo: Object,
    campId: String,
    paymentType: String,
    paymentStructure: Object,
    dates: String,
    toggle: Number,
    totalUpchargePercentage: Number,
    additionalCampers: Array,
    multipleCamperPrices: Array,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
    recruitPurchase: Boolean,
    recruitSubscriptionPrice: Number,
    hideRecruitAds: Boolean,
  },
  data() {
    return {
      totalPrice: 0,
      campDisplayName: '',
      camperDiscountCode: '',
      displayOrderSummary: false,
      billingInfo: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
      },
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    addingRecruitPurchase() {
      this.$emit('addingRecruitPurchase')
    },
    checkedOut() {
      this.$emit('checkedOut')
    },
    async registerCamper() {
      let url =
        import.meta.env.VITE_APP_DB_URL + 'individualCamp/registerCamperExpress'
      swal({
        title: 'Wait for confirmation',
        text: 'Please wait for confirmation',
        icon: 'warning',
        buttons: false,
      })
      this.formatEmergencyContactInfo()
      await axios
        .post(url, {
          campId: this.campId,
          camperInfo: this.camperInfo,
          emergencyContactInfo: this.emergencyContactInfo,
          customFieldValues: this.customFieldValues,
          billingInfo: this.billingInfo,
          addOns: this.addOns,
          dates: this.dates,
        })
        .then(function () {
          swal('Success', 'Registration Successful', 'success')
          this.gif = false
          this.$emit('checkedOut')
        })
        .catch(function (err) {
          console.log('err')
          let error = err.response
          try {
            swal('Error', error.data.errorMessage, 'error')
          } catch (e) {
            console.log('post failure')
          }
        })
      this.gif = false
      //this.toggle = 1;
    },
    handleNext() {
      this.displayOrderSummary = true
    },
  },
}
</script>
